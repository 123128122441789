import React, { useEffect, useState } from "react";
import {
	Button,
	Label,
	Card,
	Col,
	CardBody,
	CardHeader,
	CardTitle,
	Row,
	Form,
} from "reactstrap";
import {
	CheckBoxElement,
	CurrencyInput,
	PercentInput,
	SelectorElement,
} from "../features/forms/components/InputElement";
import { saveSuccessAlert } from "../utils/alertUtils";
import formNavigationUtils from "utils/formNavigationUtils";
import useCurrentAccountStore from "./AccountStore";
import shallow from "zustand/shallow";
import AccountHook from "./AccountHook";
import { AccountStatus } from "../constants/Constants";
import AccountDeleteButton from "./AccountDeleteButton";
import { reCalculateAccount } from "./AccountAPI";
import Swal from "sweetalert2";
/**
 *
 */
const AccountSettings = () => {
	const { account, editAccount } = useCurrentAccountStore(
		(state) => state,
		shallow
	);
	const save = AccountHook();

	// Hooks for Account Setting store values
	const [latePercent, setLatePercent] = useState(account.lateChargePct);
	const [lateFixAmount, setLateFixAmount] = useState(account.lateChargeAmt);
	const [lateType, setLateType] = useState(account.lateChargeType);
	const [status, setStatus] = useState(account.status);
	const [subStatus, setSubStatus] = useState(account.subStatus);
	const [options, setOptions] = useState([]);
	const [autoMessaging, setAutoMessaging] = useState(
		account?.autoMessaging || false
	);

	// Update options whenever status changes
	useEffect(() => {
		const filteredOptions = AccountStatus.filter(
			(sub) => sub.main === Number(status)
		).map((sub) => ({
			value: sub.label, // Assign value as label if you need
			label: sub.label,
		}));
		setOptions(filteredOptions);
	}, [status]);
	const commandsave = () => {
		account.lateChargePct = latePercent;
		account.lateChargeAmt = lateFixAmount;
		account.lateChargeType = lateType;
		account.status = status;
		account.subStatus = subStatus;
		account.autoMessaging = autoMessaging;
		editAccount(account);
		save();
		saveSuccessAlert();
	};

	return (
		<div onKeyDown={formNavigationUtils}>
			<Form>
				<Button
					color="success"
					onClick={() => {
						commandsave();
					}}
					size="md"
				>
					<i className="nc-icon nc-cloud-download-93"></i> Save
				</Button>

				<Card>
					<CardHeader>
						<CardTitle tag="h3">Late Charge setting</CardTitle>
					</CardHeader>
					<CardBody>
						<Col>
							<Row className="mx-0 pb-2">
								<SelectorElement
									name="lateType"
									label="Late Charge by"
									colSize="col-sm-3"
									value={lateType}
									onChange={(e) => setLateType(e.target.value)}
									options={[
										{ value: -1, label: "Not Auto Calculate" },
										{ value: 0, label: "Pct of Pmt" },
										{ value: 2, label: "Pct of Past Due" },
										{ value: 1, label: "Flat Fee" },
									]}
									disableValidation
								/>
								<PercentInput
									label="Late Percentage"
									value={latePercent}
									onChange={setLatePercent}
									disableValidation
									colSize="col-sm-3"
								/>
								<Label> + </Label>
								<CurrencyInput
									value={lateFixAmount}
									onChange={setLateFixAmount}
									name="lateFixAmount"
									label="Fixed Amount"
									type="Currency"
									colSize="col-sm-3"
									disableValidation
								/>
							</Row>
						</Col>
					</CardBody>
				</Card>
				<Card>
					<CardHeader>
						<CardTitle tag="h3">Account Status</CardTitle>
					</CardHeader>

					<CardBody>
						<Col>
							<Row className="mx-0 pb-2">
								<SelectorElement
									name="status"
									label="Account Status"
									colSize="col-sm-3"
									value={status}
									onChange={(e) => setStatus(e.target.value)}
									options={[
										{ value: 0, label: "Active" },
										{ value: 1, label: "DeActive" },
									]}
									disableValidation
								/>
								<SelectorElement
									name="subStatus"
									label="Account subStatus"
									colSize="col-sm-3"
									value={subStatus}
									onChange={(e) => setSubStatus(e.target.value)}
									options={options}
									disableValidation
								/>
								<Col className="text-center">
									<CheckBoxElement
										disableValidation
										checked={autoMessaging}
										onChange={() => {
											setAutoMessaging(!autoMessaging);
										}}
										name={autoMessaging}
										label="Auto Messaging"
										//colSize="col-sm-3"
										labelClass="pl-4 "
									/>
								</Col>
							</Row>
						</Col>
					</CardBody>
				</Card>
				<Card>
					<CardHeader tag="h3">Actions</CardHeader>
					<CardBody>
						<button
							className="btn btn-primary"
							onClick={(e) => {
								e.preventDefault();
								reCalculateAccount(account.id).then((res) => {
									editAccount(res.data.content);
									Swal.close();
									saveSuccessAlert();
								});
							}}
						>
							Recalculate
						</button>
						<AccountDeleteButton />
					</CardBody>
				</Card>
			</Form>
		</div>
	);
};

export default AccountSettings;
