import useSetAccount from "./useSetAccount";
import { saveAccount } from "./AccountAPI";
import useBuildAccount from "./useBuildAccount";
import {
	progressLoading,
	saveFailAlert,
	saveSuccessAlert,
} from "../utils/alertUtils";
import Swal from "sweetalert2";
import { showApiError } from "../utils/errorRoutingUtils";

const AccountHook = () => {
	const setAccount = useSetAccount();
	const getAcct = useBuildAccount();

	const save = async () => {
		const acctObj = getAcct();
		saveAccount(acctObj).then(
			(res) => {
				progressLoading("Saving Account");
				setAccount(res);
				Swal.close();
				saveSuccessAlert();
			},
			(err) => {
				showApiError(err, saveFailAlert);
			}
		);
	};

	return save;
};

export default AccountHook;
