import React, { useState } from "react";
import { Button } from "reactstrap";
import ChildrenModal from "../../components/modals/ChildrenModal";
import SendMessage from "./SendMessage";

const Communication = ({
	customerEmail = "",
	sendToNumber = "",
	dealID = null,
	accountID = null,
	personType = "Customer",
}) => {
	const [modalOpen, setModalOpen] = useState(false);

	const toggleModal = () => setModalOpen(!modalOpen);

	return (
		<>
			<Button className="btn-md btn-info" onClick={toggleModal}>
				<i className="nc-icon nc-send" /> &nbsp;&nbsp;Text & Email
			</Button>

			{modalOpen && (
				<ChildrenModal
					size="lg"
					modal={modalOpen}
					toggle={toggleModal}
					modalTitle="Send Text & Email"
				>
					<SendMessage
						customerEmail={customerEmail}
						sendToNumber={sendToNumber}
						dealID={dealID}
						accountID={accountID}
						personType={personType}
						toggle={toggleModal}
					/>
				</ChildrenModal>
			)}
		</>
	);
};

export default Communication;
