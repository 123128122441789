import React, { useState, useEffect } from "react";
import useCurrentDealStore from "stores/DealStore";
import { useDigitzsMerchantId, useFromUser } from "stores/LocalStorageHelper";
import { Button, Col, Form, Row } from "reactstrap";
import InputElement, {
	CurrencyInputElement,
} from "features/forms/components/InputElement";
import InputWrapper from "features/forms/components/InputWrapper";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { setLocale } from "yup";
import { useForm, FormProvider } from "react-hook-form";
import { DealStatus } from "constants/Constants";
import shallow from "zustand/shallow";
import PropTypes from "prop-types";
import DigitzsImg from "assets/img/digitzs-final-logo.png";
import DigitzsPayment from "../../../../../../services/digitzsService/DigitzsPayment";
import ChildrenModal from "../../../../../../components/modals/ChildrenModal";
import ListItemModal from "../../../../../settings/subFeatures/settingsTabs/signUp/ListItemModal";

/**
 * PickupPaymentForm component to handle adding and editing down payments.
 *
 * @param {Object} props - The component props.
 * @param {number} props.currentIndex - The current index of the down payment being edited.
 * @param {function} props.setCurrentIndex - Function to set the current index.
 * @param {function} props.toggle - Function to toggle the form visibility.
 */
const PickupPaymentForm = ({ currentIndex, setCurrentIndex, toggle }) => {
	const { pickupPmts, editPickupPmts, dealRecNum, type, calculate } =
		useCurrentDealStore(
			(state) => ({
				pickupPmts: state.pickupPmts,
				editPickupPmts: state.editPickupPmts,
				dealRecNum: state.deal.dealRecNum,
				type: state.deal.type,
				calculate: state.calculate,
			}),
			shallow
		);

	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const userID = useFromUser("ID");

	const [dateDue, setDateDue] = useState("");
	const [amount, setAmount] = useState(0);
	const [datePaid, setDatePaid] = useState("");
	const [amountPaid, setAmountPaid] = useState(0);
	const [receiptNo, setReceiptNo] = useState("");

	const clearFields = () => {
		setDateDue("");
		setAmount(0);
		setDatePaid("");
		setAmountPaid(0);
		setReceiptNo("");
		setCurrentIndex(pickupPmts.length);
	};

	useEffect(() => {
		if (currentIndex < pickupPmts.length) {
			setDateDue(pickupPmts[currentIndex]?.dateDue || "");
			setAmount(pickupPmts[currentIndex]?.amount || 0);
			setDatePaid(pickupPmts[currentIndex]?.datePaid || "");
			setAmountPaid(pickupPmts[currentIndex]?.amountPaid || 0);
			setReceiptNo(pickupPmts[currentIndex]?.receiptNo || "");
		}
		return clearFields;
		// eslint-disable-next-line
	}, [currentIndex]);
	const merchantId = useDigitzsMerchantId();
	const [digitzsModal, setDigitzsModal] = useState(false);
	const toggleDigitzsModal = () => setDigitzsModal(!digitzsModal);
	const toggleDigitzsModalApi = () => {
		if (merchantId !== null) {
			failAlert(
				"Digitzs account already exists for this dealership with merchant ID: " +
					res.data.content.merchantId
			);
			return;
		}
		setDigitzsModal(!digitzsModal);
	};

	const addPickupPayment = () => {
		const getID = () => {
			if (pickupPmts[currentIndex] === undefined) {
				return null;
			} else if (pickupPmts[currentIndex].ID === undefined) {
				return null;
			} else {
				return pickupPmts[currentIndex].ID;
			}
		};
		const ID = getID();
		const newPickup = {
			dateDue,
			amount: parseFloat(amount),
			datePaid,
			amountPaid: parseFloat(amountPaid),
			receiptNo,
			currentIndex,
			dealerID,
			locationID,
			ID,
			dealRecNum,
			userRecNum: userID,
		};
		console.log(newPickup);
		console.log("Current index is: " + currentIndex);

		const pickupList = () => {
			let obj = [...pickupPmts]; // Create a copy of pickupPmts
			obj[currentIndex] = newPickup;
			return obj;
		};
		const newPickupObj = pickupList();
		editPickupPmts(newPickupObj);
		clearFields();
		calculate();
		toggle();
	};

	const digitzsLink = () => {
		window.open(
			"https://myvalet.ondeets.ai/sign-up",
			"_blank",
			"noopener,noreferrer"
		);
	};

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			max: "Must be ${max} characters or less",
			length: "Required format is YYYY/MM/DD",
		},
	});

	// Define rules for each input field
	const schema = yup.object().shape({
		dateDue: yup.string().required().max(50),
		amount: yup.string().required().max(50),
	});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	return (
		<>
			<FormProvider {...methods}>
				<Form>
					<InputWrapper
						formTitle="Add Deferred Payment"
						inputComponents={[
							<InputElement
								key="dateDue"
								{...methods}
								readOnly={type !== DealStatus.PENDING}
								value={dateDue}
								onChange={(e) => setDateDue(e.target.value)}
								name="dateDue"
								label="Due Date"
								type="date"
							/>,
							<CurrencyInputElement
								key="amount"
								readOnly={type !== DealStatus.PENDING}
								value={amount}
								onChange={setAmount}
								name="amount"
								label="Amount"
								type="number"
							/>,
							<InputElement
								key="datePaid"
								{...methods}
								readOnly={type !== DealStatus.PENDING}
								value={datePaid}
								onChange={(e) => setDatePaid(e.target.value)}
								name="datePaid"
								label="Date Paid"
								type="date"
							/>,
							<CurrencyInputElement
								key="amountPaid"
								readOnly={type !== DealStatus.PENDING}
								value={amountPaid}
								onChange={setAmountPaid}
								name="amountPaid"
								label="Amount Paid"
								type="number"
							/>,
							<InputElement
								key="receiptNo"
								{...methods}
								readOnly={type !== DealStatus.PENDING}
								value={receiptNo}
								onChange={(e) => setReceiptNo(e.target.value)}
								name="receiptNo"
								label="Receipt #"
								type="text"
							/>,
						]}
						buttons={
							<div style={{ display: "flex", justifyContent: "center" }}>
								<Button
									readOnly={type !== DealStatus.PENDING}
									color="primary"
									onClick={methods.handleSubmit(addPickupPayment)}
								>
									Save
								</Button>
							</div>
						}
					/>
				</Form>
			</FormProvider>
			<div>
				{!merchantId && (
					<Row className="mt-2 justify-content-center">
						<Col xs="12" sm="12" md="6" lg="6" xl="4" className="mb-3 d-grid ">
							<ListItemModal
								modalListTitle="Digitzs"
								modalButtonTitle="Sign Up"
								toggle={digitzsLink}
								cardClassName="mb-2"
								imageLogo={DigitzsImg}
								disabled={false}
								integrationInfo="Digitzs is our preferred payment processing solution. Low rates, quick transfers, and fully integrated into your system."
							/>
						</Col>
					</Row>
				)}
				{merchantId && (
					<>
						<ChildrenModal modal={digitzsModal} toggle={toggleDigitzsModal}>
							<DigitzsPayment pmtAmount={amount} />
						</ChildrenModal>
						<Button onClick={toggleDigitzsModal}>Process Credit Card</Button>
					</>
				)}
			</div>
		</>
	);
};

PickupPaymentForm.propTypes = {
	currentIndex: PropTypes.number.isRequired,
	setCurrentIndex: PropTypes.func.isRequired,
	toggle: PropTypes.func.isRequired,
};

export default PickupPaymentForm;
