/*!
=========================================================
* DealerClick 2.0 - v1.0
=========================================================

* Coded by DealerClick Software ))
=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import { Card, CardBody, Row, Col, CardHeader, CardTitle } from "reactstrap";
import dayjs from "dayjs";
import ReactTable from "../../../components/tables/ReactTable";

const ScheduledTable = ({ data }) => {
	console.log(data);
	// Optional: format message type if stored as number
	const formatMessageType = (value) => {
		if (value === 0) return "SMS";
		if (value === 1) return "Email";
		if (value === 2) return "SMS & Email";
		return "Unknown";
	};

	return (
		<div>
			<div className="pt-3 pb-3 mobileHeading">
				<Col className="text-center">
					<CardTitle></CardTitle>
				</Col>
			</div>
			<Row>
				<Col md="12">
					<Card>
						<CardHeader>
							<h4 className="mb-0">Scheduled Communications</h4>
						</CardHeader>
						<CardBody>
							<ReactTable
								className="-striped -highlight primary-pagination"
								data={data}
								columns={[
									{
										Header: "Source",
										accessor: "source",
									},
									{
										Header: "Type",
										accessor: "messageType",
										Cell: ({ value }) => formatMessageType(value),
									},
									{
										Header: "Cell",
										accessor: "cell",
									},
									{
										Header: "Email From",
										accessor: "emailFrom",
									},
									{
										Header: "Email To",
										accessor: "emailTo",
									},
									{
										Header: "Message",
										accessor: "message",
										style: { whiteSpace: "pre-wrap" },
									},
									{
										Header: "Schedule Date",
										accessor: "scheduleDate",
										Cell: ({ value }) =>
											value ? dayjs(value).format("MM/DD/YYYY") : "",
									},
									{
										Header: "Active",
										accessor: "active",
										Cell: ({ value }) => (value ? "Yes" : "No"),
									},
									{
										Header: "Actions",
										accessor: "actions",
										disableFilters: true,
										Cell: ({ row }) => (
											<div>
												{/* Add your action buttons or links here */}
												<button className="btn btn-sm btn-primary">View</button>
											</div>
										),
									},
								]}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default ScheduledTable;
