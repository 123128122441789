import React, { useContext, useEffect, useState } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers";
import {
	displayError,
	//createPdfAndDownload,
	displayPdf,
	formLoading,
	pdfEsignature,
} from "api/FormsAPI";
import ChildrenModal from "components/modals/ChildrenModal";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import * as yup from "yup";
import InputElement, {
	CheckBoxElement,
	CurrencyInputElement,
	IntegerInputElement,
	TextAreaInput,
} from "features/forms/components/InputElement";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	CardText,
	CardTitle,
	Col,
	FormGroup,
	Input,
	Row,
} from "reactstrap";
import swal from "sweetalert2";
import { getPdf, saveDefaults } from "api/FormsAPI";
import { WizardButtons } from "components/modals/DynamicModal";
import { FormsReportsContext } from "../context/FormsReportsContext";
import { PackRequestContext } from "../context/PackContexts";
import { retrieveFailAlert, saveSuccessAlert } from "utils/alertUtils";
import {
	useFormsReports,
	useFromFormsReports,
} from "stores/LocalStorageHelper";
import { showApiError } from "../../../utils/errorRoutingUtils";

const PDFInputPicker = ({ parameterName, setParams, type, defaultVal }) => {
	const [param, setParam] = useState(defaultVal);

	// console.log(type);

	// set up default from date and to date
	// from date start at the beginning of month
	// todate is today
	const today = new Date();
	const dd = String(today.getDate()).padStart(2, "0");
	const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
	const yyyy = today.getFullYear();
	const toDate = yyyy + "-" + mm + "-" + dd;
	const fromDate = yyyy + "-" + mm + "-01";

	useEffect(() => {
		if (type === "FromDate") {
			setParam(fromDate);
			setParams(parameterName, fromDate);
		} else if (type === "ToDate") {
			setParam(toDate);
			setParams(parameterName, toDate);
		} else {
			setParams(parameterName, defaultVal);
		}

		// eslint-disable-next-line
	}, []);

	const methods = useFormContext();
	if (type === "BigDecimal") {
		return (
			<CurrencyInputElement
				name={parameterName}
				value={param}
				colSize="col"
				onChange={(values) => {
					setParam(values);
					setParams(parameterName, values);
				}}
			/>
		);
	} else if (type === "LocalDate" || type === "ToDate" || type === "FromDate") {
		return (
			<InputElement
				{...methods}
				type={"date"}
				value={param}
				onChange={(e) => {
					console.log(e.target.value);
					setParam(e.target.value);
					setParams(parameterName, e.target.value);
				}}
				name={parameterName}
				colSize="col"
			/>
		);
	} else if (type === "Integer") {
		return (
			<IntegerInputElement
				colSize="col"
				value={param}
				name={parameterName}
				type="number"
				onChange={(values) => {
					if (values === "") {
						setParam(0);
						setParams(parameterName, 0);
					} else {
						setParam(values);
						setParams(parameterName, values);
					}
				}}
			/>
		);
	} else if (type === "option") {
		return (
			<div className="d-flex justify-content-end">
				<CheckBoxElement
					{...methods}
					checked={param}
					onChange={(e) => {
						setParam(!param);
						//need to add or remove from array of options here
						if (e.target.checked) {
							setParams.addOption(parameterName);
						} else {
							setParams.removeOption(parameterName);
						}
					}}
					name={parameterName}
				/>
			</div>
		);
	} else if (type.multiOption) {
		return type.multiOption.map((option) => (
			<>
				<ParamLabelAndInput displayName={option.label}>
					<PDFInputPicker
						parameterName={option.value}
						type={"option"}
						setParams={setParams}
					/>
				</ParamLabelAndInput>
				<br />
			</>
		));
	} else if (type.dropdown) {
		if (param === "") {
			const initialVal = type.dropdown[Object.keys(type.dropdown)[0]].value;
			setParam(initialVal);
			setParams(parameterName, initialVal);
		}
		const options = type.dropdown.map((option) => (
			<option value={option.value}>{option.label}</option>
		));
		return (
			<FormGroup className="col">
				<Input
					type="select"
					colSize="col"
					value={param}
					name={parameterName}
					onChange={(e) => {
						setParam(e.target.value);
						setParams(parameterName, e.target.value);
					}}
				>
					{options}
				</Input>
			</FormGroup>
		);
	} else if (type === "String") {
		return (
			<InputElement
				{...methods}
				type={"text"}
				value={param}
				onChange={(e) => {
					console.log(e.target.value);
					setParam(e.target.value);
					setParams(parameterName, e.target.value);
				}}
				name={parameterName}
				colSize="col"
			/>
		);
	} else if (type === "TextArea") {
		return (
			<TextAreaInput
				{...methods}
				value={param}
				onChange={(e) => {
					console.log(e.target.value);
					setParam(e.target.value);
					setParams(parameterName, e.target.value);
				}}
				name={parameterName}
				colSize="col"
			/>
		);
	} else if (type === "CheckBox") {
		return (
			<div className="d-flex justify-content-end p-3">
				<CheckBoxElement
					{...methods}
					checked={param}
					onChange={(e) => {
						setParam(!param);
						setParams(parameterName, !param);
					}}
					name={parameterName}
				/>
			</div>
		);
	} else return null;
};

const EsignInputPicker = ({ parameterName, setParams, type }) => {
	const [param, setParam] = useState("");
	console.log(type);
	const methods = useFormContext();
	if (type === "option") {
		return (
			<div className="d-flex justify-content-end">
				<CheckBoxElement
					{...methods}
					checked={param}
					onChange={(e) => {
						setParam(!param);
						//need to add or remove from array of options here
						if (e.target.checked) {
							setParams.addOption(parameterName);
						} else {
							setParams.removeOption(parameterName);
						}
					}}
					name={parameterName}
				/>
			</div>
		);
	} else if (type.multiOption) {
		return type.multiOption.map((option) => (
			<>
				<ParamLabelAndInput displayName={option.label}>
					<EsignInputPicker
						parameterName={option.value}
						type={"option"}
						setParams={setParams}
					/>
				</ParamLabelAndInput>
				<br />
			</>
		));
	}
};

const MultiOptionInput = ({
	parameterName,
	displayName,
	type,
	setParams,
	isEsign = false,
}) => {
	const [optionList, setOptionList] = useState([]);
	const addOption = (opt) => {
		let temp = [...optionList];
		temp.push(opt);
		setOptionList(temp);
		setParams(parameterName, temp);
	};

	const removeOption = (opt) => {
		let temp = [...optionList];
		const filteredTemp = temp.filter((e) => e !== opt);
		setOptionList(filteredTemp);
		setParams(parameterName, filteredTemp);
	};

	if (displayName === "Status") {
		displayName = "Status (Select at least one)";
	}

	if (!isEsign) {
		return (
			<Row>
				<Col sm="5">
					<CardText tag="h5">{displayName}</CardText>
				</Col>
				<Col sm="7">
					<PDFInputPicker
						type={type}
						parameterName={parameterName}
						setParams={{ addOption, removeOption }}
					/>
				</Col>
			</Row>
		);
	} else {
		return (
			<Row>
				<Col sm="5">
					<CardText tag="h5">{displayName}</CardText>
				</Col>
				<Col sm="7">
					<EsignInputPicker
						type={type}
						parameterName={parameterName}
						setParams={{ addOption, removeOption }}
					/>
				</Col>
			</Row>
		);
	}
};

const ParamLabelAndInput = ({ displayName, children }) => {
	return (
		<Row className="align-items-center">
			<Col sm="6">
				<CardText tag="h5">{displayName}</CardText>
			</Col>
			<Col sm="6">{children}</Col>
		</Row>
	);
};

export const FormOrReportRequest = ({
	modal,
	toggle,
	parameters,
	isWizard = false,
	dcReport,
	submit,
	dealerTempID,
}) => {
	const IN_DEVELOPMENT = process.env.NODE_ENV === "development";
	const { setParams, pdfRequest, setPdfRequest } =
		useContext(FormsReportsContext);
	const { setParamsList, setLenderPackRequest } =
		useContext(PackRequestContext);

	const handleDefaultsChange = (defaults = null, defaultsID = null) => {
		setPdfRequest((prevState) => {
			return {
				...prevState,
				dcReport: {
					...prevState.dcReport,
					defaults,
					defaultsID,
				},
			};
		});
	};
	const handleRequestChange = (requestparam, value) => {
		setParams((prevState) => {
			return {
				...prevState,
				[requestparam]: value,
			};
		});
		setPdfRequest((prevState) => {
			return {
				...prevState,
				params: {
					...prevState.params,
					[requestparam]: value,
				},
			};
		});
		setParamsList((prevState) => {
			return {
				...prevState,
				[dcReport.id]: {
					...prevState[dcReport.id],
					[requestparam]: value,
				},
			};
		});
		setLenderPackRequest((prevState) => {
			return {
				...prevState,
				paramsList: {
					...prevState.paramsList,
					[dcReport.id]: {
						...prevState.paramsList[dcReport.id],
						[requestparam]: value,
					},
				},
			};
		});
	};
	// not sure why it needs to be double parsed, maybe what we have stored is double stringified?
	const paramsToRender = JSON.parse(parameters);
	const defaultParams = JSON.parse(
		pdfRequest?.dcReport?.defaults || dcReport.defaults
	);

	let isOption = false;

	const paramLabelAndInputMap = paramsToRender.parameters.map((element) => {
		let defaultVal;
		const paramType = element.type;
		if (paramType === "Integer" || paramType === "BigDecimal") {
			defaultVal = 0;
		} else if (paramType === "CheckBox") {
			defaultVal = false;
		} else if (paramType.dropdown) {
			defaultVal = paramType.dropdown[Object.keys(paramType.dropdown)[0]].value;
		} else {
			defaultVal = "";
		}
		if (defaultParams !== null) {
			defaultVal = defaultParams[element.parameterName];
		}
		if (element.type.multiOption) {
			isOption = true;
			return (
				<>
					<MultiOptionInput
						parameterName={element.parameterName}
						displayName={element.displayName}
						type={paramType}
						setParams={handleRequestChange}
					/>
					<br />
				</>
			);
		} else {
			return (
				<>
					<ParamLabelAndInput
						parameterName={element.parameterName}
						displayName={element.displayName}
						type={paramType}
						setParams={handleRequestChange}
						defaultVal={defaultVal}
					>
						<PDFInputPicker
							type={paramType}
							parameterName={element.parameterName}
							setParams={handleRequestChange}
							defaultVal={defaultVal}
						/>
					</ParamLabelAndInput>
				</>
			);
		}
	});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(getSchemaObj(paramsToRender.parameters)),
	});

	const Footer = () => {
		return (
			<div className="d-flex justify-content-center">
				<>
					<SaveDefaultsButton
						isOption={isOption}
						handleDefaultsChange={handleDefaultsChange}
					/>
					<SubmitButton
						toggle={toggle}
						isOption={isOption}
						fileName={
							dcReport.fileName + "_" + pdfRequest.dealID === null
								? ""
								: pdfRequest.dealID + ".pdf"
						}
						dealerTempID={dealerTempID}
					/>
					{/* <EsignButton dealerTempID={dealerTempID} /> */}
					{IN_DEVELOPMENT && (
						<Button
							onClick={() => {
								pdfRequest.dealerTempID = dealerTempID;
								console.log(pdfRequest);
							}}
						>
							JSON
						</Button>
					)}
				</>
			</div>
		);
	};

	if (!isWizard) {
		return (
			<FormProvider {...methods}>
				<ChildrenModal toggle={toggle} size="lg" modal={modal}>
					<>
						<Card>
							<CardHeader>
								<CardTitle tag="h5">{dcReport.title}</CardTitle>
							</CardHeader>
							<CardBody>{paramLabelAndInputMap}</CardBody>
						</Card>
						<ErrorMessage
							name="options"
							render={({ message }) => (
								<p className="error_text d-flex justify-content-center">
									{message}
								</p>
							)}
						/>
					</>
					<Footer />
				</ChildrenModal>
			</FormProvider>
		);
	} else {
		return (
			<FormProvider {...methods}>
				<Card>
					<CardHeader>
						<CardTitle tag="h5">{dcReport.title}</CardTitle>
					</CardHeader>
					<CardBody>
						{paramLabelAndInputMap}
						<ErrorMessage
							name="options"
							render={({ message }) => (
								<p className="error_text d-flex justify-content-center">
									{message}
								</p>
							)}
						/>
						<WizardButtons submit={submit} />
					</CardBody>
				</Card>
			</FormProvider>
		);
	}
};

export const EsignRequest = ({ otherSignatures, dcReport, submit }) => {
	const { setOtherSignatures, setPdfRequest } = useContext(FormsReportsContext);
	const { setOtherSignaturesList, setLenderPackRequest } =
		useContext(PackRequestContext);

	const handleRequestChange = (requestparam, value) => {
		setOtherSignatures((prevState) => {
			return {
				...prevState,
				[requestparam]: value,
			};
		});
		setPdfRequest((prevState) => {
			return {
				...prevState,
				otherSignatures: {
					...prevState.otherSignatures,
					[requestparam]: value,
				},
			};
		});
		setOtherSignaturesList((prevState) => {
			return {
				...prevState,
				[dcReport.id]: {
					...prevState[dcReport.id],
					[requestparam]: value,
				},
			};
		});
		setLenderPackRequest((prevState) => {
			return {
				...prevState,
				otherSignaturesList: {
					...prevState.otherSignaturesList,
					[dcReport.id]: {
						...prevState.otherSignaturesList[dcReport.id],
						[requestparam]: value,
					},
				},
			};
		});
	};

	const otherSigsToRender = JSON.parse(otherSignatures);
	const paramLabelAndInputMap = otherSigsToRender.signatures.map((element) => {
		const paramType = element.type;
		if (element.type.multiOption) {
			return (
				<>
					<MultiOptionInput
						parameterName={element.parameterName}
						displayName={element.displayName}
						type={paramType}
						setParams={handleRequestChange}
						isEsign={true}
					/>
					<br />
				</>
			);
		} else {
			return (
				<>
					<ParamLabelAndInput displayName={element.displayName}>
						<EsignInputPicker
							parameterName={element.parameterName}
							type={paramType}
							setParams={handleRequestChange}
						/>
					</ParamLabelAndInput>
				</>
			);
		}
	});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(getSchemaObj(otherSigsToRender.signatures)),
	});

	return (
		<FormProvider {...methods}>
			<Card>
				<CardHeader>
					<CardTitle tag="h5">{dcReport.title}</CardTitle>
				</CardHeader>
				<CardBody>
					{paramLabelAndInputMap}
					<ErrorMessage
						name="options"
						render={({ message }) => (
							<p className="error_text d-flex justify-content-center">
								{message}
							</p>
						)}
					/>
					<WizardButtons submit={submit} />
				</CardBody>
			</Card>
		</FormProvider>
	);
};

const getSchemaObj = (obj) => {
	let schemaObj = {};
	obj.map((key) => {
		// console.log(key);
		if (key.type === "BigDecimal")
			return (schemaObj = {
				...schemaObj,
				[key.parameterName]: yup.string().required("Invalid Price").max(50),
			});
		else if (key.type === "Integer")
			return (schemaObj = {
				...schemaObj,
				[key.parameterName]: yup.string().required("Invalid Number"),
			});
		else if (
			key.type === "LocalDate" ||
			key.type === "FromDate" ||
			key.type === "ToDate"
		) {
			if (key.parameterName === "fromDate") {
				return (schemaObj = {
					...schemaObj,
					[key.parameterName]: yup
						.date()
						.required("Date Required")
						.nullable()
						.transform((curr, orig) => (orig?.length === 10 ? curr : null))
						.max(yup.ref("toDate"), "From date cannot be after to date"),
				});
			} else if (key.parameterName === "toDate") {
				return (schemaObj = {
					...schemaObj,
					[key.parameterName]: yup
						.date()
						.required("Date Required")
						.nullable()
						.transform((curr, orig) => (orig?.length === 10 ? curr : null))
						.min(yup.ref("fromDate"), "To date cannot be before from date"),
				});
			} else {
				return (schemaObj = {
					...schemaObj,
					[key.parameterName]: yup
						.date()
						.required("Date Required")
						.nullable()
						.transform((curr, orig) => (orig?.length === 10 ? curr : null))
						.min("1990-01-01", `Must be after 01/01/1990`)
						.max("2030-12-30", "Must be before 12/30/2030"),
				});
			}
		}
		return (schemaObj = {
			...schemaObj,
			[key.parameterName]: yup.mixed(),
		});
	});
	return yup.object().shape(schemaObj);
};

const EsignButton = ({ dealerTempID }) => {
	const methods = useFormContext();
	const { pdfRequest } = useContext(FormsReportsContext);
	const submit = () => {
		formLoading("eSignature");
		pdfRequest.dealerTempID = dealerTempID;
		pdfEsignature(pdfRequest).then(
			(response) => {
				swal.close();
				saveSuccessAlert(response.data.message);
			},
			(err) => {
				if (!err.isGeneralError) {
					console.log(err);
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};

	return <Button onClick={methods.handleSubmit(submit)}>eSign</Button>;
};

const SubmitButton = ({ toggle, isOption = false, fileName, dealerTempID }) => {
	const methods = useFormContext();
	const { pdfRequest } = useContext(FormsReportsContext);
	const submit = () => {
		if (
			(pdfRequest.params?.statusList == null ||
				pdfRequest.params?.statusList?.length < 1) &&
			isOption
		) {
			methods.setError("options", {
				message: "Select at least one of the status",
			});
			return;
		}
		formLoading("PDF");
		pdfRequest.dealerTempID = dealerTempID;
		getPdf(pdfRequest).then(
			(response) => {
				//createPdfAndDownload(response, fileName);
				displayPdf(response);
				swal.close();
			},
			(err) => {
				displayError(err);
			}
		);
		//toggle();
	};

	return <Button onClick={methods.handleSubmit(submit)}>Submit</Button>;
};

const SaveDefaultsButton = ({
	isOption = false,
	fileName,
	handleDefaultsChange,
}) => {
	const methods = useFormContext();
	const { pdfRequest } = useContext(FormsReportsContext);
	const { listName } = useContext(FormsReportsContext);
	let list = useFromFormsReports(listName);
	let formsReports = useFormsReports();

	const updateFormsReportsList = (paramsString, defaultsID, dcReportID) => {
		if (list) {
			const index = list.findIndex((obj) => obj.id === dcReportID);
			list[index].defaults = paramsString;
			list[index].defaultsID = defaultsID;
			formsReports[listName] = list;
			sessionStorage.setItem("formsReports", JSON.stringify(formsReports));
		}
	};

	const save = () => {
		if (
			(pdfRequest.params?.statusList == null ||
				pdfRequest.params?.statusList?.length < 1) &&
			isOption
		) {
			methods.setError("options", {
				message: "Select at least one of the status",
			});
			return;
		}
		const dcReportsDefaults = {
			id: pdfRequest.dcReport.defaultsID,
			dealerID: pdfRequest.params.dealerID,
			locationID: pdfRequest.params.locationID,
			dcReportID: pdfRequest.dcReportID,
			defaults: JSON.stringify(pdfRequest.params),
		};
		saveDefaults(dcReportsDefaults).then(
			(response) => {
				console.log(pdfRequest);
				console.log(dcReportsDefaults);
				const defaults = response.data.content.defaults;
				const defaultsID = response.data.content.id;
				handleDefaultsChange(defaults, defaultsID);
				updateFormsReportsList(defaults, defaultsID, pdfRequest.dcReportID);
				saveSuccessAlert();
			},
			(err) => {
				displayError(err);
			}
		);
	};

	return <Button onClick={methods.handleSubmit(save)}>Save as Default</Button>;
};
