import create from "zustand";
import { convertToDollar } from "../features/deal/helperFunctions/SaleFunctions";

const useCurrentSaleStore = create((set) => ({
	ID: null,
	price: 0.0,
	dealerSmog: 0.0,
	docFee: 0.0,
	gap: 0.0,
	warranty: 0.0,
	aftmktNonTaxable: 0.0,
	aftmkt: 0.0,
	trade: 0.0,
	payoff: 0.0,
	down: 0.0,
	saleType: 0,
	servContractTaxRate: 0.0,
	stateSmog: 0.0,
	warrantyTax: 0.0,
	userTax1: 0.0,
	userTax2: 0.0,
	userTax3: 0.0,
	userTax4: 0.0,
	userTax5: 0.0,
	userTax6: 0.0,
	tax: 0.0,
	loanDate: "",
	firstDueDate: "",
	finalDueDate: "",
	loanPeriod: 5,
	term: 1,
	apr: 0.0,
	regularPmt: 0.0,
	payment: 0.0,
	finalPmt: 0.0,
	amtFinanced: 0.0,
	financeCharge: 0.0,
	loan: 0.0,
	license: 0.0,
	licenseFee: 0.0,
	licensePct: 0.0,
	registration: 0.0,
	registrationFee: 0.0,
	registrationPct: 0.0,
	titleFee: 0.0,
	filingFee: 0.0,
	smogExemption: 0.0,
	weightFee: 0.0,
	tireFee: 0.0,
	bpa: 0.0,
	totalDMVFees: 0.0,
	totalSellerFees: 0.0,
	totalNonTaxableSellerFees: 0.0,
	totalNetTrade: 0.0,
	manufacturerRebate: 0.0,
	taxRatesList: [],
	daysToPay: 0,
	totalPickupPmts: 0.0,
	gapTerm: 0,
	aftmktCost: 0.0,
	fiDiscount: 0.0,
	dealNumber: "",
	taxableAmount: 0.0,
	taxRate: 0.0,
	salesTax: 0.0,
	totalTaxable: 0.0,
	localTax: 0.0,
	reserve: 0.0,
	reserveFlag: false,
	businessTax: 0.0,
	businessTaxRate: 0.0,
	fetTax: 0.0,
	fetTaxRate: 0.0,
	// luxuryTax: 0.0,
	vit: 0.0,
	vitRate: 0.0,
	taxType: 0,
	monthlyTax: 0.0, //Deferred Monthly Tax
	deferredTax: 0.0,
	discount: 0.0,

	creditLifeFlag: 0,
	disabilityFlag: 0,
	lifePremium: 0.0,
	disabilityPremium: 0.0,
	fixedAmountTax: 0.0,
	tradeTaxCredit: 0.0,

	editCreditLifeFlag: (val) => set(() => ({ creditLifeFlag: val })),
	editDisabilityFlag: (val) => set(() => ({ disabilityFlag: val })),
	editLifePremium: (val) => set(() => ({ lifePremium: val })),
	editDisabilityPremium: (val) => set(() => ({ disabilityPremium: val })),
	bankFee: 0.0,
	addOn: 0.0,
	rollBack: 0,
	rollPreValue: 0,
	balloon: 0.0,
	balloonTerm: 0,
	balloonDate: "",
	hasBalloon: false,
	balloonFinanceCharge: 0.0,
	onePay: 0.0,
	// not actally in sale but I need this globally for rolling
	desiredPmt: 0.0,
	collisionTotPremium: 0.0,

	editCollisionTotPremium: (val) => set(() => ({ collisionTotPremium: val })),
	editID: (val) => set(() => ({ ID: val })),
	editPrice: (val) => set(() => ({ price: val })),
	editDealerSmog: (val) => set(() => ({ dealerSmog: val })),
	editDocFee: (val) => set(() => ({ docFee: val })),
	editGap: (val) => set(() => ({ gap: val })),
	editWarranty: (val) => set(() => ({ warranty: val })),
	editAftmktNonTaxable: (val) => set(() => ({ aftmktNonTaxable: val })),
	editAftmkt: (val) => set(() => ({ aftmkt: val })),
	editTrade: (val) => set(() => ({ trade: val })),
	editPayoff: (val) => set(() => ({ payoff: val })),
	editDown: (val) => set(() => ({ down: val })),
	editSaleType: (val) => set(() => ({ saleType: val })),
	editServContractTaxRate: (val) => set(() => ({ servContractTaxRate: val })),
	editStateSmog: (val) => set(() => ({ stateSmog: val })),
	editWarrantyTax: (val) => set(() => ({ warrantyTax: val })),
	editUserTax1: (val) => set(() => ({ userTax1: val })),
	editUserTax2: (val) => set(() => ({ userTax2: val })),
	editUserTax3: (val) => set(() => ({ userTax3: val })),
	editUserTax4: (val) => set(() => ({ userTax4: val })),
	editUserTax5: (val) => set(() => ({ userTax5: val })),
	editUserTax6: (val) => set(() => ({ userTax6: val })),
	editTax: (val) => set(() => ({ tax: val })),
	editLoanDate: (val) => set(() => ({ loanDate: val })),
	editFirstDueDate: (val) => set(() => ({ firstDueDate: val })),
	editFinalDueDate: (val) => set(() => ({ finalDueDate: val })),
	editLoanPeriod: (val) => set(() => ({ loanPeriod: val })),
	editTerm: (val) => set(() => ({ term: val })),
	editApr: (val) => set(() => ({ apr: val })),
	editRegularPmt: (val) => set(() => ({ regularPmt: val })),
	editPayment: (val) => set(() => ({ payment: val })),
	editFinalPmt: (val) => set(() => ({ finalPmt: val })),
	editAmtFinanced: (val) => set(() => ({ amtFinanced: val })),
	editFinanceCharge: (val) => set(() => ({ financeCharge: val })),
	editLoan: (val) => set(() => ({ loan: val })),
	editLicense: (val) => set(() => ({ license: convertToDollar(val) })),
	editLicenseFee: (val) => set(() => ({ licenseFee: val })),
	editLicensePct: (val) => set(() => ({ licensePct: val })),
	editRegistration: (val) =>
		set(() => ({ registration: convertToDollar(val) })),
	editRegistrationFee: (val) => set(() => ({ registrationFee: val })),
	editRegistrationPct: (val) => set(() => ({ registrationPct: val })),
	editTitleFee: (val) => set(() => ({ titleFee: val })),
	editFilingFee: (val) => set(() => ({ filingFee: val })),
	editSmogExemption: (val) => set(() => ({ smogExemption: val })),
	editWeightFee: (val) => set(() => ({ weightFee: val })),
	editTireFee: (val) => set(() => ({ tireFee: val })),
	editBpa: (val) => set(() => ({ bpa: val })),
	editTotalDMVFees: (val) => set(() => ({ totalDMVFees: val })),
	editTotalSellerFees: (val) => set(() => ({ totalSellerFees: val })),
	editTotalNonTaxableSellerFees: (val) =>
		set(() => ({ totalNonTaxableSellerFees: val })),
	editTotalNetTrade: (val) => set(() => ({ totalNetTrade: val })),
	editTaxRatesList: (val) => set(() => ({ taxRatesList: val })),
	editManufacturerRebate: (val) => set(() => ({ manufacturerRebate: val })),
	editDaysToPay: (val) => set(() => ({ daysToPay: val })),
	editTotalPickupPmts: (val) => set(() => ({ totalPickupPmts: val })),
	editGapTerm: (val) => set(() => ({ gapTerm: val })),
	editAftmktCost: (val) => set(() => ({ aftmktCost: val })),
	editFiDiscount: (val) => set(() => ({ fiDiscount: val })),
	editDealNumber: (val) => set(() => ({ dealNumber: val })),
	editTaxableAmount: (val) => set(() => ({ taxableAmount: val })),
	editSalesTax: (val) => set(() => ({ salesTax: val })),
	editTaxRate: (val) => set(() => ({ taxRate: val })),
	editTotalTaxable: (val) => set(() => ({ totalTaxable: val })),
	editReserve: (val) => set(() => ({ reserve: val })),
	editReserveFlag: (val) => set(() => ({ reserveFlag: val })),
	editDiscount: (val) => set(() => ({ discount: val })),
	// editLocalTax: (val) => set(() => ({ localTax: val })),
	editBusinessTax: (val) => set(() => ({ businessTax: val })),
	editBusinessTaxRate: (val) => set(() => ({ businessTaxRate: val })),
	editFetTax: (val) => set(() => ({ fetTax: val })),
	editFetTaxRate: (val) => set(() => ({ fetTaxRate: val })),
	editVit: (val) => set(() => ({ vit: val })),
	editVitRate: (val) => set(() => ({ vitRate: val })),

	// editLuxuryTax: (val) => set(() => ({ luxuryTax: val })),
	editTaxType: (val) => set(() => ({ taxType: val })),
	editMonthlyTax: (val) => set(() => ({ monthlyTax: val })),
	editDeferredTax: (val) => set(() => ({ deferredTax: val })),
	editBankFee: (val) => set(() => ({ bankFee: val })),
	editAddOn: (val) => set(() => ({ addOn: val })),
	editRollBack: (val) => set(() => ({ rollBack: val })),
	editRollPreValue: (val) => set(() => ({ rollPreValue: val })),
	editDesiredPmt: (val) => set(() => ({ desiredPmt: val })),
	editBalloon: (val) => set(() => ({ balloon: val })),
	editBalloonTerm: (val) => set(() => ({ balloonTerm: val })),
	editBalloonDate: (val) => set(() => ({ balloonDate: val })),
	editHasBalloon: (val) => set(() => ({ hasBalloon: val })),
	editBalloonFinanceCharge: (val) => set(() => ({ balloonFinanceCharge: val })),
	editOnePay: (val) => set(() => ({ onePay: val })),
	editFixedAmountTax: (val) => set(() => ({ fixedAmountTax: val })),
	editTradeTaxCredit: (val) => set(() => ({ tradeTaxCredit: val })),
	setSaleFields: (res) => {
		set(() => ({
			...res,
			ID: res.ID,
			price: convertToDollar(res.price),
			dealerSmog: convertToDollar(res.dealerSmog),
			docFee: convertToDollar(res.docFee),
			gap: convertToDollar(res.gap),
			warranty: convertToDollar(res.warranty),
			aftmktNonTaxable: convertToDollar(res.aftmktNonTaxable),
			aftmkt: convertToDollar(res.aftmkt),
			trade: convertToDollar(res.trade),
			payoff: convertToDollar(res.payoff),
			down: convertToDollar(res.down),
			saleType: res.saleType,
			servContractTaxRate: convertToDollar(res.servContractTaxRate),
			stateSmog: convertToDollar(res.stateSmog),
			warrantyTax: convertToDollar(res.warrantyTax),
			userTax1: convertToDollar(res.userTax1),
			userTax2: convertToDollar(res.userTax2),
			userTax3: convertToDollar(res.userTax3),
			userTax4: convertToDollar(res.userTax4),
			tax: convertToDollar(res.tax),
			loanDate: res.loanDate,
			firstDueDate: res.firstDueDate,
			finalDueDate: res.finalDueDate,
			loanPeriod: res.loanPeriod,
			term: res.term,
			apr: parseFloat(parseFloat(res.apr).toFixed(6)),
			payment: convertToDollar(res.payment),
			finalPmt: convertToDollar(res.finalPmt),
			amtFinanced: convertToDollar(res.amtFinanced),
			financeCharge: convertToDollar(res.financeCharge),
			loan: convertToDollar(res.loan),
			license: convertToDollar(res.license),
			licenseFee: convertToDollar(res.licenseFee),
			licensePct: res.licensePct,
			registration: convertToDollar(res.registration),
			registrationFee: convertToDollar(res.registrationFee),
			registrationPct: res.registrationPct,
			titleFee: convertToDollar(res.titleFee),
			filingFee: convertToDollar(res.filingFee),
			smogExemption: convertToDollar(res.smogExemption),
			weightFee: convertToDollar(res.weightFee),
			tireFee: convertToDollar(res.tireFee),
			bpa: convertToDollar(res.bpa),
			totalDMVFees: convertToDollar(res.totalDMVFees),
			totalSellerFees: convertToDollar(res.totalSellerFees),
			totalNonTaxableSellerFees: convertToDollar(res.totalNonTaxableSellerFees),
			manufacturerRebate: res.rebate,
			totalNetTrade: convertToDollar(res.totalNetTrade),
			gapTerm: res.gapTerm,
			aftmktCost: convertToDollar(res.aftmktCost),
			fiDiscount: convertToDollar(res.fiDiscount),
			dealNumber: res.dealNumber,
			taxableAmount: convertToDollar(res.taxableAmount),
			salesTax: convertToDollar(res.salesTax),
			taxRate: res.taxRate,
			totalTaxable: convertToDollar(res.totalTaxable),
			daysToPay: res.daysToPay,
			discount: res.discount,
			bankFee: convertToDollar(res.bankFee),
			addOn: parseFloat(parseFloat(res.addOn).toFixed(4)),
			rollBack: res.rollBack,
			rollPreValue: res.rollPreValue,
			balloon: res.balloon,
			balloonTerm: res.balloonTerm,
			balloonDate: res.balloonDate,
			hasBalloon: res.hasBalloon,
			onePay: convertToDollar(res.onePay),
			collisionTotPremium: convertToDollar(res.collisionTotPremium),
			totalPickupPmts: convertToDollar(res.totalPickupPmts),
			creditLifeFlag: res.creditLifeFlag,
			disabilityFlag: res.disabilityFlag,
			lifePremium: convertToDollar(res.lifePremium),
			disabilityPremium: convertToDollar(res.disabilityPremium),
			taxType: res.taxType,
			fixedAmountTax: convertToDollar(res.fixedAmountTax),
			tradeTaxCredit: convertToDollar(res.tradeTaxCredit),
			vit: convertToDollar(res.vit),
			vitRate: res.vitRate,
			businessTaxRate: res.businessTaxRate,
			businessTax: convertToDollar(res.businessTax),
		}));
	},

	reset: () => {
		console.log("sale reset");
		set({
			ID: null,
			price: 0.0,
			dealerSmog: 0.0,
			docFee: 0.0,
			gap: 0.0,
			warranty: 0.0,
			aftmktNonTaxable: 0.0,
			aftmkt: 0.0,
			trade: 0.0,
			payoff: 0.0,
			down: 0.0,
			saleType: 0,
			servContractTaxRate: 0.0,
			stateSmog: 0.0,
			warrantyTax: 0.0,
			userTax1: 0.0,
			userTax2: 0.0,
			userTax3: 0.0,
			userTax4: 0.0,
			tax: 0.0,
			loanDate: "",
			firstDueDate: "",
			finalDueDate: "",
			loanPeriod: 5,
			term: 1,
			apr: 0.0,
			addOn: 0.0,
			payment: 0.0,
			finalPmt: 0.0,
			amtFinanced: 0.0,
			financeCharge: 0.0,
			loan: 0.0,
			license: 0.0,
			licenseFee: 0.0,
			licensePct: 0.0,
			registration: 0.0,
			registrationFee: 0.0,
			registrationPct: 0.0,
			titleFee: 0.0,
			filingFee: 0.0,
			smogExemption: 0.0,
			weightFee: 0.0,
			tireFee: 0.0,
			bpa: 0.0,
			totalDMVFees: 0.0,
			totalSellerFees: 0.0,
			totalNonTaxableSellerFees: 0.0,
			totalNetTrade: 0.0,
			manufacturerRebate: 0.0,
			taxRatesList: [],
			daysToPay: 0,
			totalPickupPmts: 0.0,
			gapTerm: 0,
			aftmktCost: 0.0,
			fiDiscount: 0.0,
			dealNumber: "",
			taxableAmount: 0.0,
			salesTax: 0.0,
			taxRate: 0.0,
			totalTaxable: 0.0,
			reserve: 0.0,
			reserveFlag: false,
			discount: 0.0,
			bankFee: 0.0,
			// localTax: 0.0,
			businessTax: 0.0,
			businessTaxRate: 0.0,
			fetTax: 0.0,
			fetTaxRate: 0.0,
			// luxuryTax: 0.0,
			vit: 0.0,
			vitRate: 0.0,
			taxType: 0,
			monthlyTax: 0.0,
			deferredTax: 0.0,
			rollBack: 0,
			rollPreValue: 0,
			desiredPmt: 0.0,
			balloon: 0.0,
			balloonTerm: 0,
			balloonDate: "",
			hasBalloon: false,
			onePay: 0.0,
			collisionTotPremium: 0.0,
			creditLifeFlag: 0,
			disabilityFlag: 0,
			lifePremium: 0.0,
			disabilityPremium: 0.0,
			fixedAmountTax: 0.0,
			tradeTaxCredit: 0.0,
		});
	},
}));

export default useCurrentSaleStore;
