import React, { useState } from "react";
import { Col, Row, Table, Button, CardText } from "reactstrap";
import useCurrentDealStore from "stores/DealStore";
import { failAlert, saveFailAlert } from "utils/alertUtils";
import { CurrencyDisplayElement } from "features/deal/components/DisplayElement";
import ChildrenModal from "components/modals/ChildrenModal";
import shallow from "zustand/shallow";
import MoneyDownForm from "./MoneyDownForm";
import { paymentMethod } from "constants/Constants";
import { saveDeal } from "api/DealAPI";
import useSetDeal from "features/deal/hooks/useSetDeal";
import useBuildDeal from "features/deal/hooks/useBuildDeal";
import { useDigitzsMerchantId, useFromUser } from "stores/LocalStorageHelper";
import { displayError, displayPdf, formLoading, getPdf } from "api/FormsAPI";
import { showApiError } from "utils/errorRoutingUtils";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";
import useCurrentSaleStore from "stores/SaleStore";
import Card from "reactstrap/es/Card";
import CardBody from "reactstrap/es/CardBody";
import DigitzsImg from "assets/img/digitzs-final-logo.png";
import ListItemModal from "../../../../../settings/subFeatures/settingsTabs/signUp/ListItemModal";

const MoneyDownTable = () => {
	const merchantId = useDigitzsMerchantId();
	const [digitzsModal, setDigitzsModal] = useState(false);
	const toggleDigitzsModal = () => setDigitzsModal(!digitzsModal);
	const toggleDigitzsModalApi = () => {
		if (merchantId !== null) {
			failAlert(
				"Digitzs account already exists for this dealership with merchant ID: " +
					res.data.content.merchantId
			);
			return;
		}
		setDigitzsModal(!digitzsModal);
	};
	const { down } = useCurrentSaleStore(
		(state) => ({
			down: state.down,
		}),
		shallow
	);

	const { downPayments, editDownPayments, calculate } = useCurrentDealStore(
		(state) => ({
			downPayments: state.downPayments,
			editDownPayments: state.editDownPayments,
			calculate: state.calculate,
		}),
		shallow
	);
	const Head = [
		"#",
		"Date",
		"Receipt No",
		"Amount",
		"Methods",
		"Status",
		"Actions",
	];

	const changeToVoid = (ID, index) => {
		console.log("change status with ID: " + ID);

		let updatedObj = downPayments.map((obj, i) => {
			let o = { ...obj };
			if (index === i) {
				o.status = "1";
			}
			return o;
		});

		editDownPayments(updatedObj);
		setCurrentIndex(updatedObj.length);
		calculate();
		updateSuccessAlert();
	};

	const digitzsLink = () => {
		window.open(
			"https://myvalet.ondeets.ai/sign-up",
			"_blank",
			"noopener,noreferrer"
		);
	};

	const [currentIndex, setCurrentIndex] = useState(downPayments?.length);
	const [showAdd, setShowAdd] = useState(false);
	const toggle = () => setShowAdd(!showAdd);

	const tableHead = Head.map((key, index) => <th key={index}>{key}</th>);

	const rows = downPayments.map((key, index) => (
		<tr key={index}>
			<th scope="row">{index + 1}</th>
			<td> {key.datePaid}</td>
			<td>{key.receiptNo}</td>
			<td>{<CurrencyDisplayElement val={key.amtPaid} />}</td>
			<td>{paymentMethod.filter((e) => key.method === e.value)[0]?.label}</td>
			<td>
				{key.status === "1" || key.status === "void" ? "Void" : "Completed"}
			</td>
			<td>
				<div className="d-flex justify-content-center">
					<div
						className="btn btn-md btn-primary"
						onClick={() => {
							setCurrentIndex(index);
							toggle();
						}}
					>
						<i className="nc-icon nc-align-center"></i>
						<span className="noMobile">Edit</span>
					</div>
					<ReceiptButton paymentId={key.id} />
				</div>
			</td>
		</tr>
	));
	return (
		<>
			<Col>
				<Button
					className="btn btn-primary btn-md"
					onClick={() => {
						setCurrentIndex(downPayments.length);
						toggle();
					}}
				>
					<i className="nc-icon nc-simple-add" />
					Make a new Payment
				</Button>
			</Col>
			<ChildrenModal
				size="lg"
				modal={showAdd}
				toggle={toggle}
				modalTitle="Down"
			>
				<MoneyDownForm
					currentIndex={currentIndex}
					setCurrentIndex={setCurrentIndex}
					toggle={toggle}
				/>
			</ChildrenModal>
			{rows.length > 0 && (
				<Card className="mb-3 mt-2">
					<CardBody className="py-0">
						<div style={{ overflowX: "auto" }}>
							<Table striped hover>
								<thead>
									<tr>{tableHead}</tr>
								</thead>
								<tbody>{rows}</tbody>
							</Table>
						</div>
					</CardBody>
				</Card>
			)}

			{!merchantId && (
				<Row className="mt-2 justify-content-center">
					<Col xs="12" sm="12" md="6" lg="6" xl="4" className="mb-3 d-grid ">
						<ListItemModal
							modalListTitle="Digitzs"
							modalButtonTitle="Sign Up"
							toggle={digitzsLink}
							cardClassName="mb-2"
							imageLogo={DigitzsImg}
							disabled={false}
							integrationInfo="Digitzs is our preferred payment processing solution. Low rates, quick transfers, and fully integrated into your system."
						/>
					</Col>
				</Row>
			)}

			<div>
				<Row className="mt-3 justify-content-center">
					<Col sm="6">
						<CardText tag="h3" className="text-center">
							Total Down
						</CardText>
					</Col>
					<hr className="mb-2 w-75 mx-auto" />
					<Col sm="6">
						<CardText tag="h3" className="text-center">
							<NumberFormat
								value={down}
								thousandSeparator={true}
								decimalScale={2}
								fixedDecimalScale={true}
								prefix={"$"}
								isNumericString={true}
								readOnly={true}
								displayType="text"
							/>
						</CardText>
					</Col>
				</Row>
			</div>
		</>
	);
};

export default MoneyDownTable;

const ReceiptButton = ({ paymentId }) => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const getDeal = useBuildDeal();
	const setDeal = useSetDeal();

	const handlePrint = () => {
		const deal = getDeal();
		const dealID = deal.deal.ID;
		const body = {
			dealID: dealID,
			inventoryID: null,
			dcReportID: 311,
			params: { dealerID: dealerID, locationID: locationID, paymentId },
		};
		formLoading("PDF");
		saveDeal(deal).then(
			(res) => {
				setDeal(res.data.content);
				body.dealID = res.data.content.deal.ID;
				getPdf(body).then(
					(response) => {
						displayPdf(response);
						Swal.close();
					},
					(err) => {
						displayError(err);
					}
				);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	return (
		<Button
			onClick={() => {
				handlePrint();
			}}
		>
			Print
		</Button>
	);
};
