import { useEffect, useState } from "react";
import { getScheduledList } from "../../../api/DealerIntegrationApi";
import ScheduledTable from "./ScheduledTable";
import { useFromUser } from "../../../stores/LocalStorageHelper";
import Loading from "../../../components/loadingSpinner/Loading";

const ScheduledList = () => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const [data, setData] = useState([]);
	const [loadingList, setLoadingList] = useState(true);
	const dataFormat = (content) => {
		const container = Object.keys(content).map((key) => {
			let container = {};
			container.source = content[key].source;
			container.messageType = content[key].cellEmailBoth;
			container.cell = content[key].cell;
			container.emailFrom = content[key].emailFrom;
			container.emailTo = content[key].emailTo;
			container.message = content[key].message;
			container.scheduleDate = content[key].lastExecutionDate;
			container.active = content[key].active;

			container.Actions = (
				<>
					<div className="text-center">
						<div
							className="btn-md btn btn-primary"
							onClick={() => {
								history.push(accountDetailPage, {
									acctID: content[key].acctID,
								});

								//getAccountData(content[key].acctID);
							}}
						>
							<i className="icon-big text-center nc-icon nc-paper text-default"></i>
							<span className="noMobile">&nbsp;View</span>
						</div>
					</div>
				</>
			);
			return container;
		});
		return container;
	};

	const getListData = () => {
		if (dealerID !== undefined && locationID !== undefined) {
			getScheduledList(locationID).then(
				(response) => {
					const result = dataFormat(response.data.content);
					setData(result);
					setLoadingList(false);
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
						history.push(homeRoute);
					}
				}
			);
		}
	};

	useEffect(() => {
		setLoadingList(true);
		getListData();

		// eslint-disable-next-line
	}, []);

	return (
		<div>
			{loadingList && <Loading />}
			{!loadingList && <ScheduledTable data={data} />}
		</div>
	);
};
export default ScheduledList;
