import { useEffect, useCallback } from "react";
import useCurrentSaleStore from "stores/SaleStore";
import useCurrentDealStore from "stores/DealStore";
import useCurrentRecapStore from "stores/RecapStore";
import {
	FindApr,
	calculateAmountFinanced,
	calculateFirstDueDate,
	calculateFinalDueDate,
	calculatePayment,
	calculateFinalPayment,
	calculateLoan,
	calculateFinanceCharge,
	calcLicenseFee,
	calcRegistrationFee,
	calcDMVFees,
	rollByTerm,
	calculateBalloon,
	convertToDollar,
	calculateOCCCexample2,
} from "../helperFunctions/SaleFunctions";
import {
	DealStatus,
	LoanPeriod,
	profitSettingsEnum,
} from "constants/Constants";
import shallow from "zustand/shallow";
import { commissionSettingsEnum, commTypeList } from "constants/Constants";

const useCalculateSales = () => {
	const roundUp = (num, digits = 2) => {
		const factor = Math.pow(10, digits);
		return Math.round(num * factor) / factor;
	};
	const {
		price,
		totalDMVFees,
		gap,
		warranty,
		aftmkt,
		down,
		totalPickupPmts,
		tax,
		manufacturerRebate,
		editAmtFinanced,
		daysToPay,
		loanDate,
		loanPeriod,
		term,
		firstDueDate,
		apr,
		addOn,
		editAddOn,
		amtFinanced,
		loan,
		financeCharge,
		editFirstDueDate,
		editFinalDueDate,
		editPayment,
		editFinalPmt,
		editFinanceCharge,
		editLoan,
		editRegularPmt,
		editAftmkt,
		editAftmktCost,
		editTrade,
		editPayoff: editSalePayoff,
		editTotalNetTrade,
		editTotalPickupPmts,
		docFee,
		dealerSmog,
		stateSmog,
		editTotalSellerFees,
		editTotalNonTaxableSellerFees,
		editWarranty,
		aftmktNonTaxable,
		editAftmktNonTaxable,
		editTaxableAmount,
		editSalesTax,
		editMonthlyTax,
		editDeferredTax,
		editTotalTaxable,
		editBusinessTax,
		editBusinessTaxRate,
		editFetTax,
		editFetTaxRate,
		editVit,
		editVitRate,
		editTax,
		taxRate,
		taxType,
		monthlyTax,
		deferredTax,
		editTaxType,
		editLicense,
		licenseFee,
		licensePct,
		editRegistration,
		registrationFee,
		registrationPct,
		titleFee,
		filingFee,
		smogExemption,
		weightFee,
		tireFee,
		bpa,
		editTotalDMVFees,
		bankFee,
		aftmktCost,
		fiDiscount,
		editFiDiscount,
		discount,
		vit,
		rollBack,
		editTerm,
		desiredPmt,
		balloonTerm,
		editBalloon,
		editBalloonDate,
		editBalloonTerm,
		editBalloonFinanceCharge,
		editDown,
		editOnePay,
		collisionTotPremium,
		editCollisionTotPremium,
		editUserTax1,
		editUserTax2,
		editUserTax3,
		editUserTax4,
		editUserTax5,
		editUserTax6,
		lifePremium,
		disabilityPremium,
		hasBalloon,
		fixedAmountTax,
		editTradeTaxCredit,
		reserveFlag,
		editWarrantyTax,
		editRollPreValue,
		editRollBack,
	} = useCurrentSaleStore((state) => state, shallow);

	const {
		aftermarket,
		trades,
		pickupPmts,
		miscSellerFees,
		miscDMVFees,
		warranty: warrantyList,
		vehicle,
		wholesaleInvs,
		downPayments,
		commissions,
		editCommissions,
		editCancellationAmt,
		editRestockingFee,
		county,
		calculateBool,
	} = useCurrentDealStore((state) => state, shallow);

	const {
		dmvTaxRate,
		servContractTaxRate,
		aftMktTaxRate,
		gapTaxRate,
		// taxRate,
		docFeeTaxRate,
		dealerSmogTaxRate,
		taxCreditRate,
		taxCreditFixAmount,
		maxTaxCredit,
		// localTaxRate,
		// businessTaxRate,
		// luxuryTaxRate,
		vitRate,
		businessTaxRate,
		fetTaxRate,
		bodyInjuryPremium,
		collisionPremium,
		compPremium,
		medicalPremium,
		otherPremium,
		propertyPremium,
	} = useCurrentDealStore(
		(state) => ({
			dmvTaxRate: state?.deal?.dealSettings?.taxDefaults?.dmvTaxRate,
			servContractTaxRate:
				state?.deal?.dealSettings?.taxDefaults?.servContractTaxRate,
			aftMktTaxRate: state?.deal?.dealSettings?.taxDefaults?.aftMktTaxRate,
			gapTaxRate: state?.deal?.dealSettings?.taxDefaults?.gapTaxRate,
			// taxRate: state?.deal?.dealSettings?.taxDefaults?.taxRate,
			docFeeTaxRate: state?.deal?.dealSettings?.taxDefaults?.docFeeTaxRate,
			dealerSmogTaxRate:
				state?.deal?.dealSettings?.taxDefaults?.dealerSmogTaxRate,
			taxCreditRate: state?.deal?.dealSettings?.taxDefaults?.taxCreditRate,
			taxCreditFixAmount:
				state?.deal?.dealSettings?.taxDefaults?.taxCreditFixAmount,
			maxTaxCredit: state?.deal?.dealSettings?.taxDefaults?.maxTaxCredit,

			vitRate: state?.deal?.dealSettings?.taxDefaults?.vitRate,
			businessTaxRate: state?.deal?.dealSettings?.taxDefaults?.businessTaxRate,
			fetTaxRate: state?.deal?.dealSettings?.taxDefaults?.fetTaxRate,

			bodyInjuryPremium: state.collisionInsurance?.bodyInjuryPremium,
			collisionPremium: state.collisionInsurance?.collisionPremium,
			compPremium: state.collisionInsurance?.compPremium,
			medicalPremium: state.collisionInsurance?.medicalPremium,
			otherPremium: state.collisionInsurance?.otherPremium,
			propertyPremium: state.collisionInsurance?.propertyPremium,
		}),
		shallow
	);

	const { cancellationAmt, cancellationAccepted } = useCurrentDealStore(
		(state) => state?.contractInfo,
		shallow
	);

	const { countyRecNum, type, isFetTaxable } = useCurrentDealStore(
		(state) => ({
			countyRecNum: state.deal.countyRecNum,
			type: state.deal.type,
			isFetTaxable: state?.vehicle?.isFetTaxable,
		}),
		shallow
	);

	const { dealID, editMiscDMVFees } = useCurrentDealStore(
		(state) => ({
			dealID: state.deal.ID,
			editMiscDMVFees: state.editMiscDMVFees,
		}),
		shallow
	);

	// profit settings
	const {
		isDocFeeProfit,
		isDealerSmogProfit,
		isReserveProfit,
		isServConProfit,
		isGapProfit,
		isAftMktProfit,
		isMiscProfit,
		// isBankFeeProfit,
	} = useCurrentDealStore(
		(state) => state?.deal?.dealSettings?.profitSettings,
		shallow
	);

	// Commission settings
	const {
		isDocFeeComm,
		isAftMktComm,
		isServConComm,
		isGapComm,
		isMiscComm,
		isBankFeeComm,
		isReserveComm,
		isSmogComm,
	} = useCurrentDealStore(
		(state) => state?.deal?.dealSettings?.commissionSettings,
		shallow
	);

	// used for commission calc
	const {
		//credLifeProfit,
		//disabProfit,
		collisionTotProfit,
		fiGross,
		totalGross,
		salesGross,
		collisionTotCost,
		totalLenderFee,
		editFiGross,
		editSalesGross,
		editTotalGross,
		editTotalLenderFee,
		editNetCheckToDealer,
		editCollisionTotProfit,
	} = useCurrentRecapStore((state) => state, shallow);

	const {
		vsiCost,
		miscCost,
		gapInsCost,
		warrantyCost,
		editCarProfit,
		editAftmktProfit,
		editTotalTradeAllowance,
		editTotalTradeACV,
		editTotalTradePayoff,
		editTotalTradeProfit,
		editTotalDown,
		editMiscProfit,
		editGapInsProfit,
		editWarrantyProfit,
		interestCost,
		dlrParticipation,
		additionalProfit,
		editInterestProfit,
		editInterestNetProfit,
		//editAdditionalProfit,
		carProfit,
		aftmktProfit,
		totalTradeProfit,
		miscProfit,
		gapInsProfit,
		warrantyProfit,
		frontendProfit,
		backendProfit,
		lenderFee,
		incentive,
		reserve,
		totalDown,
		editFrontendProfit,
		editBackendProfit,
		interestNetProfit,
		grossProfit,
		commGross,
		//credLifeProfit,
		//disabProfit,
		editGrossProfit,
		//recon,
		//official,
		//additional,
		gpBeforeFees,
		gpBeforeReserve,
		reservePercent,
		editOfficial,
		//editAdditional,
		editGpBeforeFees,
		editGpBeforeReserve,
		editGpBeforeComm,
		editReceivable,
		editReserve,
		//editReservePercent,
		editInterestCost,
		editDlrParticipation,
		editMiscCost,
		editWarrantyCost,
		editNetProfit,
		editSalesComm,
		editFiComm,
		editTotalComm,
		editProfitComm,
		editCommGross,
		editReconProfit,
		editRecon,
	} = useCurrentRecapStore((state) => state, shallow);

	// Can modify this once we are tracking in house deals
	// For now this provides the necessary logic
	const isInHouse = () => {
		return false;
	};

	// const twoDecimal = (value) => {
	// 	return parseFloat(parseFloat(value).toFixed(2));
	// };

	let vehicleCost = vehicle?.totalCost || 0;
	let vehicleTotalOtherCost = vehicle?.totalOtherCost || 0;
	let vehicleTotalRecon = vehicle?.totalRecon || 0;
	let vehicleYear = vehicle?.year || "";
	let vehicleType = vehicle?.type || "";
	//let holdback = vehicle?.holdBack || 0;
	let pack = vehicle?.pack || 0;
	let flooring = vehicle?.flooring || 0;
	if (wholesaleInvs.length > 0) {
		vehicleCost = wholesaleInvs.reduce(
			(acc, inv) => acc + inv.car.totalCost,
			0
		);
		vehicleTotalOtherCost = wholesaleInvs.reduce(
			(acc, inv) => acc + inv.car.totalOtherCost,
			0
		);
		vehicleTotalRecon = wholesaleInvs.reduce(
			(acc, inv) => acc + inv.car.totalRecon,
			0
		);
		//holdback = wholesaleInvs.reduce((acc, inv) => acc + inv.car.holdback, 0);
		pack = wholesaleInvs.reduce((acc, inv) => acc + inv.car.pack, 0);
		flooring = wholesaleInvs.reduce((acc, inv) => acc + inv.car.flooring, 0);
		console.log(vehicleCost);
		console.log(vehicleTotalOtherCost);
		console.log(vehicleTotalRecon);
		//console.log(holdback);
		console.log(pack);
		console.log(flooring);
	}
	// try memoCalculateSales with all of the function calls and then a single useEffect for them all
	const memoCalculateSales = useCallback(() => {
		// Calculate  Total Seller Fees
		const calcMiscSellerFees = () => {
			let nonTaxSum = 0.0;
			let sum =
				parseFloat(docFee ? docFee : 0) +
				parseFloat(dealerSmog ? dealerSmog : 0) +
				parseFloat(stateSmog ? stateSmog : 0);
			let sumCost = 0;
			miscSellerFees.forEach((key) => {
				if (!key.isTaxable) {
					nonTaxSum += key.amount;
				}
				sum += key.amount;
				sumCost += key.cost;
			});
			console.log(miscSellerFees);
			// parseFloat(nonTaxSum).toFixed(2);
			// parseFloat(sum).toFixed(2);
			// parseFloat(sumCost).toFixed(2);
			nonTaxSum = roundUp(nonTaxSum);
			sum = roundUp(sum);
			sumCost = roundUp(sumCost);
			editMiscCost(sumCost);
			editTotalSellerFees(sum);
			// TODO what is this used for?
			editTotalNonTaxableSellerFees(nonTaxSum);
			return {
				miscCost_: sumCost,
				totalSellerFees_: sum,
				totalNonTaxableSellerFees_: nonTaxSum,
			};
		};
		const { miscCost_, totalSellerFees_, totalNonTaxableSellerFees_ } =
			calcMiscSellerFees();
		console.log(totalNonTaxableSellerFees_);
		// Calculate  Total Service Contract (Warranty)
		const calcServConTotal = () => {
			let sum = 0;
			let sumCost = 0;
			warrantyList.forEach((key) => {
				sum += key.amount;
				sumCost += key.cost;
			});
			console.log(warrantyList);
			// parseFloat(sum).toFixed(2);
			// parseFloat(sumCost).toFixed(2);
			sum = roundUp(sum);
			sumCost = roundUp(sumCost);
			editWarranty(sum);
			editWarrantyCost(sumCost);
			return { warranty_: sum, warrantyCost_: sumCost };
		};
		const { warranty_, warrantyCost_ } = calcServConTotal();

		// Calculate  Total DMV Fees
		let license_ = calcLicenseFee(licenseFee, licensePct, price);
		editLicense(license_);

		let registration_ = calcRegistrationFee(
			registrationFee,
			registrationPct,
			price
		);
		editRegistration(registration_);

		const calcPropAssessmentUT = () => {
			return miscDMVFees;
		};

		const miscDMVFees_ = calcPropAssessmentUT();

		let totalDMVFees_ = calcDMVFees(
			license_,
			registration_,
			titleFee,
			filingFee,
			smogExemption,
			weightFee,
			tireFee,
			bpa,
			miscDMVFees_
		);
		editTotalDMVFees(totalDMVFees_);

		// Calculate  Total AfterMarket
		const calcAftermarket = () => {
			let sum = 0;
			let nonTaxSum = 0;
			let sumCost = 0;
			aftermarket.forEach((key) => {
				if (key.isTaxable) {
					sum += key.price * key.qty;
				} else {
					nonTaxSum += key.price * key.qty;
					sum += key.price * key.qty;
				}
				sumCost += key.cost * key.qty;
			});

			editAftmktCost(sumCost);
			editAftmktNonTaxable(nonTaxSum);
			editAftmkt(sum);

			return {
				aftmktCost_: sumCost,
				aftmktNonTaxable_: nonTaxSum,
				aftmkt_: sum,
			};
		};
		const { aftmktCost_, aftmktNonTaxable_, aftmkt_ } = calcAftermarket();

		const calcTotPremium = () => {
			const sum =
				bodyInjuryPremium +
				collisionPremium +
				compPremium +
				medicalPremium +
				otherPremium +
				propertyPremium;
			editCollisionTotPremium(sum);
			return { collisionTotPremium_: sum };
		};
		const { collisionTotPremium_ } = calcTotPremium();

		// Calculate Net Trade and Profit
		// double accounting for values on recap and sale
		const calcNetTradeAndProfit = () => {
			// total allowance
			let totalTrade = 0;
			let totalAcv = 0;
			let totalPayoff = 0;
			if (trades.length > 0) {
				trades.forEach((element) => {
					totalTrade += element.allowance;
					totalPayoff += element.payoff;
					totalAcv += element.acv;
				});
			}
			editTrade(totalTrade);
			editSalePayoff(totalPayoff);
			const totalNet = totalTrade - totalPayoff;
			editTotalNetTrade(totalNet);
			editTotalTradeACV(totalAcv);
			editTotalTradeAllowance(totalTrade);
			editTotalTradePayoff(totalPayoff);
			const profit = totalAcv - totalTrade;
			editTotalTradeProfit(profit);
			return {
				trade_: totalTrade,
				payoff_: totalPayoff,
				totalNetTrade_: totalNet,
				totalTradeACV_: totalAcv,
				totalTradeProfit_: profit,
			};
		};

		const {
			trade_,
			// payoff_,
			totalNetTrade_,
			// totalTradeACV_,
			totalTradeProfit_,
		} = calcNetTradeAndProfit();

		// Calculate Total Pickup Payments
		const calcTotalPickupPmts = () => {
			let sum = 0;
			pickupPmts.forEach((key) => {
				sum += parseFloat(key.amount);
				console.log(sum);
			});
			const total = parseFloat(sum);
			editTotalPickupPmts(total);
			return { totalPickupPmts_: total };
		};
		const { totalPickupPmts_ } = calcTotalPickupPmts();

		//calculate total down payments
		const calcDownPayments = () => {
			let down_ = down;
			let sum = 0;
			if (downPayments.length > 0) {
				downPayments.forEach((key) => {
					if (key.status !== "1" && key.status !== "void") {
						sum += parseFloat(key.amtPaid);
					}
				});
				// parseFloat(sum).toFixed(2);
				sum = roundUp(sum);
				editDown(sum);
				down_ = sum;
			}
			return { down_ };
		};
		const { down_ } = calcDownPayments();

		// Calculate TAX and SalesTAX
		const calculateTaxes = () => {
			/**
			 * Total taxable amount that uses the default taxRate.
			 * This value can be either the taxRate of dealership zip or customer zip.
			 * "-1" signifies the item is taxed with the default tax rate in which case it is added to taxable ammount
			 */

			let monthlyTax_ = 0;
			let deferredTax_ = 0;
			let taxableAmount_ = 0;

			taxableAmount_ =
				parseFloat(price) +
				(dmvTaxRate === -1 ? parseFloat(totalDMVFees_) : 0) +
				(servContractTaxRate === -1 ? parseFloat(warranty_) : 0) +
				(docFeeTaxRate === -1 ? parseFloat(docFee) : 0) +
				(dealerSmogTaxRate === -1 ? parseFloat(dealerSmog) : 0) +
				//  (gapTaxRate === -1 ? parseFloat(collisionTotPremium) : 0) +
				(gapTaxRate === -1 ? parseFloat(gap) : 0) +
				(aftMktTaxRate === -1
					? parseFloat(aftmkt_) - parseFloat(aftmktNonTaxable_)
					: 0);

			miscSellerFees.forEach((element) => {
				taxableAmount_ += element.isTaxable ? parseFloat(element.amount) : 0;
				console.log(element.isTaxable + "Misc = " + parseFloat(element.amount));
			});

			miscDMVFees_.forEach((element) => {
				taxableAmount_ += element.isTaxable ? parseFloat(element.amount) : 0;
			});

			let tradeTaxCredit_ = 0.0;

			tradeTaxCredit_ =
				parseFloat(trade_) * parseFloat(taxCreditRate) +
				parseFloat(taxCreditFixAmount);

			if (tradeTaxCredit_ > maxTaxCredit && maxTaxCredit > 0)
				tradeTaxCredit_ = maxTaxCredit;

			editTradeTaxCredit(tradeTaxCredit_);
			taxableAmount_ = parseFloat(taxableAmount_) - parseFloat(tradeTaxCredit_);
			taxableAmount_ = taxableAmount_ < 0 ? 0 : taxableAmount_;
			editTaxableAmount(taxableAmount_);
			/**
			 * Total amount of money that is taxable.
			 * This is has no regard for associated tax rates and is not used for
			 * any calculations, simply informative
			 **/

			let totalTaxable_ = 0.0;
			totalTaxable_ =
				parseFloat(taxableAmount_) +
				(dmvTaxRate > 0 ? parseFloat(totalDMVFees_) : 0) +
				(servContractTaxRate > 0 ? parseFloat(warranty_) : 0) +
				(docFeeTaxRate > 0 ? parseFloat(docFee) : 0) +
				(dealerSmogTaxRate > 0 ? parseFloat(dealerSmog) : 0) +
				(aftMktTaxRate > 0
					? parseFloat(aftmkt_) - parseFloat(aftmktNonTaxable_)
					: 0) +
				//(gapTaxRate > 0 ? parseFloat(collisionTotPremium) : 0) +
				(gapTaxRate > 0 ? parseFloat(gap) : 0);

			editTotalTaxable(totalTaxable_);
			let salesTax_ = parseFloat(taxableAmount_) * parseFloat(taxRate);
			const calcUserTax = (taxRate, border, rateBelow) => {
				let bord = parseFloat(taxableAmount_) - parseFloat(border);
				let tmpUserTax = (bord < 0, 0, parseFloat(bord)) * parseFloat(taxRate);
				tmpUserTax +=
					(bord < 0, parseFloat(taxableAmount_), parseFloat(border)) *
					parseFloat(rateBelow);
				return tmpUserTax;
			};

			let userTax1_ = 0.0;
			let userTax2_ = 0.0;
			let userTax3_ = 0.0;
			let userTax4_ = 0.0;
			let userTax5_ = 0.0;
			let userTax6_ = 0.0;

			if (countyRecNum > 0 && county !== null) {
				if (county.isTaxSplit) {
					salesTax_ = 0;

					userTax1_ = calcUserTax(
						county.userTaxRate1,
						county.userBorder1,
						county.userRateBelow1,
						county.userRateBelow1
					);
					editUserTax1(userTax1_);

					userTax2_ = calcUserTax(
						county.userTaxRate2,
						county.userBorder2,
						county.userRateBelow2,
						county.userRateBelow2
					);
					editUserTax2(userTax2_);

					userTax3_ = calcUserTax(
						county.userTaxRate3,
						county.userBorder3,
						county.userRateBelow3,
						county.userRateBelow3
					);
					editUserTax3(userTax3_);

					userTax4_ = calcUserTax(
						county.userTaxRate4,
						county.userBorder4,
						county.userRateBelow4,
						county.userRateBelow4
					);
					editUserTax4(userTax4_);

					userTax5_ = calcUserTax(
						county.userTaxRate5,
						county.userBorder5,
						county.userRateBelow5,
						county.userRateBelow5
					);
					editUserTax5(userTax5_);

					userTax6_ = calcUserTax(
						county.userTaxRate6,
						county.userBorder6,
						county.userRateBelow6,
						county.userRateBelow6
					);
					editUserTax6(userTax6_);
					salesTax_ =
						userTax1_ +
						userTax2_ +
						userTax3_ +
						userTax4_ +
						userTax5_ +
						userTax6_;
				}
			}
			const tmpOtherSalesTax =
				(dmvTaxRate > 0 ? dmvTaxRate * parseFloat(totalDMVFees_) : 0) +
				(servContractTaxRate > 0
					? servContractTaxRate * parseFloat(warranty_)
					: 0) +
				(docFeeTaxRate > 0 ? docFeeTaxRate * parseFloat(docFee) : 0) +
				(dealerSmogTaxRate > 0
					? dealerSmogTaxRate * parseFloat(dealerSmog)
					: 0) +
				(gapTaxRate > 0 ? gapTaxRate * parseFloat(gap) : 0) +
				//(gapTaxRate > 0 ? gapTaxRate * parseFloat(collisionTotPremium) : 0) +
				(aftMktTaxRate > 0
					? aftMktTaxRate *
					  (parseFloat(aftmkt_) - parseFloat(aftmktNonTaxable_))
					: 0);
			salesTax_ = salesTax_ + tmpOtherSalesTax + fixedAmountTax;
			if (county != null) {
				const taxMin = county.minimumTax ? county.minimumTax : salesTax_;
				const taxMax = county.maximumTax ? county.maximumTax : salesTax_;
				salesTax_ = salesTax_ < taxMin ? county.minimumTax : salesTax_;
				salesTax_ = salesTax_ > taxMax ? county.maximumTax : salesTax_;
			}
			const warantyTax_ =
				servContractTaxRate > 0
					? servContractTaxRate * parseFloat(warranty_)
					: 0;
			editWarrantyTax(warantyTax_);
			//editSalesTax(parseFloat(salesTax_.toFixed(2)));
			editSalesTax(roundUp(salesTax_));

			if (taxType === 1) {
				editMonthlyTax(parseFloat(convertToDollar(salesTax_ / term)));
				editDeferredTax(salesTax_);
				monthlyTax_ = parseFloat(convertToDollar(salesTax_ / term));
				deferredTax_ = salesTax_;
			} else {
				editMonthlyTax(0);
				editDeferredTax(0);
				monthlyTax_ = 0;
				deferredTax_ = 0;
			}

			// TODO
			// define what items can have a specified tax rate, need to determine a general form.
			// Should we only allow the dealer to use default tax rate or non taxable?
			// should this be only be controlled in settings?
			const vit_ = price * vitRate;
			if (isNaN(vit_)) {
				editVit(0);
				editVitRate(0);
			} else {
				//editVit(parseFloat(vit_.toFixed(2)));
				editVit(roundUp(vit_));
				editVitRate(vitRate);
			}
			const businessTax_ = taxableAmount_ * businessTaxRate;
			if (isNaN(businessTax_)) {
				editBusinessTax(0);
				editBusinessTaxRate(0);
			} else {
				//editBusinessTax(parseFloat(businessTax_.toFixed(2)));
				editBusinessTax(roundUp(businessTax_));
				editBusinessTaxRate(businessTaxRate);
			}
			console.log(isFetTaxable);
			const fetTax_ = price * (isFetTaxable === true ? fetTaxRate : 0);
			if (isNaN(fetTax_) || fetTax_ === 0) {
				editFetTax(0);
				editFetTaxRate(0);
			} else {
				//editFetTax(parseFloat(fetTax_.toFixed(2)));
				editFetTax(roundUp(fetTax_));
				editFetTaxRate(fetTaxRate);
			}
			if (isNaN(taxType)) {
				editTaxType(0);
			}

			// const tax_ =
			// 	parseFloat(salesTax_.toFixed(2)) +
			// 	parseFloat(vit_.toFixed(2)) +
			// 	parseFloat(businessTax_.toFixed(2)) +
			// 	parseFloat(fetTax_.toFixed(2));
			const tax_ =
				roundUp(salesTax_) +
				roundUp(vit_) +
				roundUp(businessTax_) +
				roundUp(fetTax_);
			//Tax is NaN, set it to 0
			if (isNaN(tax_)) {
				editTax(0);
			} else editTax(tax_);

			return {
				monthlyTax_,
				deferredTax_,
				tradeTaxCredit_,
				taxableAmount_,
				totalTaxable_,
				userTax1_,
				userTax2_,
				userTax3_,
				userTax4_,
				userTax5_,
				userTax6_,
				salesTax_,
				tax_,
			};
		};
		const {
			monthlyTax_,
			deferredTax_,
			// tradeTaxCredit_,
			// taxableAmount_,
			// totalTaxable_,
			// userTax1_,
			// userTax2_,
			// userTax3_,
			// userTax4_,
			// userTax5_,
			// userTax6_,
			// salesTax_,
			tax_,
		} = calculateTaxes();

		/**
		 * Calculates cancellation amount and restocking fee based on the CA CCOA
		 */
		const calcCancellationAmt = () => {
			// determine cancellation amount
			let cancellationAmt_;
			let restockingFee_;
			if (price <= 5000) {
				cancellationAmt_ = 75;
			} else if (price <= 10000) {
				cancellationAmt_ = 150;
			} else if (price <= 30000) {
				cancellationAmt_ = 250;
			} else {
				cancellationAmt_ = price * 0.01;
			}

			// determine restocking fee
			if (price <= 5000) {
				restockingFee_ = 175;
			} else if (price <= 10000) {
				restockingFee_ = 350;
			} else {
				restockingFee_ = 500;
			}
			editCancellationAmt(cancellationAmt_);
			editRestockingFee(restockingFee_);
			return {
				cancellationAmt_,
				restockingFee_,
			};
		};
		const {
			cancellationAmt_,
			// restockingFee_
		} = calcCancellationAmt();

		//Calculate Cash price, this does not nclude any kind of money down, pick/deferred payments, or deferred taxes
		const calcCashPrice = () => {
			let total =
				parseFloat(price) +
				parseFloat(totalDMVFees_) +
				parseFloat(totalSellerFees_) +
				parseFloat(gap) +
				parseFloat(collisionTotPremium_) +
				parseFloat(lifePremium) +
				parseFloat(disabilityPremium) +
				parseFloat(warranty_) +
				parseFloat(aftmkt_) +
				parseFloat(tax_) +
				(cancellationAccepted === 1 ? parseFloat(cancellationAmt_) : 0); // +
			//parseFloat(bankFee) +

			//parseFloat(total).toFixed(2);
			total = roundUp(total);
			editOnePay(total);
			return { onePay_: total };
		};
		// const { onePay_ } =
		calcCashPrice();

		// Calculate First DueDate
		const firstDueDate_ = calculateFirstDueDate(daysToPay, loanDate);
		editFirstDueDate(firstDueDate_);
		// Calculate Final DueDate
		const finalDueDate_ = calculateFinalDueDate(
			loanPeriod,
			term,
			firstDueDate_
		);
		editFinalDueDate(finalDueDate_);

		// Calculate Amount Finance
		const amtFinanced_ = calculateAmountFinanced(
			price,
			totalDMVFees_,
			totalSellerFees_,
			gap,
			warranty_,
			aftmkt_,
			down_, // not
			totalPickupPmts_, // not
			totalNetTrade_, // not but should be
			taxType,
			tax_,
			deferredTax_,
			manufacturerRebate, // not but should be
			cancellationAmt_,
			cancellationAccepted,
			collisionTotPremium_,
			lifePremium,
			disabilityPremium
		);

		editAmtFinanced(amtFinanced_);

		const calcReserve = () => {
			let reserve_ = reserve;
			if (reserveFlag) {
				reserve_ = amtFinanced_ * reservePercent;
				editReserve(reserve_);
			}
			return reserve_;
		};

		const reserve_ = calcReserve();

		// Calculate Payment
		let payment_ = calculatePayment(
			apr,
			loanPeriod,
			term,
			amtFinanced_,
			loanDate,
			firstDueDate_,
			bankFee
		);

		// Calculate Final Payment
		let finalPmt_ = calculateFinalPayment(apr, payment_, term, amtFinanced_);

		const calculatePayments = () => {
			editPayment(payment_);
			editFinalPmt(finalPmt_);
		};
		console.log("Roll Back: " + rollBack);
		if (rollBack === 90) {
			console.log("rolling by term...");
			console.log("Term: " + term);
			console.log("Loan Period: " + loanPeriod);
			console.log("APR: " + apr);
			console.log("Amt Financed: " + amtFinanced_);
			console.log("Payment: " + payment_);
			console.log("DesiredPmt: " + desiredPmt);
			console.log("Final Pmt: " + finalPmt_);

			const tempTerm = rollByTerm(desiredPmt, amtFinanced_, apr, loanPeriod);
			if (isNaN(tempTerm) || tempTerm <= 0) {
				console.error("Desired payment is too low to amortize the loan.");
				// Handle the error, e.g., prompt user to enter a higher payment
				return;
			}

			const integerTerm = Math.floor(tempTerm);
			const fractionalTerm = tempTerm - integerTerm;
			editTerm(integerTerm + (fractionalTerm > 0 ? 1 : 0));
			editPayment(desiredPmt);
			payment_ = desiredPmt;
			let finalPmt = 0;
			if (apr === 0) {
				finalPmt = amtFinanced_ - desiredPmt * integerTerm;
			} else {
				// Calculate the interest rate per period
				const periodsPerYear = LoanPeriod[loanPeriod].term; // e.g., 12 for monthly
				const interestPerPeriod = apr / periodsPerYear;
				// Calculate the remaining balance after integer number of payments
				const remainingBalance =
					amtFinanced_ * Math.pow(1 + interestPerPeriod, integerTerm) -
					desiredPmt *
						((Math.pow(1 + interestPerPeriod, integerTerm) - 1) /
							interestPerPeriod);
				// If there is a fractional term, calculate the final payment
				if (fractionalTerm > 0) {
					// Accrue interest for the fractional period
					const accruedInterest =
						remainingBalance * interestPerPeriod * fractionalTerm;
					finalPmt = remainingBalance + accruedInterest;
				}
			}
			editFinalPmt(finalPmt);
			finalPmt_ = finalPmt;
		} else {
			calculatePayments();
		}

		// Calculate FinanceCharge
		let financeCharge_ = calculateFinanceCharge(
			amtFinanced_,
			payment_,
			finalPmt_,
			term
		);
		editFinanceCharge(financeCharge_);
		// TODO check this
		const addOn_ = FindApr(
			apr,
			loanPeriod,
			term,
			amtFinanced_,
			loanDate,
			firstDueDate_,
			payment_,
			0
		);
		editAddOn(addOn_);

		// const result = calculateOCCCexample2();
		// console.log(result);

		const calculateBalloonValues = () => {
			console.log(hasBalloon);
			if (hasBalloon) {
				let balloonTerm_ = balloonTerm;
				if (balloonTerm < 0 || balloonTerm > term - 1) {
					balloonTerm_ = term - 1;
					editBalloonTerm(term - 1);
				}
				const balloon_ = calculateBalloon(
					amtFinanced_,
					balloonTerm_,
					apr,
					payment_,
					loanPeriod
				);
				editBalloon(balloon_);
				const balloonDate_ = calculateFinalDueDate(
					loanPeriod,
					balloonTerm_,
					firstDueDate_
				);
				editBalloonDate(balloonDate_);

				if (balloon_ > 0 && balloonTerm_ > 0) {
					const balloonFinanceCharge_ =
						balloon_ + payment_ * balloonTerm_ - amtFinanced_;
					editBalloonFinanceCharge(balloonFinanceCharge_);
					editFinanceCharge(balloonFinanceCharge_);
					financeCharge_ = balloonFinanceCharge_;
				}
			}
		};
		calculateBalloonValues();
		editRegularPmt(payment_);

		if (taxType === 1) {
			editPayment(payment_ + monthlyTax_);

			let defTax =
				deferredTax_ > 0
					? parseFloat(convertToDollar(deferredTax_ - monthlyTax_ * (term - 1)))
					: 0;
			editFinalPmt(finalPmt_ + defTax);
		} else {
			editPayment(payment_);
			editFinalPmt(finalPmt_);
		}
		const loan_ = calculateLoan(amtFinanced_, financeCharge_, deferredTax_);
		editLoan(loan_);

		// recap

		const calcVehicleProfit = () => {
			const total = price - vehicleCost;
			editCarProfit(total);
			return total;
		};
		const carProfit_ = calcVehicleProfit();

		const calcReconProfit = () => {
			const total = vehicleTotalOtherCost + vehicleTotalRecon;
			editRecon(total);
			editReconProfit(total * -1);
			return { recon_: total, reconProfit_: total * -1 };
		};
		// const { recon_, reconProfit_ } =
		calcReconProfit();

		const calcDownProfit = () => {
			const totalDown_ =
				totalNetTrade_ + down_ + totalPickupPmts_ + manufacturerRebate;
			editTotalDown(totalDown_);
			return totalDown_;
		};
		const totalDown_ = calcDownProfit();
		////is not profitable
		const calcOfficialFeesProfit = () => {
			const official_ = tax_ + totalDMVFees;
			editOfficial(official_);
			return official_;
		};
		// const official_ =
		calcOfficialFeesProfit();

		const calcAftermarketProfit = () => {
			let aftmktProfit_ = aftmkt_ - aftmktCost_;
			if (isAftMktProfit === profitSettingsEnum.NOT_PROFIT.value) {
				aftmktProfit_ = 0;
			}
			editAftmktProfit(aftmktProfit_);
			return aftmktProfit_;
		};
		const aftmktProfit_ = calcAftermarketProfit();

		const calcMiscProfit = () => {
			let miscProfit_ =
				totalSellerFees_ - miscCost_ - docFee - dealerSmog - stateSmog;

			if (isMiscProfit === profitSettingsEnum.NOT_PROFIT.value) {
				miscProfit_ = 0;
			}
			if (isDocFeeProfit !== profitSettingsEnum.NOT_PROFIT.value) {
				miscProfit_ += docFee;
			}
			if (isDealerSmogProfit !== profitSettingsEnum.NOT_PROFIT.value) {
				miscProfit_ += dealerSmog;
			}
			editMiscProfit(miscProfit_);
			return miscProfit_;
		};
		const miscProfit_ = calcMiscProfit();

		const calcGapProfit = () => {
			let gapInsProfit_ = gap - gapInsCost;
			if (isGapProfit === profitSettingsEnum.NOT_PROFIT.value) {
				gapInsProfit_ = 0;
			}
			editGapInsProfit(gapInsProfit_);
			return gapInsProfit_;
		};
		const gapInsProfit_ = calcGapProfit();

		// where is collisionTotCost being calculated
		const calcCollisionTotProfit = () => {
			let collisionTotProfit_ = collisionTotPremium_ - collisionTotCost;
			if (isGapProfit === profitSettingsEnum.NOT_PROFIT.value) {
				collisionTotProfit_ = 0;
			}
			editCollisionTotProfit(collisionTotProfit_);
			return collisionTotProfit_;
		};
		const collisionTotProfit_ = calcCollisionTotProfit();

		const calcWarrantyProfit = () => {
			let warrantyProfit_ = warranty_ - warrantyCost_;
			if (isServConProfit === profitSettingsEnum.NOT_PROFIT.value) {
				warrantyProfit_ = 0;
			}
			editWarrantyProfit(warrantyProfit_);
			return warrantyProfit_;
		};
		const warrantyProfit_ = calcWarrantyProfit();

		// go over this with peyman
		const calcGrossFinanProfit = () => {
			let interestNetProfit_ = 0;
			if (isInHouse()) {
				editInterestProfit(financeCharge_);
				editInterestCost(0);
				editDlrParticipation(1);
			} else {
				// Not cash deal
				if (loanPeriod !== 5) {
					const pmt = calculatePayment(
						interestCost,
						loanPeriod,
						term,
						amtFinanced_,
						loanDate,
						firstDueDate_,
						bankFee
					);
					const finalPmt = calculateFinalPayment(
						apr,
						pmt,
						term,
						amtFinanced_,
						deferredTax_,
						monthlyTax
					);
					const lenderFinanceCharge = calculateFinanceCharge(
						amtFinanced_,
						pmt,
						finalPmt,
						term,
						deferredTax_
					);
					const val =
						parseFloat(financeCharge_) - parseFloat(lenderFinanceCharge);
					//					const interestProfit_ = twoDecimal(val);
					const interestProfit_ = roundUp(val);
					editInterestProfit(interestProfit_);

					const participation =
						parseFloat(interestProfit_) * parseFloat(dlrParticipation);
					// interestNetProfit_ = twoDecimal(
					// 	participation + parseFloat(additionalProfit)
					// );
					interestNetProfit_ = roundUp(
						participation + parseFloat(additionalProfit)
					);
					editInterestNetProfit(interestNetProfit_);
				} else {
					// cash deal
					editInterestProfit(0);
				}
			}
			return interestNetProfit_;
		};
		const interestNetProfit_ = calcGrossFinanProfit();

		const calcFrontend = () => {
			let frontendProfit_ = carProfit_ + totalTradeProfit_;

			if (isDocFeeProfit === profitSettingsEnum.FRONT.value) {
				frontendProfit_ += parseFloat(docFee);
			}

			if (isDealerSmogProfit === profitSettingsEnum.FRONT.value) {
				frontendProfit_ += parseFloat(dealerSmog);
			}

			if (isMiscProfit === profitSettingsEnum.FRONT.value) {
				frontendProfit_ += parseFloat(
					totalSellerFees_ - miscCost_ - docFee - dealerSmog - stateSmog
				);
			}

			if (isReserveProfit === profitSettingsEnum.FRONT.value) {
				frontendProfit_ += parseFloat(reserve_);
			}

			if (isServConProfit === profitSettingsEnum.FRONT.value) {
				frontendProfit_ += parseFloat(warrantyProfit_);
			}

			if (isGapProfit === profitSettingsEnum.FRONT.value) {
				frontendProfit_ += parseFloat(gapInsProfit_);
			}

			if (isAftMktProfit === profitSettingsEnum.FRONT.value) {
				frontendProfit_ += parseFloat(aftmktProfit_);
			}

			// bank fee should never be profit, removing from calculations
			// if (isBankFeeProfit === profitSettingsEnum.FRONT.value) {
			//  frontendProfit_ += parseFloat(lenderFee);
			// }

			//frontendProfit_ = twoDecimal(frontendProfit_);
			frontendProfit_ = roundUp(frontendProfit_);
			editFrontendProfit(frontendProfit_);
			return frontendProfit_;
		};
		const frontendProfit_ = calcFrontend();

		const calcBackend = () => {
			let backendProfit_ = interestNetProfit_;

			if (cancellationAccepted) {
				backendProfit_ += cancellationAmt_;
			}

			if (isDocFeeProfit === profitSettingsEnum.BACK.value) {
				backendProfit_ += parseFloat(docFee);
			}
			if (isDealerSmogProfit === profitSettingsEnum.BACK.value) {
				backendProfit_ += parseFloat(dealerSmog);
			}
			if (isMiscProfit === profitSettingsEnum.BACK.value) {
				backendProfit_ += parseFloat(
					totalSellerFees_ - miscCost_ - docFee - dealerSmog - stateSmog
				);
			}

			if (isReserveProfit === profitSettingsEnum.BACK.value) {
				backendProfit_ += parseFloat(reserve_);
			}
			if (isServConProfit === profitSettingsEnum.BACK.value) {
				backendProfit_ += parseFloat(warrantyProfit_);
			}
			if (isGapProfit === profitSettingsEnum.BACK.value) {
				backendProfit_ += parseFloat(gapInsProfit_);
				backendProfit_ += parseFloat(collisionTotProfit_);
			}
			if (isAftMktProfit === profitSettingsEnum.BACK.value) {
				backendProfit_ += parseFloat(aftmktProfit_);
			}
			// bank fee should never be profit
			// if (isBankFeeProfit === profitSettingsEnum.BACK.value) {
			//  backendProfit_ += parseFloat(lenderFee);
			// }

			console.log({
				interestNetProfit_,
				cancellationAmt_,
				docFee,
				dealerSmog,
				totalSellerFees_,
				miscCost_,
				docFee,
				dealerSmog,
				stateSmog,
				reserve_,
				warrantyProfit_,
				gapInsProfit_,
				collisionTotProfit_,
				aftmktProfit_,
			});

			//backendProfit_ = twoDecimal(backendProfit_);
			backendProfit_ = roundUp(backendProfit_);
			editBackendProfit(backendProfit_);
			return backendProfit_;
		};
		const backendProfit_ = calcBackend();

		const calcGPBeforeFees = () => {
			let gpBeforeFees_ =
				parseFloat(frontendProfit_) + parseFloat(backendProfit_);
			//gpBeforeFees_ = twoDecimal(gpBeforeFees_);
			gpBeforeFees_ = roundUp(gpBeforeFees_);
			editGpBeforeFees(gpBeforeFees_);
			return gpBeforeFees_;
		};
		const gpBeforeFees_ = calcGPBeforeFees();
		const calcLenderFeeInfo = () => {
			const fiDiscount_ = amtFinanced_ * discount;
			const totalLenderFee_ = lenderFee + fiDiscount_;
			const netCheckToDealer_ =
				amtFinanced_ - totalLenderFee_ + interestNetProfit_;

			editFiDiscount(fiDiscount_);
			editTotalLenderFee(totalLenderFee_);
			editNetCheckToDealer(netCheckToDealer_);

			return { fiDiscount_, totalLenderFee_, netCheckToDealer_ };
		};
		const {
			fiDiscount_,
			totalLenderFee_,
			// netCheckToDealer_,
		} = calcLenderFeeInfo();

		const calcGPBeforeReserve = () => {
			let gpBeforeReserve_ =
				parseFloat(gpBeforeFees_) -
				parseFloat(totalLenderFee_) +
				parseFloat(incentive);
			//+				parseFloat(holdback);
			//gpBeforeReserve_ = twoDecimal(gpBeforeReserve_);
			gpBeforeReserve_ = roundUp(gpBeforeReserve_);
			editGpBeforeReserve(gpBeforeReserve_);
			return gpBeforeReserve_;
		};
		const gpBeforeReserve_ = calcGPBeforeReserve();

		const calcGPBeforeComm = () => {
			let grossProfit_ = parseFloat(gpBeforeReserve_) - parseFloat(reserve_);
			//grossProfit_ = twoDecimal(grossProfit_);
			grossProfit_ = roundUp(grossProfit_);
			// why are these the same, we dont need two values
			editGpBeforeComm(grossProfit_);
			editGrossProfit(grossProfit_);
			return grossProfit_;
		};
		const grossProfit_ = calcGPBeforeComm();

		const calcReceivable = () => {
			let receivable_ =
				parseFloat(amtFinanced_) -
				(parseFloat(lenderFee) + parseFloat(fiDiscount_)) +
				parseFloat(interestNetProfit_) +
				parseFloat(totalDown_);
			//receivable_ = twoDecimal(receivable_);
			receivable_ = roundUp(receivable_);
			editReceivable(receivable_);
		};
		calcReceivable();

		// commissions

		const calcSalesGross = () => {
			let salesGross_ = 0;
			salesGross_ += carProfit_;
			salesGross_ += totalTradeProfit_;
			if (
				isDocFeeComm === commissionSettingsEnum.FRONT_COMMISSION.value ||
				isDocFeeComm === commissionSettingsEnum.BOTH_COMMISION.value
			) {
				salesGross_ += parseFloat(docFee);
			}

			if (
				isSmogComm === commissionSettingsEnum.FRONT_COMMISSION.value ||
				isSmogComm === commissionSettingsEnum.BOTH_COMMISION.value
			) {
				salesGross_ += parseFloat(dealerSmog);
			}

			if (
				isReserveComm === commissionSettingsEnum.FRONT_COMMISSION.value ||
				isReserveComm === commissionSettingsEnum.BOTH_COMMISION.value
			) {
				salesGross_ += parseFloat(reserve_);
			}

			if (
				isServConComm === commissionSettingsEnum.FRONT_COMMISSION.value ||
				isServConComm === commissionSettingsEnum.BOTH_COMMISION.value
			) {
				salesGross_ += parseFloat(warrantyProfit_);
			}

			if (
				isGapComm === commissionSettingsEnum.FRONT_COMMISSION.value ||
				isGapComm === commissionSettingsEnum.BOTH_COMMISION.value
			) {
				salesGross_ += parseFloat(gapInsProfit_);
				salesGross_ += parseFloat(collisionTotProfit_);
			}

			if (
				isAftMktComm === commissionSettingsEnum.FRONT_COMMISSION.value ||
				isAftMktComm === commissionSettingsEnum.BOTH_COMMISION.value
			) {
				salesGross_ += parseFloat(aftmktProfit_);
			}

			if (
				isMiscComm === commissionSettingsEnum.FRONT_COMMISSION.value ||
				isMiscComm === commissionSettingsEnum.BOTH_COMMISION.value
			) {
				let miscProfit__ =
					totalSellerFees_ - miscCost_ - docFee - dealerSmog - stateSmog;

				salesGross_ += parseFloat(miscProfit__);
			}

			if (
				isBankFeeComm === commissionSettingsEnum.FRONT_COMMISSION.value ||
				isBankFeeComm === commissionSettingsEnum.BOTH_COMMISION.value
			) {
				salesGross_ += parseFloat(lenderFee);
			}
			//salesGross_ = twoDecimal(salesGross_);
			salesGross_ = roundUp(salesGross_);
			editSalesGross(salesGross_);
			return salesGross_;
		};

		const calcFIGross = () => {
			let fiGross_ = 0;
			fiGross_ += interestNetProfit_;

			if (
				isDocFeeComm === commissionSettingsEnum.BACK_COMMISSION.value ||
				isDocFeeComm === commissionSettingsEnum.BOTH_COMMISION.value
			) {
				fiGross_ += parseFloat(docFee);
			}

			if (
				isSmogComm === commissionSettingsEnum.BACK_COMMISSION.value ||
				isSmogComm === commissionSettingsEnum.BOTH_COMMISION.value
			) {
				fiGross_ += parseFloat(dealerSmog);
			}

			if (
				isReserveComm === commissionSettingsEnum.BACK_COMMISSION.value ||
				isReserveComm === commissionSettingsEnum.BOTH_COMMISION.value
			) {
				fiGross_ += parseFloat(reserve_);
			}

			if (
				isServConComm === commissionSettingsEnum.BACK_COMMISSION.value ||
				isServConComm === commissionSettingsEnum.BOTH_COMMISION.value
			) {
				fiGross_ += parseFloat(warrantyProfit_);
			}
			if (
				isGapComm === commissionSettingsEnum.BACK_COMMISSION.value ||
				isGapComm === commissionSettingsEnum.BOTH_COMMISION.value
			) {
				fiGross_ += parseFloat(gapInsProfit_);
				fiGross_ += parseFloat(collisionTotProfit_);
			}
			if (
				isAftMktComm === commissionSettingsEnum.BACK_COMMISSION.value ||
				isAftMktComm === commissionSettingsEnum.BOTH_COMMISION.value
			) {
				fiGross_ += parseFloat(aftmktProfit_);
			}

			if (
				isMiscComm === commissionSettingsEnum.BACK_COMMISSION.value ||
				isMiscComm === commissionSettingsEnum.BOTH_COMMISION.value
			) {
				let miscProfit__ =
					totalSellerFees_ - miscCost_ - docFee - dealerSmog - stateSmog;
				fiGross_ += parseFloat(miscProfit__);
			}

			if (
				isBankFeeComm === commissionSettingsEnum.BACK_COMMISSION.value ||
				isBankFeeComm === commissionSettingsEnum.BOTH_COMMISION.value
			) {
				fiGross_ += parseFloat(lenderFee);
			}
			//fiGross_ = twoDecimal(fiGross_);
			fiGross_ = roundUp(fiGross_);
			editFiGross(fiGross_);
			return fiGross_;
		};

		const calcTotalGross = () => {
			let totalGross_ = 0;
			totalGross_ += carProfit_ + totalTradeProfit_ + interestNetProfit_;

			if (isDocFeeComm !== commissionSettingsEnum.NO_COMMISSION.value) {
				totalGross_ += parseFloat(docFee);
			}
			if (isSmogComm !== commissionSettingsEnum.NO_COMMISSION.value) {
				totalGross_ += parseFloat(dealerSmog);
			}
			if (isReserveComm !== commissionSettingsEnum.NO_COMMISSION.value) {
				totalGross_ += parseFloat(reserve_);
			}
			if (isServConComm !== commissionSettingsEnum.NO_COMMISSION.value) {
				totalGross_ += parseFloat(warrantyProfit_);
			}
			if (isGapComm !== commissionSettingsEnum.NO_COMMISSION.value) {
				totalGross_ += parseFloat(gapInsProfit_);
			}
			if (isAftMktComm !== commissionSettingsEnum.NO_COMMISSION.value) {
				totalGross_ += parseFloat(aftmktProfit_);
			}
			if (isMiscComm !== commissionSettingsEnum.NO_COMMISSION.value) {
				let miscProfit__ =
					totalSellerFees_ - miscCost_ - docFee - dealerSmog - stateSmog;

				totalGross_ += parseFloat(miscProfit__);
			}
			if (isBankFeeComm !== commissionSettingsEnum.NO_COMMISSION.value) {
				totalGross_ += parseFloat(lenderFee);
			}

			totalGross_ += parseFloat(additionalProfit);
			//totalGross_ = twoDecimal(totalGross_);
			totalGross_ = roundUp(totalGross_);
			editTotalGross(totalGross_);
			return totalGross_;
		};

		const calculateCommission = () => {
			let commGross_ = 0;
			let salesComm_ = 0;
			let fiComm_ = 0;
			let totalComm_ = 0;
			let profitComm_ = 0;

			const newComm = commissions.map((commission) => {
				const updatedCommission = { ...commission }; // Create a copy of the commission object

				console.log(updatedCommission.commType);

				if (Number(updatedCommission.commType) === commTypeList.SALE.value) {
					// updatedCommission.commAmount = twoDecimal(
					// 	salesGross_ * updatedCommission.commPercent +
					// 		updatedCommission.fixedAmount
					// );
					updatedCommission.commAmount = roundUp(
						salesGross_ * updatedCommission.commPercent +
							updatedCommission.fixedAmount
					);

					salesComm_ += updatedCommission.commAmount;
				} else if (
					Number(updatedCommission.commType) === commTypeList.FI.value
				) {
					// updatedCommission.commAmount = twoDecimal(
					// 	fiGross_ * updatedCommission.commPercent +
					// 		updatedCommission.fixedAmount
					// );
					updatedCommission.commAmount = roundUp(
						fiGross_ * updatedCommission.commPercent +
							updatedCommission.fixedAmount
					);
					fiComm_ += updatedCommission.commAmount;
				} else if (
					Number(updatedCommission.commType) === commTypeList.TOTAL.value
				) {
					// updatedCommission.commAmount = twoDecimal(
					// 	totalGross_ * updatedCommission.commPercent +
					// 		updatedCommission.fixedAmount
					// );
					updatedCommission.commAmount = roundUp(
						totalGross_ * updatedCommission.commPercent +
							updatedCommission.fixedAmount
					);
					totalComm_ += updatedCommission.commAmount;
				} else if (
					Number(updatedCommission.commType) === commTypeList.NETPROFIT.value
				) {
					// updatedCommission.commAmount = twoDecimal(
					// 	grossProfit_ * updatedCommission.commPercent +
					// 		updatedCommission.fixedAmount
					// );
					updatedCommission.commAmount = roundUp(
						grossProfit_ * updatedCommission.commPercent +
							updatedCommission.fixedAmount
					);
					profitComm_ += updatedCommission.commAmount;
				}
				commGross_ +=
					updatedCommission.bonus +
					updatedCommission.other +
					updatedCommission.commAmount;
				return updatedCommission;
			});

			editCommissions(newComm);
			editSalesComm(salesComm_);
			editFiComm(fiComm_);
			editTotalComm(totalComm_);
			editProfitComm(profitComm_);
			editCommGross(commGross_);
			editNetProfit(grossProfit_ - commGross_);
			return commGross_;
		};

		const salesGross_ = calcSalesGross();
		const fiGross_ = calcFIGross();
		const totalGross_ = calcTotalGross();
		const commGross_ = calculateCommission();

		const calcNetProfit = () => {
			//const finalVal = twoDecimal(grossProfit_ - commGross_);
			const finalVal = roundUp(grossProfit_ - commGross_);
			editNetProfit(finalVal);
		};
		calcNetProfit();
	}, [
		additionalProfit,
		aftMktTaxRate,
		aftermarket,
		apr,
		balloonTerm,
		bankFee,
		bodyInjuryPremium,
		bpa,
		cancellationAccepted,
		collisionPremium,
		collisionTotCost,
		commissions,
		compPremium,
		county,
		countyRecNum,
		daysToPay,
		dealerSmog,
		dealerSmogTaxRate,
		desiredPmt,
		disabilityPremium,
		discount,
		dlrParticipation,
		dmvTaxRate,
		docFee,
		docFeeTaxRate,
		down,
		downPayments,
		isFetTaxable,
		editBusinessTaxRate,
		editFetTaxRate,
		editVitRate,
		editAddOn,
		editAftmkt,
		editAftmktCost,
		editAftmktNonTaxable,
		editAftmktProfit,
		editAmtFinanced,
		editBackendProfit,
		editBalloon,
		editBalloonDate,
		editBalloonFinanceCharge,
		editBalloonTerm,
		editCancellationAmt,
		editCarProfit,
		editCollisionTotPremium,
		editCollisionTotProfit,
		editCommGross,
		editCommissions,
		editDeferredTax,
		editDlrParticipation,
		editDown,
		editFiComm,
		editFiDiscount,
		editFiGross,
		editFinalDueDate,
		editFinalPmt,
		editFinanceCharge,
		editFirstDueDate,
		editFrontendProfit,
		editGapInsProfit,
		editGpBeforeComm,
		editGpBeforeFees,
		editGpBeforeReserve,
		editGrossProfit,
		editInterestCost,
		editInterestNetProfit,
		editInterestProfit,
		editLicense,
		editLoan,
		editMiscCost,
		editMiscProfit,
		editMonthlyTax,
		editNetCheckToDealer,
		editNetProfit,
		editOfficial,
		editOnePay,
		editPayment,
		editProfitComm,
		editReceivable,
		editRecon,
		editReconProfit,
		editRegistration,
		editRegularPmt,
		editReserve,
		editRestockingFee,
		editSalePayoff,
		editSalesComm,
		editSalesGross,
		editSalesTax,
		editTax,
		editTaxType,
		editTaxableAmount,
		editTerm,
		editTotalComm,
		editTotalDMVFees,
		editTotalDown,
		editTotalGross,
		editTotalLenderFee,
		editTotalNetTrade,
		editTotalNonTaxableSellerFees,
		editTotalPickupPmts,
		editTotalSellerFees,
		editTotalTaxable,
		editTotalTradeACV,
		editTotalTradeAllowance,
		editTotalTradePayoff,
		editTotalTradeProfit,
		editTrade,
		editTradeTaxCredit,
		editUserTax1,
		editUserTax2,
		editUserTax3,
		editUserTax4,
		editUserTax5,
		editUserTax6,
		editVit,
		editBusinessTax,
		editFetTax,
		editWarranty,
		editWarrantyCost,
		editWarrantyProfit,
		filingFee,
		fixedAmountTax,
		gap,
		gapInsCost,
		gapTaxRate,
		hasBalloon,
		//	holdback,
		incentive,
		interestCost,
		isAftMktComm,
		isAftMktProfit,
		isBankFeeComm,
		// isBankFeeProfit,
		isDealerSmogProfit,
		isDocFeeComm,
		isDocFeeProfit,
		isGapComm,
		isGapProfit,
		isMiscComm,
		isMiscProfit,
		isReserveComm,
		isReserveProfit,
		isServConComm,
		isServConProfit,
		isSmogComm,
		lenderFee,
		licenseFee,
		licensePct,
		lifePremium,
		loanDate,
		loanPeriod,
		manufacturerRebate,
		maxTaxCredit,
		medicalPremium,
		miscDMVFees,
		miscSellerFees,
		monthlyTax,
		otherPremium,
		pickupPmts,
		price,
		propertyPremium,
		registrationFee,
		registrationPct,
		// reservePercent,
		// reserve,
		reserveFlag,
		rollBack,
		servContractTaxRate,
		smogExemption,
		stateSmog,
		taxCreditFixAmount,
		taxCreditRate,
		taxRate,
		taxType,
		term,
		tireFee,
		titleFee,
		totalDMVFees,
		trades,
		vehicleCost,
		vehicleTotalOtherCost,
		vehicleTotalRecon,
		vitRate,
		businessTaxRate,
		fetTaxRate,
		warrantyList,
		weightFee,
		editWarrantyTax,
	]);

	const memoCalculateRecap = useCallback(() => {
		const calcOfficialFeesProfit = () => {
			const total = tax + totalDMVFees;
			editOfficial(total);
		};
		calcOfficialFeesProfit();

		const calcGPBeforeFees = () => {
			const total = parseFloat(frontendProfit) + parseFloat(backendProfit);
			//const finalVal = twoDecimal(total);
			const finalVal = roundUp(total);
			editGpBeforeFees(finalVal);
		};
		calcGPBeforeFees();
		const calcLenderFeeInfo = () => {
			editTotalLenderFee(lenderFee + fiDiscount);
			editNetCheckToDealer(
				amtFinanced - (lenderFee + fiDiscount) + interestNetProfit
			);
		};
		calcLenderFeeInfo();

		const calcGPBeforeReserve = () => {
			const total =
				parseFloat(gpBeforeFees) -
				parseFloat(totalLenderFee) +
				parseFloat(incentive);
			//+				parseFloat(holdback);
			//const finalVal = twoDecimal(total);
			const finalVal = roundUp(total);
			editGpBeforeReserve(finalVal);
		};
		calcGPBeforeReserve();

		const calcGPBeforeComm = () => {
			console.log(gpBeforeReserve);
			console.log(reserve);
			const total = parseFloat(gpBeforeReserve) - parseFloat(reserve);
			//const finalVal = twoDecimal(total);
			const finalVal = roundUp(total);
			editGpBeforeComm(finalVal);
			editGrossProfit(finalVal);
			console.log(finalVal);
		};
		calcGPBeforeComm();

		const calcReceivable = () => {
			const total =
				parseFloat(amtFinanced) -
				(parseFloat(lenderFee) + parseFloat(fiDiscount)) +
				parseFloat(interestNetProfit) +
				parseFloat(totalDown);
			//const finalVal = twoDecimal(total);
			const finalVal = roundUp(total);
			editReceivable(finalVal);
		};
		calcReceivable();

		const calcReserve = () => {
			const resAmt = amtFinanced * reservePercent;
			editReserve(resAmt);
		};

		calcReserve();
		console.log({
			totalLenderFee,
			tax,
			totalDMVFees,
			amtFinanced,
			reserve,
			backendProfit,
			frontendProfit,
			gpBeforeFees,
			gpBeforeReserve,
			fiDiscount,
			interestNetProfit,
			incentive,
			totalDown,
			lenderFee,
			reservePercent,
			//holdback,
			pack,
		});
	}, [
		editNetCheckToDealer,
		editTotalLenderFee,
		totalLenderFee,
		tax,
		totalDMVFees,
		amtFinanced,
		reserve,
		backendProfit,
		frontendProfit,
		gpBeforeFees,
		gpBeforeReserve,
		fiDiscount,
		interestNetProfit,
		incentive,
		totalDown,
		lenderFee,
		reservePercent,
		//holdback,
		pack,
		editOfficial,
		editGpBeforeFees,
		editGpBeforeReserve,
		editGpBeforeComm,
		editGrossProfit,
		editReceivable,
		editReserve,
	]);

	useEffect(() => {
		// left out some values from the dependency array since they get calculated ie payment
		console.log("Beginning Sale Calculation...");
		console.log(type);
		if (type !== DealStatus.PENDING) {
			console.log("not pending calcs...");
			memoCalculateRecap();
		} else {
			console.log("pending calcs...");
			memoCalculateSales();
		}
		console.log("Ending Sale Calculation...");
		// eslint-disable-next-line
	}, [
		calculateBool,
		taxType,
		price,
		vitRate,
		businessTaxRate,
		fetTaxRate,
		isFetTaxable,
		down,
		//totalPickup,
		//tax,
		manufacturerRebate,
		daysToPay,
		loanDate,
		loanPeriod,
		term,
		firstDueDate,
		apr,
		addOn,
		//amtFinanced,
		//payment,
		//finalPmt,
		//financeCharge,
		//memoCalculateSales,
		collisionTotPremium,
		taxRate,
		countyRecNum,
		cancellationAmt,
		cancellationAccepted,
		vehicleCost,
		vehicleTotalOtherCost,
		vehicleTotalRecon,
		//holdback,
		pack,
		aftmktCost,
		aftmktNonTaxable,
		docFee,
		dealerSmog,
		stateSmog,
		vsiCost,
		miscCost,
		gapInsCost,
		warrantyCost,
		bankFee,
		interestCost,
		dlrParticipation,
		isDocFeeProfit,
		isDealerSmogProfit,
		isReserveProfit,
		isServConProfit,
		isGapProfit,
		isAftMktProfit,
		isMiscProfit,
		// isBankFeeProfit,
		reservePercent,
		rollBack,
		balloonTerm,
		discount,
		bodyInjuryPremium,
		collisionPremium,
		compPremium,
		medicalPremium,
		otherPremium,
		propertyPremium,
		additionalProfit,
		reserve,
		grossProfit,
		salesGross,
		fiGross,
		totalGross,
		carProfit,
		//totalTradeProfit,
		docFee,
		dealerSmog,
		warrantyProfit,
		gapInsProfit,
		aftmktProfit,
		miscProfit,
		lenderFee,
		incentive,
		gpBeforeReserve,
		isDocFeeProfit,
		isDealerSmogProfit,
		isReserveProfit,
		isServConProfit,
		isGapProfit,
		isAftMktProfit,
		isMiscProfit,
		// isBankFeeProfit,
		isDocFeeComm,
		isAftMktComm,
		isServConComm,
		isGapComm,
		isMiscComm,
		isBankFeeComm,
		isReserveComm,
		isSmogComm,
		additionalProfit,
		collisionTotCost,
		monthlyTax,
		deferredTax,
		hasBalloon,
		licenseFee,
		licensePct,
		registrationFee,
		registrationPct,
		titleFee,
		filingFee,
		smogExemption,
		weightFee,
		tireFee,
		bpa,
		fixedAmountTax,
		taxCreditRate,
		taxCreditFixAmount,
		maxTaxCredit,

		// totals/composite values
		totalDMVFees,
		// totalSellerFees,
		// totalNonTaxableSellerFees,
		gap,
		warranty,
		aftmkt,
		//trade,
		//payoff,
		//totalNetTrade,
		totalPickupPmts,
		vit,
		tax,
		amtFinanced,
		financeCharge,
		loan,
		totalDown,
		aftmktProfit,
		collisionTotProfit,
		warrantyProfit,
		interestNetProfit,
		frontendProfit,
		backendProfit,
		gpBeforeFees,
		fiDiscount,
		totalLenderFee,
		reserve,
		reserveFlag,
		gpBeforeReserve,
		grossProfit,
		salesGross,
		fiGross,
		totalGross,
		commGross,
		vehicle?.year,
		vehicle?.type,
	]);
};

export default useCalculateSales;
