import React from "react";
import { Card, CardBody } from "reactstrap";
import ReactTable from "./ReactTable";
import FilterColumn from "utils/filterColumn";

/**
 * InventoryTable component to display the inventory data in a table format.
 *
 * @param {Object} props - Component props
 * @param {Array} props.data - The data to display in the table
 * @param {Function} props.onRowClick - The function to call when a row is clicked
 * @returns {JSX.Element} The rendered component
 */
const InventoryTable = ({ data, onRowClick }) => {
	/**
	 * Renders the vehicle cell with VIN, license plate, and other details.
	 *
	 * @param {Object} props - Cell properties
	 * @returns {JSX.Element} The rendered vehicle cell
	 */
	const vehicleCell = (props) => (
		<>
			{props.row.original.vin && (
				<span>
					<div>{props.row.original.vehicle}</div>
					<div className="font-xs">{props.row.original.vin}</div>
					{props.row.original.license && (
						<div>License Plate: {props.row.original.license}</div>
					)}
				</span>
			)}
		</>
	);

	/**
	 * Renders the status cell.
	 *
	 * @param {Object} props - Cell properties
	 * @returns {JSX.Element} The rendered status cell
	 */
	const statusCell = (props) => (
		<>{props.row.original.status && <span>{props.row.original.status}</span>}</>
	);

	/**
	 * Renders the vehicle type cell.
	 *
	 * @param {Object} props - Cell properties
	 * @returns {JSX.Element} The rendered vehicle type cell
	 */
	const vehicleTypeCell = (props) => (
		<>{props.row.original.type && <span>{props.row.original.type}</span>}</>
	);

	/**
	 * Renders the price cell.
	 *
	 * @param {Object} props - Cell properties
	 * @returns {JSX.Element} The rendered price cell
	 */
	const priceCell = (props) => (
		<>
			{props.row.original.askingPrice && (
				<span>{props.row.original.askingPrice}</span>
			)}
		</>
	);

	/**
	 * Renders the stock cell with stock number, age, and dates.
	 *
	 * @param {Object} props - Cell properties
	 * @returns {JSX.Element} The rendered stock cell
	 */
	const stockCell = (props) => (
		<div className="stock-cell-container">
			{props.row.original.stockNo && (
				<div className="stock-cell-item">
					Stock#: {props.row.original.stockNo}
				</div>
			)}
			{props.row.original.vin &&
				props.row.original.status !== "Deleted" &&
				props.row.original.status !== "Sold" &&
				props.row.original.status !== "Funded" && (
					<div className="stock-cell-item">
						Age: {props.row.original.age} Days
					</div>
				)}
			{props.row.original.vin && props.row.original.status !== "Deleted" && (
				<div className="stock-cell-item">
					Date In: {props.row.original.start}
				</div>
			)}
			{props.row.original.vin &&
				props.row.original.end &&
				props.row.original.status !== "Deleted" && (
					<div className="stock-cell-item">
						Date Out: {props.row.original.end}
					</div>
				)}
		</div>
	);

	return (
		<div className="px-3">
			<Card className="mt-2">
				<CardBody>
					<ReactTable
						data={data}
						columns={[
							{
								Header: "Photo",
								accessor: "thumbnail",
								className: "vehicle-photo",
								disableFilters: true,
								Cell: ({ row }) => (
									<div onClick={() => onRowClick(row.original)}>
										{row.original.thumbnail}
									</div>
								),
							},
							{
								Header: "Stock",
								accessor: "stockAndVin",
								Cell: stockCell,
							},
							{
								Header: "Vehicle",
								accessor: "vehicleSearch",
								Cell: vehicleCell,
							},
							{
								Header: "Price",
								accessor: "askingPrice",
								Cell: priceCell,
							},
							{
								Header: "Vehicle Type",
								accessor: "type",
								Filter: FilterColumn,
								filter: "includes",
								Cell: vehicleTypeCell,
							},
							{
								Header: "Status",
								accessor: "status",
								Filter: FilterColumn,
								filter: "includes",
								Cell: statusCell,
							},
							{
								Header: "Actions",
								accessor: "Actions",
								disableFilters: true,
							},
						]}
					/>
				</CardBody>
			</Card>
		</div>
	);
};

export default InventoryTable;
