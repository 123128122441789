import React, { useState } from "react";
import { Input, Row } from "reactstrap";
import OpenTotalModal from "../OpenTotalModal";
import PickupPaymentModal from "../modals/pickupPayment/PickupPaymentModal";
import NumberFormat from "react-number-format";
import useCurrentSaleStore from "stores/SaleStore";
import shallow from "zustand/shallow";

/**
 * DeferredPayment component renders a row with a button to open a modal for Pickup payments
 * and displays the total pickup payments.
 *
 * @returns {JSX.Element} The rendered DeferredPayment component.
 */
const DeferredPayment = () => {
	const [openPmtModal, setOpenPmtModal] = useState(false);

	const { totalPickupPmts } = useCurrentSaleStore(
		(state) => ({
			totalPickupPmts: state.totalPickupPmts,
		}),
		shallow
	);

	/**
	 * Toggles the visibility of the Pickup payment modal.
	 */
	const toggle = () => {
		setOpenPmtModal(!openPmtModal);
	};

	return (
		<Row>
			{openPmtModal && (
				<PickupPaymentModal modal={openPmtModal} toggle={toggle} />
			)}
			<div className="col-6 px-0">
				<OpenTotalModal
					btnClass="w-100 btn btn-primary btn-md px-0"
					label="Deferred"
					toggle={toggle}
				/>
			</div>

			<div className="col-6 pr-0 pl-2">
				<NumberFormat
					readOnly
					value={totalPickupPmts}
					thousandSeparator
					decimalScale={2}
					fixedDecimalScale
					onFocus={(e) => e.target.select()}
					prefix="$"
					isNumericString
					customInput={Input}
					className="text-danger bold"
				/>
			</div>
		</Row>
	);
};

export default DeferredPayment;
