import React, { useEffect, useState, useCallback } from "react";
import { Button, Card } from "reactstrap";

import { failAlert, saveSuccessAlert } from "../../utils/alertUtils";
import { sendMailgunEmail } from "../../api/DealerIntegrationApi";
import { showApiError } from "../../utils/errorRoutingUtils";
import InputElement, {
	SelectorElement,
} from "../../features/forms/components/InputElement";

import { object, setLocale, string } from "yup";
import { yupResolver } from "@hookform/resolvers";
import { FormProvider, useForm } from "react-hook-form";
import { getTwilioMessageTemplates } from "../../api/TwilioApi";
import { formLoading } from "../../api/FormsAPI";
import swal from "sweetalert2";
import useCurrentCustomerStore from "stores/CustomerStore";
import { MessageTemplateVariablesAndInput } from "../../features/communication/MessageTemplateVariables";
import useCurrentDealStore from "../../stores/DealStore";
import shallow from "zustand/shallow";
import ScheduleMessage from "../../features/crm/components/ScheduleMessage";
import ChildrenModal from "../../components/modals/ChildrenModal";
import useCurrentAccountStore from "../../account/AccountStore";

const MailgunSendEmail = ({
	customerEmail,
	toggle,
	lenderPacket,
	pdfRequest,
	personType = "Customer",
}) => {
	// Get logged in user's email
	//	const dealerEmail = JSON.parse(sessionStorage.getItem("user"))?.email;
	const dealerEmail = JSON.parse(sessionStorage.getItem("user"))?.locations
		?.email;
	const user = JSON.parse(sessionStorage.getItem("user"));
	const locationID = user?.locationID;
	const IN_DEVELOPMENT = process.env.NODE_ENV === "development";

	// Local states
	const [toEmail, setToEmail] = useState(customerEmail || "");
	const [body, setBody] = useState("");
	const [subject, setSubject] = useState("");
	const [templates, setTemplates] = useState([]);
	const [templateOptions, setTemplateOptions] = useState([]);
	const [selectedTemplate, setSelectedTemplate] = useState(null);
	const [cc, setCc] = useState("");
	const [bcc, setBcc] = useState("");

	const [scheduleModal, setScheduleModal] = useState(false);
	const toggleScheduleModal = () => setScheduleModal(!scheduleModal);

	const { ID } = useCurrentCustomerStore(
		(state) => ({ ID: state.ID }),
		shallow
	);
	const { dealID } = useCurrentDealStore(
		(state) => ({
			dealID: state.deal.ID,
		}),
		shallow
	);
	const { accountID } = useCurrentAccountStore(
		(state) => ({
			accountID: state.account.id,
		}),
		shallow
	);

	const buildEmail = () => {
		if (lenderPacket != null) {
			return {
				locationID,
				to: toEmail,
				from: dealerEmail,
				body,
				subject,
				lenderPackRequest: lenderPacket,
				cc,
				bcc,
				dealID: lenderPacket.dealID,
			};
		} else if (pdfRequest != null) {
			return {
				locationID,
				to: toEmail,
				from: dealerEmail,
				body,
				subject,
				pdfRequest,
				cc,
				bcc,
				dealID: pdfRequest.dealID,
			};
		} else {
			return {
				locationID,
				to: toEmail,
				from: dealerEmail,
				body,
				subject,
				bcc,
				cc,
				dealID: dealID,
				accountID: accountID,
				customerID: ID,
			};
		}
	};

	// Calls mailgun api to send the email
	const sendEmail = () => {
		const email = buildEmail();
		formLoading("Email");
		console.log(email);
		sendMailgunEmail(email).then(
			(res) => {
				swal.close();
				toggle();
				saveSuccessAlert(res.data.message);
			},
			(err) => {
				swal.close();
				if (!err.isGeneralError) {
					showApiError(err, failAlert);
				}
			}
		);
	};

	const getTemplates = useCallback(() => {
		getTwilioMessageTemplates(locationID).then(
			(res) => {
				const data = res.data.content;
				const options = [];
				let templateList = {};

				for (let i = 0; i < data.length; i++) {
					const option = {
						label: data[i].name,
						value: data[i].ID,
					};

					options.push(option);
					templateList[data[i].ID] = { ...data[i] };
				}

				setTemplateOptions(options);
				setTemplates(templateList);
			},
			(err) => console.log(err)
		);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		getTemplates();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (selectedTemplate != null) {
			setBody(templates?.[selectedTemplate]?.body || "");
			setSubject(templates?.[selectedTemplate]?.subject || "");
		}
		// eslint-disable-next-line
	}, [selectedTemplate]);

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
	});

	// Define rules for each input field
	const schema = object().shape({
		mailgunFromEmail: string().required(
			"If missing, update in the Settings page"
		),
		mailgunToEmail: string().required(),
		//	messageBody: string().required(),
		// mailgunFileName: string().matches(/^.|\/|\\/, {
		// 	message:
		// 		"Attachment filename cannot contain any of the following characters: '.', '/', '\\'",
		// }),
	});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	return (
		<FormProvider {...methods}>
			<Card>
				<div className="row row-12 mt-2">
					<InputElement
						{...methods}
						readOnly
						colSize="col-lg-6"
						value={dealerEmail}
						name="mailgunFromEmail"
						label="Your Email"
					/>
					<InputElement
						{...methods}
						colSize="col-lg-6"
						value={toEmail}
						onChange={(e) => setToEmail(e.target.value)}
						name="mailgunToEmail"
						label="Customer Email"
					/>
					<InputElement
						{...methods}
						colSize="col-lg-6"
						value={cc}
						onChange={(e) => setCc(e.target.value)}
						name="cc"
						label="Cc (Mutiple Emails separated by comma) "
					/>

					<InputElement
						{...methods}
						colSize="col-lg-6"
						value={bcc}
						onChange={(e) => setBcc(e.target.value)}
						name="bcc"
						label="Bcc (Mutiple Emails separated by comma) "
					/>
					{templateOptions.length > 0 && (
						<SelectorElement
							{...methods}
							colSize="col-lg-6"
							value={selectedTemplate}
							name="twilioMessageTemplateOptions"
							label="Message Template"
							onChange={(e) => setSelectedTemplate(e.target.value)}
							options={templateOptions}
						/>
					)}
				</div>
				<div className="row row-12">
					<InputElement
						{...methods}
						colSize="col-lg-12"
						value={subject}
						onChange={(e) => setSubject(e.target.value)}
						name="mailgunSubject"
						label="Subject"
					/>
				</div>
				<MessageTemplateVariablesAndInput body={body} updateBody={setBody} />
				<div className="col-lg-12 d-flex justify-content-center align-items-center mb-2">
					<Button onClick={methods.handleSubmit(sendEmail)}>Send</Button>
					{IN_DEVELOPMENT && (
						<Button onClick={() => console.log(buildEmail())}>JSON</Button>
					)}
					<Button onClick={toggleScheduleModal}>
						Schedule Message
						<ChildrenModal
							size="lg"
							modal={scheduleModal}
							toggle={toggleScheduleModal}
							modalTitle="Schedule Message"
						>
							<ScheduleMessage
								message={buildEmail()}
								toggle={toggleScheduleModal}
								// sendToNumber={sendToNumber}
								// dealID={dealID}
								// customerID={customerID}
								// personType={personType}
							/>
						</ChildrenModal>
					</Button>
				</div>
			</Card>
		</FormProvider>
	);
};

export default MailgunSendEmail;
