import React, { useEffect, useState } from "react";
import { Button, Form, Row } from "reactstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { useForm, FormProvider } from "react-hook-form";
import shallow from "zustand/shallow";
import InputElement from "features/forms/components/InputElement";
import InputWrapper from "features/forms/components/InputWrapper";
import DealInventoryListData from "../features/deal/subFeatures/inventory/DealInventoryListData";
import OpenModalButton from "../features/deal/components/OpenModalButton";
import ChildrenModal from "../components/modals/ChildrenModal";
import useCurrentAccountStore from "./AccountStore";
import AccountGpsInformation from "./AccountGpsInformation";
import AccountHook from "./AccountHook";
import WholeSaleInvsProvider from "../features/deal/subFeatures/dealTabs/wholesaleInventorys/WholeSaleInvsContext";

const AccountAddVehicle = () => {
	const { account, editAccount, vehicle } = useCurrentAccountStore(
		(state) => state,
		shallow
	);
	const toggleAdd = () => {
		setAdd(!add);
	};
	useEffect(() => {
		if (vehicle.inventoryID == null) {
			return;
		}
		editAccount({
			...account,
			stockNum: vehicle.stockNo,
			inventoryID: vehicle.inventoryID,
			vin: vehicle.vin,
			year: vehicle.year,
			make: vehicle.make,
			model: vehicle.model,
		});

		// eslint-disable-next-line
	}, [vehicle.inventoryID]);
	// const getID = () => {
	// 	if (
	// 		!Array.isArray(wholesaleInvs) || // Ensure wholesaleInvs is an array
	// 		wholesaleInvs[currentIndex] === undefined // Check index bounds
	// 	) {
	// 		return null;
	// 	}

	// 	if (wholesaleInvs[currentIndex].id === undefined) {
	// 		return null;
	// 	}

	// 	return wholesaleInvs[currentIndex].id;
	// };

	// Define rules for each input field
	const schema = yup.object().shape({});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});
	const [add, setAdd] = useState(false);
	const save = AccountHook();
	const commandsave = () => {
		editAccount(account);
		save();
		saveSuccessAlert();
	};

	return (
		<FormProvider {...methods}>
			<Form>
				<InputWrapper
					formTitle="Vehicle Details"
					inputComponents={[
						account.id == null && (
							<Row>
								<OpenModalButton
									buttonClass="btn-lg btn btn-link"
									buttonText="Select Vehicle"
									modalTitle="Vehicles"
									onClick={toggleAdd}
									icon={true}
									iconClass="nc-icon nc-tap-01"
								/>
							</Row>
						),
						<Row>
							<InputElement
								{...methods}
								value={account.stockNum}
								name="stockNo"
								label="Stock Number"
								type="text"
								onChange={(e) =>
									editAccount({
										...account,
										stockNum: e.target.value,
									})
								}
							/>
							,
							<InputElement
								{...methods}
								value={account.vin}
								name="vin"
								label="VIN"
								type="text"
								onChange={(e) =>
									editAccount({
										...account,
										vin: e.target.value,
									})
								}
							/>
							,
							<InputElement
								{...methods}
								value={account.year}
								name="year"
								label="Year"
								type="text"
								onChange={(e) =>
									editAccount({
										...account,
										year: e.target.value,
									})
								}
							/>
							,
							<InputElement
								{...methods}
								value={account.make}
								name="make"
								label="Make"
								type="text"
								onChange={(e) =>
									editAccount({
										...account,
										make: e.target.value,
									})
								}
							/>
							,
							<InputElement
								{...methods}
								value={account.model}
								name="model"
								label="Model"
								type="text"
								onChange={(e) =>
									editAccount({
										...account,
										model: e.target.value,
									})
								}
							/>
							,
						</Row>,
					]}
				/>
			</Form>
			<AccountGpsInformation />
			{account.id != null && (
				<Button
					color="success"
					onClick={() => {
						commandsave();
					}}
					size="md"
				>
					<i className="nc-icon nc-cloud-download-93"></i> Save Information
				</Button>
			)}

			<ChildrenModal
				modalTitle="Select Vehicle"
				modal={add}
				toggle={toggleAdd}
				children={
					<WholeSaleInvsProvider>
						<DealInventoryListData toggle={toggleAdd} mainForm="Account" />
					</WholeSaleInvsProvider>
				}
			/>
		</FormProvider>
	);
};
export default AccountAddVehicle;
