import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import dayjs from "dayjs";

import PreviewMessage from "../../features/communication/PreviewMessage";

import {
	getTwilioMessages,
	getTwilioMessageTemplates,
	sendTwilioMessage,
} from "../../api/TwilioApi";
import { failAlert, saveSuccessAlert } from "../../utils/alertUtils";
import { showApiError } from "../../utils/errorRoutingUtils";
import {
	PhoneInputElement,
	SelectorElement,
} from "../../features/forms/components/InputElement";

import { object, setLocale, string } from "yup";
import { yupResolver } from "@hookform/resolvers";
import { FormProvider, useForm } from "react-hook-form";
import { getTwilioMsgBody } from "api/TwilioApi";
import { MessageTemplateVariablesAndInput } from "../../features/communication/MessageTemplateVariables";
import ChildrenModal from "../../components/modals/ChildrenModal";
import ScheduleMessage from "../../features/crm/components/ScheduleMessage";

const TwilioSendMessage = ({
	sendToNumber,
	dealID = null,
	customerID = null,
	accountID = null,
	personType = "Customer",
}) => {
	console.log(personType);
	// Local storage
	const user = JSON.parse(sessionStorage.getItem("user"));
	const dealerNumber = user?.messagingNumber;
	const locationID = user?.locationID;
	const IN_DEVELOPMENT = process.env.NODE_ENV === "development";

	// Local state
	const [toNumber, setToNumber] = useState(sendToNumber || "");
	const [messageBody, setMessageBody] = useState("");
	const [messageHistory, setMessageHistory] = useState(null);
	const [previewMessage, setPreviewMessage] = useState("");
	const [showPreview, setShowPreview] = useState(false);
	const [templates, setTemplates] = useState([]);
	const [templateOptions, setTemplateOptions] = useState([]);
	const [selectedTemplate, setSelectedTemplate] = useState(null);
	const [scheduleModal, setScheduleModal] = useState(false);
	const toggleScheduleModal = () => setScheduleModal(!scheduleModal);
	//	const [socket, setSocket] = useState(null);
	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			// eslint-disable-next-line
			max: "Cannot be more that ${max} characters long",
		},
	});

	// Define rules for each input field
	const schema = object().shape({
		twilioFromNumber: string().required("Contact support if number is missing"),
		twilioToNumber: string().required(),
		//messageBody: string().required().max(1600),
	});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	console.log(methods.getValues("twilioToNumber"));

	const buildMessage = () => {
		return {
			fromNumber: "+1" + dealerNumber,
			recipients: ["+1" + toNumber],
			messageBody: previewMessage === "" ? messageBody : previewMessage,
			customerID,
			personType,
			dealID,
			accountID,
			locationID: user?.locationID,
		};
	};
	// Check that the send to number is valid before sending a message
	const sendMessage = () => {
		console.log(toNumber);
		if (!isToNumberValid()) return;

		const message = buildMessage();
		console.log(message);
		sendTwilioMessage(message).then(
			() => {
				console.log("Message sent!");
				saveSuccessAlert("Sent!");
				setMessageBody("");
				// getMsgHistory();
			},
			(err) => {
				console.log(err);
				if (!err.isGeneralError) {
					showApiError(err, failAlert);
				}

				console.log(err);
			}
		);
	};

	const isToNumberValid = () => {
		if (typeof toNumber === "number") {
			setToNumber(toNumber.toString());
			if (toNumber.toString().length !== 10) {
				methods.setError("twilioToNumber", {
					message: "Must be a 10 digit number",
				});
				return false;
			}
		} else {
			if (toNumber.length !== 10) {
				methods.setError("twilioToNumber", {
					message: "Must be a 10 digit number",
				});
				return false;
			}
		}
		return true;
	};

	// Gets the messages between two numbers and uses that to display the message history component
	const getMsgHistory = () => {
		const msgHistory = {
			fromNumber: "+1" + dealerNumber,
			recipients: ["+1" + toNumber],
			locationID: user?.locationID,
		};
		console.log(msgHistory);
		getTwilioMessages(msgHistory).then(
			(res) => setMessageHistory(res.data.content.messages),
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, failAlert);
				}
			}
		);
	};

	const fetchHistory = () => {
		if (!isToNumberValid()) return;
		else {
			getMsgHistory();
		}
	};

	// Gets the messages between two numbers and uses that to display the message history component
	const getTemplates = () => {
		getTwilioMessageTemplates(locationID).then(
			(res) => {
				const data = res.data.content;
				const options = [];
				let templateList = {};

				for (let i = 0; i < data.length; i++) {
					const option = {
						label: data[i].name,
						value: data[i].ID,
					};

					options.push(option);
					templateList[data[i].ID] = { ...data[i] };
				}

				setTemplateOptions(options);
				setTemplates(templateList);
			},
			(err) => console.log(err)
		);
	};

	useEffect(() => {
		getMsgHistory();
		getTemplates();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const msg = {
			dealID,
			accountID,
			customerID,
			messageBody,
			personType,
		};
		if (showPreview === true) {
			getTwilioMsgBody(msg).then(
				(res) => {
					setPreviewMessage(res.data.content);
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, failAlert);
					}
				}
			);
		} else {
			setPreviewMessage("");
		}
		// eslint-disable-next-line
	}, [dealID, accountID, customerID, showPreview, personType]);

	useEffect(() => {
		if (selectedTemplate != null) {
			setMessageBody(templates?.[selectedTemplate]?.body || "");
			setShowPreview(false);
		}
		// eslint-disable-next-line
	}, [selectedTemplate]);
	useEffect(() => {
		//Set up WebSocket connection
		// const ws = new WebSocket(
		// 	"ws://8a18-104-182-221-130.ngrok-free.app/ws/messages"
		// );
		const ws = new WebSocket(
			`wss://dealerclick.net/dev/integrations/ws/messages?userId=${locationID}`
		);
		console.log("Connected to WebSocket");
		ws.onopen = () => {
			console.log("Connected to WebSocket");
		};

		ws.onmessage = (event) => {
			console.log("WebSocket message received:", event.data);
			getMsgHistory();
		};

		ws.onclose = () => {
			console.log("Disconnected from WebSocket");
		};

		ws.onerror = (error) => {
			console.error("WebSocket error:", error);
		};

		//setSocket(ws);
		return () => {
			if (ws) {
				ws.close();
			}
		};
		// eslint-disable-next-line
	}, []);
	const twilioMessageHistory = (messages) => {
		const scrollableContainerStyle = {
			maxHeight: "400px", // Maximum height of the container
			overflowY: "auto", // Scroll vertically if the content overflows
			border: "1px solid #ccc", // Optional, adds a border around the container
			padding: "10px", // Optional, adds padding inside the container
		};

		if (messages != null && messages.length > 0) {
			return (
				<>
					<div
						className="text-messages-container"
						style={scrollableContainerStyle}
					>
						<div className="text-messages">
							{messages.map((msg, id) => (
								<div
									key={id}
									className={
										msg.direction === "INBOUND"
											? "text-messages-received"
											: "text-messages-sent"
									}
								>
									<div>{msg.body}</div>
									<p className="text-messages-time">
										{dayjs(msg.dateSent).format("MMM D, YYYY [at] h:mmA")}
									</p>
								</div>
							))}
						</div>
					</div>
				</>
			);
		} else {
			return (
				<CardTitle>
					<div className="h3 text-primary">No text message history...</div>
				</CardTitle>
			);
		}
	};

	return (
		<FormProvider {...methods}>
			<Card>
				<CardBody>
					{twilioMessageHistory(messageHistory)}
					{!showPreview && (
						<div className="">
							<div className="row row-12">
								<PhoneInputElement
									{...methods}
									readOnly
									colSize="col-lg-6"
									value={dealerNumber}
									name="twilioFromNumber"
									label="Your Number"
								/>
								<PhoneInputElement
									{...methods}
									colSize="col-lg-6"
									value={toNumber}
									onChange={setToNumber}
									onBlur={fetchHistory}
									name="twilioToNumber"
									label="Customer Number"
								/>
							</div>
							{templateOptions.length > 0 &&
								personType !== "Lead" &&
								personType !== "CreditApp" && (
									<SelectorElement
										{...methods}
										colSize="col-lg-6"
										value={selectedTemplate}
										name="twilioMessageTemplateOptions"
										label="Message Template"
										onChange={(e) => setSelectedTemplate(e.target.value)}
										options={templateOptions}
									/>
								)}

							<MessageTemplateVariablesAndInput
								{...methods}
								body={messageBody}
								updateBody={setMessageBody}
							/>
						</div>
					)}
					{showPreview && <PreviewMessage message={previewMessage} />}
					<div className="col-lg-12 d-flex justify-content-center align-items-center">
						{messageBody.length > 0 && (
							<div className="d-flex justify-content-center align-items-center my-2">
								<Button onClick={() => setShowPreview(!showPreview)}>
									{!showPreview ? "Preview" : "Close"}
								</Button>
							</div>
						)}
						{!showPreview && (
							<>
								<div
									className="btn btn-md btn-info ml-3"
									onClick={methods.handleSubmit(sendMessage)}
								>
									<i className="fa fa-paper-plane" /> Send
								</div>
								{IN_DEVELOPMENT && (
									<Button onClick={() => console.log(buildMessage())}>
										JSON
									</Button>
								)}
								<Button onClick={toggleScheduleModal}>
									Schedule Message
									<ChildrenModal
										size="lg"
										modal={scheduleModal}
										toggle={toggleScheduleModal}
										modalTitle="Schedule Message"
									>
										<ScheduleMessage
											message={buildMessage()}
											toggle={toggleScheduleModal}
											// sendToNumber={sendToNumber}
											// dealID={dealID}
											// customerID={customerID}
											// personType={personType}
										/>
									</ChildrenModal>
								</Button>
							</>
						)}
					</div>
				</CardBody>
			</Card>
		</FormProvider>
	);
};

export default TwilioSendMessage;
