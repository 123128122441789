import React, { useState, useEffect } from "react";
import {
	Button,
	FormGroup,
	Label,
	Input,
	Card,
	Col,
	CardBody,
	CardHeader,
	CardTitle,
	Row,
	Form,
} from "reactstrap";
import { TaxRegion, taxRateEnum } from "constants/Constants";
import NumberFormat from "react-number-format";
import useCurrentDealStore from "stores/DealStore";
import { retrieveFailAlert } from "utils/alertUtils";
import { useFromUser } from "stores/LocalStorageHelper";
import { getSettings } from "api/SettingsAPI";
import { DealStatus } from "constants/Constants";
import useCurrentSaleStore from "stores/SaleStore";
import formNavigationUtils from "utils/formNavigationUtils";
import { showApiError } from "utils/errorRoutingUtils";
import shallow from "zustand/shallow";
import { TaxType } from "constants/Constants";
import TaxCountySelector from "features/deal/subFeatures/taxCountySelector/TaxCountySelector";
import ChildrenModal from "../../../../../components/modals/ChildrenModal";
import CountyTax from "./county/CountyTax";

/**
 *
 */
const TaxDefaults = ({
	taxDefaults,
	editTaxDefaults,
	editTaxDefaultsValue,
	isDeal,
}) => {
	const { editTaxRate, editTaxType } = useCurrentSaleStore(
		(state) => ({
			editTaxRate: state.editTaxRate,
			editTaxType: state.editTaxType,
		}),
		shallow
	);

	const { type, countyRecNum } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
			countyRecNum: state.deal.countyRecNum,
		}),
		shallow
	);

	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	// Obj for creating components
	const ratesObj = [
		{
			name: "aftMktTaxRate",
			value: taxDefaults?.aftMktTaxRate,
			onChange: (val) => editTaxDefaultsValue("aftMktTaxRate", val),
			rateLabel: "Aftermarket Tax Rate",
			dropdownLabel: "Tax Aftermarket",
		},
		{
			name: "servContractTaxRate",
			value: taxDefaults?.servContractTaxRate,
			onChange: (val) => editTaxDefaultsValue("servContractTaxRate", val),
			rateLabel: "Service Contract Tax Rate",
			dropdownLabel: "Tax Service Contract",
		},
		{
			name: "gapTaxRate",
			value: taxDefaults?.gapTaxRate,
			onChange: (val) => editTaxDefaultsValue("gapTaxRate", val),
			rateLabel: "Gap Insurance Tax Rate",
			dropdownLabel: "Tax Gap Insurance",
		},
		{
			name: "docFeeTaxRate",
			value: taxDefaults?.docFeeTaxRate,
			onChange: (val) => editTaxDefaultsValue("docFeeTaxRate", val),
			rateLabel: "Doc Fee Tax Rate",
			dropdownLabel: "Tax Doc Fee",
		},
		{
			name: "dmvTaxRate",
			value: taxDefaults?.dmvTaxRate,
			onChange: (val) => editTaxDefaultsValue("dmvTaxRate", val),
			rateLabel: "DMV Tax Rate",
			dropdownLabel: "Tax DMV Fees",
		},
		{
			name: "dealerSmogTaxRate",
			value: taxDefaults?.dealerSmogTaxRate,
			onChange: (val) => editTaxDefaultsValue("dealerSmogTaxRate", val),
			rateLabel: "Dealer Smog Tax Rate",
			dropdownLabel: "Tax Dealer Smog",
		},
	];

	const getData = () => {
		getSettings(dealerID, locationID).then(
			(res) => {
				editTaxDefaults(res.data.content.taxDefaults);
			},
			(err) => {
				console.log(err);

				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};

	const UseDefaults = () => {
		return (
			<Col className="mb-2">
				<Button
					readOnly={type !== DealStatus.PENDING}
					color="primary"
					onClick={() => {
						getData();
					}}
					size="md"
					className="text-left"
				>
					<i className="nc-icon nc-check-2"></i> Use Defaults
				</Button>
			</Col>
		);
	};

	const taxComponents = ratesObj.map((obj, index) => {
		return <TaxComponent key={index} obj={obj} />;
	});

	const regionDropdown = (
		<>
			<Col md="3">
				<Label>Tax Type</Label>
				<Input
					name="selectTaxType"
					type="select"
					value={taxDefaults?.taxType}
					onChange={(e) => {
						editTaxType(parseInt(e.target.value));
						editTaxDefaultsValue("taxType", parseInt(e.target.value));
					}}
				>
					<option value={TaxType[0].value}>{TaxType[0].label}</option>
					<option value={TaxType[1].value}>{TaxType[1].label}</option>
				</Input>
			</Col>
			<Col md="3">
				<Label>Select Tax Region</Label>
				<Input
					name="selectTaxRegion"
					type="select"
					value={taxDefaults?.taxRegion}
					onChange={(e) => {
						console.log(e.target.value);
						editTaxDefaultsValue("taxRegion", parseInt(e.target.value));
					}}
				>
					<option value={TaxRegion[0].value}>{TaxRegion[0].label}</option>
					<option value={TaxRegion[1].value}>{TaxRegion[1].label}</option>
					<option value={TaxRegion[2].value}>{TaxRegion[2].label}</option>
				</Input>
			</Col>

			<Col md="3">
				<TaxCountySelector />
			</Col>
			<Col md="3">
				<Label>Tax Rate (%)</Label>
				<NumberFormat
					name="settingsTaxRate"
					value={taxDefaults?.taxRate * 100}
					readOnly={
						// eslint-disable-next-line
						taxDefaults?.taxRegion === 2 && countyRecNum === null ? false : true
					}
					decimalScale={4}
					fixedDecimalScale={true}
					onFocus={(e) => e.target.select()}
					isNumericString={true}
					customInput={Input}
					onValueChange={(values) => {
						if (values.value === "") {
							editTaxRate(0);
							editTaxDefaultsValue("taxRate", 0);
						} else {
							editTaxRate(values.floatValue / 100);
							editTaxDefaultsValue("taxRate", values.floatValue / 100);
						}
						if (values.value !== taxDefaults?.taxRate * 100) {
							console.log(countyRecNum);
						}
					}}
				/>
			</Col>
		</>
	);

	const taxCredit = (
		<>
			<Col md="3">
				<FormGroup>
					<Label>Trade In Tax Credit Rate (%)</Label>
					<NumberFormat
						name="taxCreditRate"
						value={taxDefaults?.taxCreditRate * 100}
						decimalScale={4}
						fixedDecimalScale={true}
						onFocus={(e) => e.target.select()}
						isNumericString={true}
						customInput={Input}
						onValueChange={(values) => {
							if (values.value === "") {
								editTaxDefaultsValue("taxCreditRate", 0);
							} else {
								editTaxDefaultsValue("taxCreditRate", values.floatValue / 100);
							}
						}}
					/>
				</FormGroup>
			</Col>
			<Label>+</Label>
			<Col md="3">
				<FormGroup>
					<Label>Tax Credit Fixed Amount</Label>
					<NumberFormat
						value={taxDefaults?.taxCreditFixAmount}
						name="taxCreditFixAmount"
						thousandSeparator={true}
						decimalScale={0}
						prefix={"$"}
						fixedDecimalScale={true}
						onFocus={(e) => e.target.select()}
						isNumericString={true}
						customInput={Input}
						onValueChange={(values) => {
							if (values.value === "") {
								editTaxDefaultsValue("taxCreditFixAmount", 0);
							} else {
								editTaxDefaultsValue("taxCreditFixAmount", values.floatValue);
							}
						}}
					/>
				</FormGroup>
			</Col>
			<Col md="3">
				<FormGroup>
					<Label>Maximum Tax Credit</Label>
					<NumberFormat
						value={taxDefaults?.maxTaxCredit}
						name="maxTaxCredit"
						thousandSeparator={true}
						decimalScale={0}
						prefix={"$"}
						fixedDecimalScale={true}
						onFocus={(e) => e.target.select()}
						isNumericString={true}
						customInput={Input}
						onValueChange={(values) => {
							if (values.value === "") {
								editTaxDefaultsValue("maxTaxCredit", 0);
							} else {
								editTaxDefaultsValue("maxTaxCredit", values.floatValue);
							}
						}}
					/>
				</FormGroup>
			</Col>
		</>
	);

	const otherTax = (
		<>
			<Col md="3">
				<FormGroup>
					<Label>Vehicle Inventory TaxRate (VIT)</Label>
					<NumberFormat
						name="vitRate"
						value={taxDefaults?.vitRate}
						decimalScale={8}
						fixedDecimalScale={true}
						onFocus={(e) => e.target.select()}
						isNumericString={true}
						customInput={Input}
						onValueChange={(values) => {
							if (values.value === "") {
								editTaxDefaultsValue("vitRate", 0);
							} else {
								editTaxDefaultsValue("vitRate", values.floatValue);
							}
						}}
					/>
				</FormGroup>
			</Col>
			<Col md="3">
				<FormGroup>
					<Label>Federal Excise Tax (FET)(%)</Label>
					<NumberFormat
						name="fetTaxRate"
						value={taxDefaults?.fetTaxRate * 100}
						decimalScale={4}
						fixedDecimalScale={true}
						onFocus={(e) => e.target.select()}
						isNumericString={true}
						customInput={Input}
						onValueChange={(values) => {
							if (values.value === "") {
								editTaxDefaultsValue("fetTaxRate", 0);
							} else {
								editTaxDefaultsValue("fetTaxRate", values.floatValue / 100);
							}
						}}
					/>
				</FormGroup>
			</Col>
			<Col md="3">
				<FormGroup>
					<Label>Business TaxRate (%)</Label>
					<NumberFormat
						name="businessTaxRate"
						value={taxDefaults?.businessTaxRate}
						decimalScale={4}
						fixedDecimalScale={true}
						onFocus={(e) => e.target.select()}
						isNumericString={true}
						customInput={Input}
						onValueChange={(values) => {
							if (values.value === "") {
								editTaxDefaultsValue("businessTaxRate", 0);
							} else {
								editTaxDefaultsValue("businessTaxRate", values.floatValue);
							}
						}}
					/>
				</FormGroup>
			</Col>
		</>
	);
	// const UseTaxByAddress = () => {
	// 	return (
	// 		<div>
	// 			<label style={{ fontSize: "1.5rem" }}>
	// 				<input
	// 					type="checkbox"
	// 					style={{ width: "18px", height: "18px" }}
	// 					checked={taxDefaults?.useTaxByAddress}
	// 					onChange={(e) =>
	// 						editTaxDefaultsValue("useTaxByAddress", e.target.checked)
	// 					}
	// 				/>
	// 				&nbsp;Use TaxRate By Address
	// 			</label>
	// 		</div>
	// 	);
	// };
	const toggleCountyTax = () => setOpenCountyTax(!openCountyTax);
	const [openCountyTax, setOpenCountyTax] = useState(false);

	return (
		<div onKeyDown={formNavigationUtils}>
			{isDeal !== true && (
				<Col className="col-12 my-2">
					<div
						className="btn btn-primary-dark btn-sm"
						onClick={toggleCountyTax}
					>
						County Tax
					</div>
					<ChildrenModal
						modalTitle="County Tax Rates"
						modal={openCountyTax}
						toggle={toggleCountyTax}
					>
						<CountyTax toggle={toggleCountyTax} />
					</ChildrenModal>
				</Col>
			)}
			<Form>
				{isDeal && (
					<Row className="mx-0 pb-2">
						<Col>
							<UseDefaults />
						</Col>
					</Row>
				)}

				<Card>
					<CardHeader>
						<CardTitle tag="h3">Tax Settings</CardTitle>
					</CardHeader>
					<CardBody>
						<Row className="mx-0">{regionDropdown}</Row>
						<Row className="mx-0">{taxComponents}</Row>
						<Row className="mx-0">{taxCredit}</Row>
						<Row className="mx-0">{otherTax}</Row>
						{/* {isDeal && <UseTaxByAddress />} */}
					</CardBody>
				</Card>
			</Form>
		</div>
	);
};

export default TaxDefaults;

const TaxComponent = ({ obj }) => {
	const setToggleState = (dbValue) => {
		if (
			dbValue === taxRateEnum.DEFAULT_TAX_RATE ||
			dbValue === taxRateEnum.NONTAXABLE
		) {
			return false;
		} else return true;
	};
	const initializeToSelect = (dbValue) => {
		if (dbValue === taxRateEnum.DEFAULT_TAX_RATE) {
			return "-1";
		} else if (dbValue === taxRateEnum.NONTAXABLE) {
			return "0";
		} else {
			return "1";
		}
	};
	const [toggle, setToggle] = useState();
	const [select, setSelect] = useState();

	useEffect(() => {
		setToggle(setToggleState(obj.value));
		setSelect(initializeToSelect(obj.value));
	}, [obj.value]);
	return (
		<>
			<Col md="3">
				<FormGroup>
					<Label>{obj.dropdownLabel}</Label>
					<Input
						name={obj.name}
						type="select"
						value={select}
						onChange={(e) => {
							setSelect(e.target.value);
							if (e.target.value === "-1" || e.target.value === "0") {
								setToggle(false);
								obj.onChange(Number(e.target.value));
							} else {
								setToggle(true);
								obj.onChange("0");
							}
						}}
					>
						<option value="0">Non-Taxable</option>
						<option value="-1">Default Tax Rate</option>
						<option value="1">Specific Rate</option>
					</Input>
				</FormGroup>
			</Col>
			{toggle && (
				<Col md="3">
					<FormGroup>
						<Label>{obj.rateLabel + " (%)"}</Label>
						<NumberFormat
							name={`${obj.name}%`}
							value={obj.value * 100}
							decimalScale={4}
							fixedDecimalScale={true}
							onFocus={(e) => e.target.select()}
							isNumericString={true}
							customInput={Input}
							onValueChange={(values) => {
								if (values.value === "") {
									obj.onChange(0);
								} else {
									obj.onChange(values.floatValue / 100);
								}
							}}
						/>
					</FormGroup>
				</Col>
			)}
		</>
	);
};
