import React, { useEffect, useState, useCallback } from "react";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import dayjs from "dayjs";

import {
	getTwilioMessages,
	getTwilioMessageTemplates,
	sendTwilioMessage,
} from "../../api/TwilioApi";
import { sendMailgunEmail } from "../../api/DealerIntegrationApi";
import { failAlert, saveSuccessAlert } from "../../utils/alertUtils";
import { showApiError } from "../../utils/errorRoutingUtils";
import InputElement, {
	SelectorElement,
	PhoneInputElement,
} from "../../features/forms/components/InputElement";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers";
import { FormProvider, useForm } from "react-hook-form";
import { MessageTemplateVariablesAndInput } from "../../features/communication/MessageTemplateVariables";
import ChildrenModal from "../../components/modals/ChildrenModal";
import ScheduleMessage from "../../features/crm/components/ScheduleMessage";
import swal from "sweetalert2";
import shallow from "zustand/shallow";
import { formLoading } from "../../api/FormsAPI";
import useCurrentAccountStore from "../../account/AccountStore";

const SendMessage = ({
	sendToNumber,
	customerEmail,
	dealID = null,
	accountID = null,
	personType = "Customer",
	toggle,
}) => {
	let account; // Declare variables outside

	if (personType === "Account") {
		({ account } = useCurrentAccountStore((state) => state, shallow));
	}

	const user = JSON.parse(sessionStorage.getItem("user"));
	const dealerNumber = user?.messagingNumber;
	const dealerEmail = user?.locations?.email;
	const locationID = user?.locationID;
	const userCellPhone = user?.cellPhone;

	const [toNumber, setToNumber] = useState(sendToNumber || "");
	const [toEmail, setToEmail] = useState(customerEmail || "");
	const [messageBody, setMessageBody] = useState("");
	const [subject, setSubject] = useState("");
	const [messageHistory, setMessageHistory] = useState(null);
	const [templates, setTemplates] = useState({});
	const [templateOptions, setTemplateOptions] = useState([]);
	const [selectedTemplate, setSelectedTemplate] = useState(null);
	const [scheduleModal, setScheduleModal] = useState(false);
	const [reminderMode, setReminderMode] = useState(false);
	const [messageType, setMessageType] = useState(2);
	const [cc, setCc] = useState("");
	const [bcc, setBcc] = useState("");
	const [showHistoryModal, setShowHistoryModal] = useState(false);
	const toggleScheduleModal = () => setScheduleModal(!scheduleModal);
	const toggleHistoryModal = () => {
		setShowHistoryModal(!showHistoryModal);
		if (!showHistoryModal) {
			getTwilioMessages({
				fromNumber: `+1${dealerNumber}`,
				recipients: [`+1${toNumber}`],
				locationID,
			})
				.then((res) => setMessageHistory(res.data.content.messages))
				.catch((err) => showApiError(err, failAlert));
		}
	};
	const twilioMessageHistory = (messages) => {
		const scrollableContainerStyle = {
			maxHeight: "400px", // Maximum height of the container
			overflowY: "auto", // Scroll vertically if the content overflows
			border: "1px solid #ccc", // Optional, adds a border around the container
			padding: "10px", // Optional, adds padding inside the container
		};

		if (messages != null && messages.length > 0) {
			return (
				<>
					<div
						className="text-messages-container"
						style={scrollableContainerStyle}
					>
						<div className="text-messages">
							{messages.map((msg, id) => (
								<div
									key={id}
									className={
										msg.direction === "INBOUND"
											? "text-messages-received"
											: "text-messages-sent"
									}
								>
									<div>{msg.body}</div>
									<p className="text-messages-time">
										{dayjs(msg.dateSent).format("MMM D, YYYY [at] h:mmA")}
									</p>
								</div>
							))}
						</div>
					</div>
				</>
			);
		} else {
			return (
				<CardTitle>
					<div className="h3 text-primary">No text message history...</div>
				</CardTitle>
			);
		}
	};

	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(
			object().shape({
				mailgunFromEmail: string().required(
					"If missing, update in the Settings page"
				),
				mailgunToEmail: string().required(),
				twilioFromNumber: string().required(
					"Contact support if number is missing"
				),
				twilioToNumber: string().required(),
			})
		),
		defaultValues: {
			messageType: 0,
		},
	});
	const isToNumberValid = () => {
		if (typeof toNumber === "number") {
			setToNumber(toNumber.toString());
			if (toNumber.toString().length !== 10) {
				methods.setError("twilioToNumber", {
					message: "Must be a 10 digit number",
				});
				return false;
			}
		} else {
			if (toNumber.length !== 10) {
				methods.setError("twilioToNumber", {
					message: "Must be a 10 digit number",
				});
				return false;
			}
		}
		return true;
	};

	const buildEmail = () => {
		return {
			to: toEmail,
			from: dealerEmail,
			body: messageBody,
			subject,
			bcc,
			cc,
			dealID: dealID,
			accountID,
			personType,
			messageType: parseInt(messageType),
			frequency: account?.period,
			term: account?.term,
			scheduleDate: account?.nextDueDate,
		};
	};
	// Calls mailgun api to send the email
	const sendEmail = () => {
		const email = buildEmail();
		formLoading("Email");
		console.log(email);
		sendMailgunEmail(email).then(
			(res) => {
				swal.close();
				toggle();
				saveSuccessAlert(res.data.message);
			},
			(err) => {
				swal.close();
				if (!err.isGeneralError) {
					showApiError(err, failAlert);
				}
			}
		);
	};
	const buildMessage = () => {
		return {
			fromNumber: "+1" + dealerNumber,
			recipients: ["+1" + toNumber],
			messageBody: messageBody,
			personType,
			dealID,
			accountID,
			locationID: user?.locationID,
			messageType: parseInt(messageType),
			frequency: account?.period,
			term: account?.term,
			scheduleDate: account?.nextDueDate,
		};
	};

	const sendMessage = () => {
		console.log(messageType);
		if (messageType === 0 || messageType === 2) {
			console.log(toNumber);

			if (!isToNumberValid()) return;

			const message = buildMessage();
			console.log(message);
			sendTwilioMessage(message).then(
				() => {
					console.log("Message sent!");
					saveSuccessAlert("Sent!");
					setMessageBody("");
					// getMsgHistory();
				},
				(err) => {
					console.log(err);
					if (!err.isGeneralError) {
						showApiError(err, failAlert);
					}

					console.log(err);
				}
			);
		}
		if (messageType === 1 || messageType === 2) {
			sendEmail();
		}
	};
	const buildScheduleMessage = () => {
		console.log(messageType);
		if (messageType === 0) {
			return buildMessage();
		} else if (messageType === 1) {
			return buildEmail();
		} else {
			return {
				fromNumber: "+1" + dealerNumber,
				recipients: ["+1" + toNumber],
				to: toEmail,
				from: dealerEmail,
				subject,
				cc,
				bcc,

				messageBody: messageBody,
				personType,
				dealID: dealID,
				accountID,
				locationID: user?.locationID,
				messageType: parseInt(messageType),
				frequency: account?.period,
				term: account?.term,
				scheduleDate: account?.nextDueDate,
			};
		}
	};
	const buildReminderMessage = () => {
		return {
			fromNumber: "+1" + dealerNumber,
			recipients: ["+1" + userCellPhone],
			messageBody: messageBody,
			dealID: dealID,
			accountID,
			personType: personType,
			locationID: user?.locationID,
			messageType: 3,
			frequency: 0,
			term: 0,
			scheduleDate: account?.nextDueDate,
		};
	};
	const toggleDealerReminderModal = () => {
		if (!userCellPhone || userCellPhone.length !== 10) {
			failAlert("Dealer user's phone number is invalid");
			return;
		}
		console.log(userCellPhone);
		// Directly set reminderMode
		setReminderMode(true);
		setScheduleModal(true);
	};
	useEffect(() => {
		getTwilioMessageTemplates(locationID).then((res) => {
			const data = res.data.content;
			const options = data.map(({ name, ID }) => ({ label: name, value: ID }));
			let templateMap = {};
			data.forEach((template) => {
				templateMap[template.ID] = template;
			});
			setTemplateOptions(options);
			setTemplates(templateMap);
		});
	}, []);

	useEffect(() => {
		if (selectedTemplate != null && templates[selectedTemplate]) {
			setMessageBody(templates[selectedTemplate].body || "");
			setSubject(templates[selectedTemplate].subject || "");
		}
	}, [selectedTemplate, templates]);

	return (
		<FormProvider {...methods}>
			<Card>
				<CardBody>
					<div className="row">
						{templateOptions.length > 0 &&
							personType !== "Lead" &&
							personType !== "CreditApp" && (
								<SelectorElement
									{...methods}
									colSize="col-lg-6"
									value={selectedTemplate}
									name="twilioMessageTemplateOptions"
									label="Message Template"
									onChange={(e) => setSelectedTemplate(e.target.value)}
									options={templateOptions}
								/>
							)}
						{messageType !== 1 && (
							<Button onClick={toggleHistoryModal} className="mb-3">
								View Message History
							</Button>
						)}
					</div>
					{messageType !== 0 && (
						<div className="row row-12">
							<InputElement
								{...methods}
								colSize="col-lg-12"
								value={subject}
								onChange={(e) => setSubject(e.target.value)}
								name="mailgunSubject"
								label="Subject"
							/>
						</div>
					)}
					<MessageTemplateVariablesAndInput
						{...methods}
						body={messageBody}
						updateBody={setMessageBody}
					/>

					<div className="row">
						{messageType !== 1 && (
							<PhoneInputElement
								{...methods}
								colSize="col-lg-6"
								value={dealerNumber}
								name="twilioFromNumber"
								label="Your Number"
								readOnly
							/>
						)}
						{messageType !== 1 && (
							<PhoneInputElement
								{...methods}
								colSize="col-lg-6"
								value={toNumber}
								onChange={setToNumber}
								name="twilioToNumber"
								label="Customer Number"
							/>
						)}
						{messageType !== 0 && (
							<InputElement
								{...methods}
								colSize="col-lg-6"
								value={dealerEmail}
								name="mailgunFromEmail"
								label="Your Email"
								readOnly
							/>
						)}
						{messageType !== 0 && (
							<InputElement
								{...methods}
								colSize="col-lg-6"
								value={toEmail}
								onChange={(e) => setToEmail(e.target.value)}
								name="mailgunToEmail"
								label="Customer Email"
							/>
						)}
						{messageType !== 0 && (
							<InputElement
								{...methods}
								colSize="col-lg-6"
								value={cc}
								onChange={(e) => setCc(e.target.value)}
								name="cc"
								label="Cc (Mutiple Emails separated by comma) "
							/>
						)}
						{messageType !== 0 && (
							<InputElement
								{...methods}
								colSize="col-lg-6"
								value={bcc}
								onChange={(e) => setBcc(e.target.value)}
								name="bcc"
								label="Bcc (Mutiple Emails separated by comma) "
							/>
						)}
					</div>

					<SelectorElement
						{...methods}
						colSize="col-sm-3"
						value={messageType}
						name="messageType"
						label="Message Type"
						onChange={(e) => setMessageType(parseInt(e.target.value))}
						options={[
							{ value: 0, label: "SMS & Email" },
							{ value: 1, label: "SMS" },
							{ value: 2, label: "Email" },
						]}
					/>

					<div className="col-lg-12 d-flex justify-content-center">
						<Button onClick={() => sendMessage()}>Send</Button>
						<Button onClick={toggleScheduleModal}>Schedule</Button>
						<Button onClick={toggleDealerReminderModal}>Reminder</Button>
					</div>
					<ChildrenModal
						size="lg"
						modal={showHistoryModal}
						toggle={toggleHistoryModal}
						modalTitle="Message History"
					>
						{twilioMessageHistory(messageHistory)}
					</ChildrenModal>

					<ChildrenModal
						size="lg"
						modal={scheduleModal}
						toggle={toggleScheduleModal}
						modalTitle={reminderMode ? "Reminder" : "Schedule Message"}
					>
						<ScheduleMessage
							message={
								reminderMode ? buildReminderMessage() : buildScheduleMessage()
							}
							toggle={toggleScheduleModal}
						/>
					</ChildrenModal>
				</CardBody>
			</Card>
		</FormProvider>
	);
};

export default SendMessage;
