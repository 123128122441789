import { object } from "yup";
import { yupResolver } from "@hookform/resolvers";
import { useState } from "react";
import InputElement, {
	DateTimeInput,
	SelectorElement,
} from "../../forms/components/InputElement";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { saveSchedulerMessage } from "../../../api/DealerIntegrationApi";
import { useFromUser } from "../../../stores/LocalStorageHelper";

const ScheduleMessage = ({ message, toggle }) => {
	console.log(message.messageType);

	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	const [scheduleDate, setScheduleDate] = useState(
		dayjs(message?.scheduleDate).format("YYYY-MM-DDTHH:mm")
	);

	const [totalExecuted, setTotalExecuted] = useState(message?.term || 1);
	const [frequency, setFrequency] = useState(message?.frequency || 0);
	const [messageType, setMessageType] = useState(message.messageType);
	const schema = object().shape({});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	const buildScheduleMessage = () => {
		console.log(message);

		return {
			dealerId: dealerID.toString(),
			locationID: locationID.toString(),
			source: message.personType,
			sourceID:
				message.personType === "Deal"
					? message?.dealID?.toString()
					: message.personType === "Account"
					? message?.accountID?.toString()
					: message?.customerID?.toString(),

			cell: message?.recipients ? message.recipients[0] : null,

			emailFrom: message?.from ? message.from : null,
			emailTo: message?.to ? message.to : null,
			emailBCC: message?.bcc ? message.bcc : null,
			emailCC: message?.cc ? message.cc : null,
			emailSubject: message.emailSubject,

			message: message?.recipients ? message.messageBody : message.body,

			cellEmailBoth: messageType,
			frequency: frequency,
			startDate: scheduleDate,
			totalExecutedTimes: 0,
			expirationDate: calculateExpirationDate(
				scheduleDate,
				frequency,
				totalExecuted
			),
			active: 1,
		};
	};
	const calculateExpirationDate = (scheduleDate, frequency, totalExecuted) => {
		let expirationDate = dayjs(scheduleDate);

		switch (frequency) {
			case 0: // One Time
				expirationDate = expirationDate;
				break;
			case 1: // Daily
				expirationDate = expirationDate.add(totalExecuted, "day");
				break;
			case 2: // Weekly
				expirationDate = expirationDate.add(totalExecuted, "week");
				break;
			case 3: // Monthly
				expirationDate = expirationDate.add(totalExecuted, "month");
				break;
			case 4: // Yearly
				expirationDate = expirationDate.add(totalExecuted, "year");
				break;
			default:
				expirationDate = expirationDate; // Default case (should not happen)
		}

		return expirationDate.format("YYYY-MM-DDTHH:mm");
	};
	const calculateTotalExecuted = (scheduleDate, frequency, expirationDate) => {
		const start = dayjs(scheduleDate);
		const end = dayjs(expirationDate);

		let totalExecuted = 0;

		switch (frequency) {
			case 0: // One Time
				totalExecuted = 0;
				break;
			case 1: // Daily
				totalExecuted = end.diff(start, "day");
				break;
			case 2: // Weekly
				totalExecuted = end.diff(start, "week");
				break;
			case 3: // Monthly
				totalExecuted = end.diff(start, "month");
				break;
			case 4: // Yearly
				totalExecuted = end.diff(start, "year");
				break;
			default:
				totalExecuted = 0; // Default case (should not happen)
		}

		return totalExecuted;
	};
	// private Long id;
	// private String cell;
	// private Integer cellEmailBoth;
	// private String dealerId;
	// private String emailBCC;
	// private String emailCC;
	// private String emailFrom;
	// private String emailSubject;
	// private String emailTo;
	// private LocalDateTime expirationDate;
	// private Integer frequency;
	// private LocalDateTime lastExecutionDate;
	// private String locationID;
	// private String message;
	// private String source;
	// private String sourceID;
	// private LocalDateTime startDate;
	// private Integer totalExecutedTimes;
	// private Boolean active;
	const toggleSave = async () => {
		console.log("toggleSave");
		const message = buildScheduleMessage();
		console.log(message);
		await saveSchedulerMessage(message).then(
			(res) => {
				console.log(res);
				toggle();
			},
			(err) => {
				console.log(err);
			}
		);
	};
	return (
		<div className="row">
			<div className="col-lg-12 d-flex justify-content-center align-items-center">
				<DateTimeInput
					{...methods}
					colSize="col-lg-4"
					value={scheduleDate}
					name="scheduleDate"
					label="Schedule Date"
					onChange={(e) => setScheduleDate(e.target.value)}
				/>
				<SelectorElement
					{...methods}
					colSize="col-sm-3"
					value={frequency}
					name="frequency"
					label="Frequency"
					onChange={(e) => setFrequency(e.target.value)}
					options={[
						{ value: 0, label: "One Time" },
						{ value: 1, label: "Daily" },
						{ value: 2, label: "Weekly" },
						{ value: 3, label: "Monthly" },
						{ value: 4, label: "Yearly" },
					]}
				/>
				<InputElement
					{...methods}
					colSize="col-sm-2"
					value={totalExecuted}
					name="totalExecuted"
					label="#Executed"
					onChange={(e) => setTotalExecuted(e.target.value)}
					type="number"
				/>
				<SelectorElement
					{...methods}
					colSize="col-sm-3"
					value={messageType}
					name="messageType"
					label="Message Type"
					onChange={(e) => setMessageType(e.target.value)}
					options={[
						{ value: 0, label: "SMS" },
						{ value: 1, label: "Email" },
						{ value: 2, label: "SMS & Email" },
					]}
				/>
			</div>
			<div className="d-flex justify-content-center align-items-center my-2">
				<button
					className="btn btn-md btn-info ml-3"
					onClick={() => {
						toggleSave();
					}}
				>
					Save Schedule
				</button>
			</div>
		</div>
	);
};
export default ScheduleMessage;
