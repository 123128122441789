import React, { useEffect, useRef, useState } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import useCurrentDealStore from "stores/DealStore";
import useCurrentSaleStore from "stores/SaleStore";
import { getTaxRateByAddress, getZipAndCustomTaxRate } from "api/DealAPI";
import { retrieveFailAlert } from "utils/alertUtils";
import { useFromUser } from "stores/LocalStorageHelper";
import { DealStatus } from "constants/Constants";
import dayjs from "dayjs";
import { showApiError } from "utils/errorRoutingUtils";
import shallow from "zustand/shallow";

/**
 * TaxCountySelector component to display and select tax rates by county.
 *
 * @returns {JSX.Element} The rendered component
 */
const TaxCountySelector = () => {
	const isFirstRender = useRef(true);
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const dealerZip = useFromUser("locations")["zipCode"];
	const dealerCity = useFromUser("locations")["city"];
	const dealerCounty = useFromUser("locations")["county"];
	const dealerState = useFromUser("locations")["state"];
	const dealerAddress = useFromUser("locations")["address"];
	let xCounty = "";
	let xState = "";
	let xAddress = "";
	let xCity = "";
	let xZip = "";

	console.log(dealerAddress);
	console.log(dealerState);
	const [taxRatesList, setTaxRatesList] = useState([]);

	const {
		type,
		county,
		editCounty,
		countyRecNum,
		editCountyRecNum,
		taxDefaults,
		buyerCity,
		buyerState,
		buyerCounty,
		buyerZip,
		buyerAddress,
		editTaxDefaultsValue,
	} = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
			county: state.county,
			editCounty: state?.editCounty,
			countyRecNum: state.deal.countyRecNum,
			editCountyRecNum: state.editCountyRecNum,
			taxDefaults: state?.deal?.dealSettings.taxDefaults,
			buyerCounty: state?.buyerAddress?.county,
			buyerCity: state?.buyerAddress?.city,
			buyerZip: state?.buyerAddress?.zip,
			buyerState: state?.buyerAddress?.state,
			buyerAddress: state?.buyerAddress?.street,
			editTaxDefaultsValue: state.editTaxDefaultsValue,
		}),
		shallow
	);

	const { editTaxRate, taxRate } = useCurrentSaleStore(
		(state) => ({
			editTaxRate: state.editTaxRate,
			taxRate: state.taxRate,
		}),
		shallow
	);

	/**
	 * Fetches the zip and custom tax rates.
	 *
	 * @param {string} zip - The zip code to fetch the tax rates for.
	 */
	const ZipAndCustomTaxRate = (zip) => {
		const cRecnum = countyRecNum == null ? 0 : countyRecNum;
		getZipAndCustomTaxRate(dealerID, locationID, zip, cRecnum).then(
			(res) => {
				console.log(res.data.content);
				const formattedRatesList = formatTaxRates(res.data.content);
				setTaxRatesList(formattedRatesList);

				if (!(taxDefaults?.taxRegion === 2 && taxDefaults?.taxRate > 0)) {
					if (taxDefaults?.taxRegion === 1 || taxDefaults?.taxRegion === 0) {
						getCountyRecNum(taxDefaults?.taxRegion, formattedRatesList);
					} else {
						handleSelect(null, formattedRatesList);
					}
				}
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};

	/**
	 * Formats the tax rates into a list of options.
	 *
	 * @param {Array} taxRates - The tax rates to format.
	 * @returns {Array} The formatted tax rates.
	 */
	const formatTaxRates = (taxRates) => {
		const options = taxRates.map((tax) => {
			const rate = Number(parseFloat(tax.taxRate) * 100).toFixed(4);
			console.log({
				matches: tax.recNum === countyRecNum,
				countyRecNum: countyRecNum,
				recNum: tax.recNum,
				taxRate: taxRate,
				rate: rate,
			});
			const displayRate =
				tax.recNum === countyRecNum
					? (Number(taxRate) * 100).toFixed(4)
					: Number(rate).toFixed(4);
			return {
				city: tax.city,
				label: `${tax.zip} - ${tax.city}, ${tax.state} - ${displayRate} % `,
				value: tax.recNum,
				taxRate: tax.taxRate,
				obj: tax,
			};
		});
		return [
			...options,
			{
				city: "",
				label: "None",
				value: "",
				taxRate: 0,
				obj: null,
			},
		];
	};

	/**
	 * Handles the selection of a tax rate.
	 *
	 * @param {string|number} ID - The selected tax rate ID.
	 * @param {Array} list - The list of tax rates.
	 */
	const handleSelect = (ID, list) => {
		const localTaxRatesList = list || taxRatesList;
		if (ID === "" || ID === null) {
			setNullsToStore();
		} else if (ID) {
			const rateObj = localTaxRatesList.find(
				(rate) => rate.value === Number(ID)
			);
			if (rateObj) {
				editCountyRecNum(Number(ID));
				editCounty(rateObj.obj);
				editTaxRate(rateObj.taxRate);
				editTaxDefaultsValue("countyRecNum", Number(ID));
				editTaxDefaultsValue("taxRate", rateObj.taxRate);
				if (
					taxDefaults?.taxRegion === 2 &&
					rateObj.obj.zip === buyerZip &&
					rateObj.obj.city === buyerCity
				) {
					xCity = buyerCity;
					xCounty = buyerCounty;
					xState = buyerState;
					xAddress = buyerAddress;
					xZip = buyerZip;
				} else if (
					taxDefaults?.taxRegion === 2 &&
					rateObj.obj.zip === dealerZip &&
					rateObj.obj.city === dealerCity
				) {
					xCity = dealerCity;
					xCounty = dealerCounty;
					xState = dealerState;
					xAddress = dealerAddress;
					xZip = dealerZip;
				}
				console.log(xAddress + " " + xCity + " " + xState + " " + xZip);
				if (taxDefaults?.taxRegion === 2) {
					getTaxRateByAddress(
						xAddress + " " + xCity + " " + xState + " " + xZip
					).then(
						(res) => {
							console.log(res.data.content);
							editTaxRate(res.data.content.taxSales);
							editTaxDefaultsValue("taxRate", res.data.content.taxSales);
						},
						(err) => {
							if (!err.isGeneralError) {
								showApiError(err, retrieveFailAlert);
							}
						}
					);
				}
			}
		}
	};

	/**
	 * Sets default values to the store.
	 */
	const setNullsToStore = () => {
		editCountyRecNum(null);
		editCounty(null);
		editTaxRate(0);

		if (taxDefaults.taxRegion === undefined || taxDefaults.taxRegion === null) {
			taxDefaults.taxRegion = 0;
		}

		editTaxDefaultsValue("countyRecNum", null);
		editTaxDefaultsValue("taxRate", 0);
	};

	/**
	 * Gets the county record number based on the tax region and tax rates list.
	 *
	 * @param {number} taxRegion - The tax region.
	 * @param {Array} taxRatesList - The list of tax rates.
	 */
	const getCountyRecNum = (taxRegion, taxRatesList) => {
		console.log("useEffect triggered with isFirstRender:", isFirstRender);
		if (isFirstRender.current) {
			isFirstRender.current = false;
			console.log("Skipping execution on initial render");
			return; // Skip execution on initial render
		}

		//if (countyRecNum != null) return;
		console.log("taxRegion", taxRegion);
		console.log("taxRatesList", taxRatesList);

		if (taxRegion === 0 && buyerZip && buyerZip.length === 5) {
			xCity = buyerCity;
			xCounty = buyerCounty;
			xState = buyerState;
			xAddress = buyerAddress;
			xZip = buyerZip;
		} else if (taxRegion === 2 && countyRecNum === null) {
			xCity = "";
			xCounty = buyerCounty;
		} else if (taxRegion === 1 && dealerZip) {
			xCity = dealerCity;
			xCounty = dealerCounty;
			xState = dealerState;
			xAddress = dealerAddress;
			xZip = dealerZip;
		}
		console.log("xCity", xCity);
		console.log("xCounty", xCounty);
		console.log("xState", xState);
		console.log("xAddress", xAddress);
		const taxRateValue = taxRatesList.find(
			(rate) =>
				rate.city.toUpperCase() === xCity.toUpperCase() &&
				rate.obj?.name.toUpperCase() === xCounty.toUpperCase()
		);
		if (taxRateValue) {
			console.log("taxRateValue", taxRateValue);
			getTaxRateByAddress(
				xAddress + " " + xCity + " " + xState + " " + xZip
			).then(
				(res) => {
					const updatedTaxRatesList = taxRatesList.map((rate) =>
						rate.value === taxRateValue.value
							? {
									...rate,
									taxRate: res.data.content.taxSales,
									obj: { ...rate.obj, taxRate: res.data.content.taxSales },
							  }
							: rate
					);
					console.log(res.data.content);
					setTaxRatesList(updatedTaxRatesList); // Update state with the new array

					// Update tax rate in store
					editTaxRate(res.data.content.taxSales);
					editTaxDefaultsValue("taxRate", res.data.content.taxSales);
					// editTaxRate(res.data.content.taxSales);
					// editTaxDefaultsValue("taxRate", res.data.content.taxSales);
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		}
		if (!taxRateValue || taxRateValue.value === "") {
			setNullsToStore();
		} else {
			handleSelect(Number(taxRateValue.value), taxRatesList);
		}
	};

	useEffect(() => {
		let zips = dealerZip;
		if (buyerZip && buyerZip.length === 5) {
			zips = `${zips},${buyerZip}`;
		}
		ZipAndCustomTaxRate(zips);
		// eslint-disable-next-line
	}, [taxDefaults.taxRegion, buyerZip, dealerZip]);
	// useEffect(() => {
	// 	console.log("setTaxRatesList");
	// 	console.log(taxRatesList);
	// 	console.log({ taxRate: taxRate * 100 });
	// 	const newOptions = taxRatesList.map((option) => {
	// 		if (option.obj !== null && option.obj.recNum === countyRecNum) {
	// 			return {
	// 				...option,
	// 				label: `${option.obj.zip} - ${option.obj.city}, ${option.obj.state}
	// 				- ${taxRate * 100}%`,
	// 			};
	// 		} else return option;
	// 	});

	// 	setTaxRatesList(newOptions);
	// }, [countyRecNum]);

	return (
		<FormGroup className="row-12 mb-0">
			<Label>
				{"Zip County Rate Last Updated: " +
					dayjs(county?.startDate).format("MM/DD/YYYY")}
			</Label>
			<Input
				name="taxRate"
				disabled={type !== DealStatus.PENDING || taxDefaults.taxRegion !== 2}
				type="select"
				value={countyRecNum === null ? "" : countyRecNum}
				onChange={(e) => handleSelect(e.target.value)}
			>
				{taxRatesList.map((rate) => (
					<option key={rate.value} value={rate.value}>
						{rate.label} - {rate.obj?.name}
					</option>
				))}
			</Input>
		</FormGroup>
	);
};

export default TaxCountySelector;
