import React, { useState } from "react";
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	FormGroup,
	Label,
	Input,
} from "reactstrap";
import { useFromUser } from "../stores/LocalStorageHelper";
import { displayError, displayPdf, getPdf } from "../api/FormsAPI";
import Swal from "sweetalert2";

const ReceiptButton = ({ paymentId, accountID }) => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const username = useFromUser("lastName") + " " + useFromUser("firstName");

	// State for modal and checkbox values
	const [modalOpen, setModalOpen] = useState(false);
	const [options, setOptions] = useState({
		showLoanBalans: false,
		showPastDue: false,
		showPayoff: false,
		showPickupBal: false,
		showPreviousBal: false,
	});

	// Toggle modal visibility
	const toggleModal = () => setModalOpen(!modalOpen);

	// Handle checkbox change
	const handleCheckboxChange = (e) => {
		setOptions({
			...options,
			[e.target.name]: e.target.checked,
		});
	};

	// Handle print with selected options
	const handlePrint = () => {
		const body = {
			dcReportID: 512,
			params: {
				dealerID,
				locationID,
				paymentID: paymentId,
				cashier: username,
				...options,
			},
		};

		getPdf(body).then(
			(response) => {
				displayPdf(response);
				Swal.close();
				toggleModal(); // Close modal after submission
			},
			(err) => {
				displayError(err);
			}
		);
	};

	return (
		<>
			<Button onClick={toggleModal}>Print</Button>

			<Modal isOpen={modalOpen} toggle={toggleModal}>
				<ModalHeader toggle={toggleModal}>Select Receipt Options</ModalHeader>
				<ModalBody>
					<FormGroup check>
						<Label check>
							<Input
								type="checkbox"
								name="showLoanBalans"
								checked={options.showLoanBalans}
								onChange={handleCheckboxChange}
								style={{ marginRight: "8px" }} // Adds space without CSS file
							/>
							Show Loan Balance
						</Label>
					</FormGroup>
					<FormGroup check>
						<Label check>
							<Input
								type="checkbox"
								name="showPastDue"
								checked={options.showPastDue}
								onChange={handleCheckboxChange}
								style={{ marginRight: "8px" }}
							/>
							Show Past Due
						</Label>
					</FormGroup>
					<FormGroup check>
						<Label check>
							<Input
								type="checkbox"
								name="showPayoff"
								checked={options.showPayoff}
								onChange={handleCheckboxChange}
								style={{ marginRight: "8px" }}
							/>
							Show Payoff
						</Label>
					</FormGroup>
					<FormGroup check>
						<Label check>
							<Input
								type="checkbox"
								name="showPickupBal"
								checked={options.showPickupBal}
								onChange={handleCheckboxChange}
								style={{ marginRight: "8px" }}
							/>
							Show Pickup Balance
						</Label>
					</FormGroup>
					<FormGroup check>
						<Label check>
							<Input
								type="checkbox"
								name="showPreviousBal"
								checked={options.showPreviousBal}
								onChange={handleCheckboxChange}
								style={{ marginRight: "8px" }}
							/>
							Show Previous Balance
						</Label>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={handlePrint}>
						Confirm & Print
					</Button>
					<Button color="secondary" onClick={toggleModal}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default ReceiptButton;
