import React, { useState, useEffect } from "react";
import { Button, Col, Form } from "reactstrap";
import * as yup from "yup";
import { setLocale } from "yup";
import { useForm, FormProvider } from "react-hook-form";
import useCurrentDealStore from "stores/DealStore";
import { useDigitzsMerchantId, useFromUser } from "stores/LocalStorageHelper";
import { saveSuccessAlert } from "utils/alertUtils";
import { DealStatus, paymentMethod } from "constants/Constants";
import shallow from "zustand/shallow";
import dayjs from "dayjs";
import {
	CurrencyInput,
	CurrencyInputElement,
	SelectorElement,
} from "features/forms/components/InputElement";
import InputWrapper from "features/forms/components/InputWrapper";
import InputElement from "features/forms/components/InputElement";
import PropTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers";
import DigitzsPayment from "../../../../../../services/digitzsService/DigitzsPayment";
import ChildrenModal from "../../../../../../components/modals/ChildrenModal";
import { deletePayment } from "../../../../../../account/AccountAPI";

/**
 * MoneyDownForm component to handle adding and editing down payments.
 *
 * @param {Object} props - The component props.
 * @param {number} props.currentIndex - The current index of the down payment being edited.
 * @param {function} props.setCurrentIndex - Function to set the current index.
 * @param {function} props.toggle - Function to toggle the form visibility.
 */
export const MoneyDownForm = ({ currentIndex, setCurrentIndex, toggle }) => {
	const merchantId = useDigitzsMerchantId();
	const [digitzsModal, setDigitzsModal] = useState(false);
	const toggleDigitzsModal = () => setDigitzsModal(!digitzsModal);

	// Deal store
	const {
		downPayments,
		editDownPayments,
		type,
		calculate,
		customerID,
		dealID,
	} = useCurrentDealStore(
		(state) => ({
			downPayments: state.downPayments,
			editDownPayments: state.editDownPayments,
			type: state.deal.type,
			calculate: state.calculate,
			customerID: state.deal.customerID,
			dealID: state.deal.ID,
		}),
		shallow
	);

	const today = dayjs().format("YYYY-MM-DD");
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const userRecNum = useFromUser("ID");

	const [payment, setPayment] = useState(downPayments[currentIndex]);
	const [receiptNo, setReceiptNo] = useState("");
	const [datePaid, setDatePaid] = useState(today);
	const [amtPaid, setAmtPaid] = useState(0);
	const [convenienceFee, setConvenienceFee] = useState(0);
	const [checkNo, setCheckNo] = useState("");
	const [method, setMethod] = useState("cash");
	// const [pmtType, setpmtType] = useState("");
	const [status, setStatus] = useState("completed");
	const [statusReason, setStatusReason] = useState("");
	const [note, setNote] = useState("");
	const [description, setDescription] = useState("");
	const [exportedToQB, setExportedToQB] = useState("");
	const [isExportToQB, setIsExportToQB] = useState(0);
	const [appVersion, setAppVersion] = useState("");
	const [hideCheck, sethideCheck] = useState(true);
	const [buttonText, setButtonText] = useState("Add");
	const [newPmt, setNewPmt] = useState(true);
	const [formTitle, setFormTitle] = useState([]);

	const changeText = (text) => setButtonText(text);

	const clearFields = () => {
		changeText("Add Payment");
		setReceiptNo("");
		setDatePaid("");
		setAmtPaid(0);
		setCheckNo("");
		setMethod("cash");
		// setpmtType("");
		setStatusReason("");
		setStatus("completed");
		setNote("");
		setDescription("");
		setExportedToQB("");
		setIsExportToQB(0);
		setAppVersion("");
		setCurrentIndex(downPayments.length);
	};

	const getID = () => {
		if (downPayments[currentIndex] === undefined) {
			return null;
		} else if (downPayments[currentIndex].id === undefined) {
			return null;
		} else {
			return downPayments[currentIndex].id;
		}
	};
	const getNewDown = (ID) => ({
		...payment,
		id: ID,
		receiptNo,
		datePaid,
		amtPaid: parseFloat(amtPaid),
		convenienceFee: parseFloat(convenienceFee),
		checkNo,
		method,
		pmtMode: 8,
		pmtType: "Down",
		status: status,
		statusReason,
		note,
		description,
		exportedToQB,
		isExportToQB,
		createOn: dayjs().format("YYYY-MM-DD"),
		appVersion,
		locationID,
		dealerID,
		currentIndex,
		userRecNum,
	});

	const addPayment = (status) => {
		if (status === "" || status === null) {
			status = "Completed";
		}
		const id = getID();
		// const newDown = getNewDown(id);
		console.log("Current index is: " + currentIndex);
		console.log(status);
		const newDown = {
			...getNewDown(id),
			status: status, // Ensure the status state is used
		};
		console.log(newDown);
		const downList = () => {
			let obj = [...downPayments]; // Create a copy of downPayments
			obj[currentIndex] = newDown;
			return obj;
		};
		const newDownObj = downList();
		editDownPayments(newDownObj);
		setPayment(newDown);
		clearFields();
		saveSuccessAlert();
		calculate();
		toggle();
	};

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			max: "Must be ${max} characters or less",
			length: "Required format is YYYY/MM/DD",
		},
	});

	// Define rules for each input field
	const schema = yup.object().shape({
		//receiptNo: yup.string().max(50).min(1).required(),
		//description: yup.string().required().max(50),
		// pmtType: yup.string().required().max(50),
		pmtMode: yup.string().required().max(50),
		//note: yup.string().required().max(50).min(1).nullable(),
	});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		if (currentIndex < downPayments.length) {
			setNewPmt(false);
			setFormTitle("Edit Payment ");
			setReceiptNo(downPayments[currentIndex]?.receiptNo || "");
			setDatePaid(downPayments[currentIndex]?.datePaid || "");
			setAmtPaid(downPayments[currentIndex]?.amtPaid || 0);
			setConvenienceFee(downPayments[currentIndex]?.convenienceFee || 0);
			setCheckNo(downPayments[currentIndex]?.checkNo || "");
			setMethod(downPayments[currentIndex]?.method || "");
			// setpmtType(downPayments[currentIndex]?.pmtType || "");
			setNote(downPayments[currentIndex]?.note || "");
			setDescription(downPayments[currentIndex]?.description || "");
			setExportedToQB(downPayments[currentIndex]?.exportedToQB || "");
			setIsExportToQB(downPayments[currentIndex]?.isExportToQB || 0);
			setAppVersion(downPayments[currentIndex]?.appVersion || "");
			setStatus(downPayments[currentIndex]?.status || "completed");
			changeText("Save Payment");
		} else {
			const newDownPayment = {
				id: null,
				dealID: dealID,
				amtPaid,
				convenienceFee: parseFloat(convenienceFee),
				datePaid,
				receiptNo,
				method,
				checkNo,
				pmtType: "Down",
				pmtMode: 8,
				status: status,
				statusReason,
				note,
				description,
				appVersion,
				locationID,
				dealerID,
				createOn: new Date(),
				userRecNum,
			};
			console.log(newDownPayment);
			setPayment(newDownPayment);
			setNewPmt(true);
			setFormTitle("Add Payment");
		}

		// eslint-disable-next-line
	}, [currentIndex]);

	const handleMethod = (e) => {
		setMethod(e);
		if (
			e === "check" ||
			e === "cashierCheck" ||
			e === "echeck" ||
			e === "other"
		)
			sethideCheck(false);
		else sethideCheck(true);
	};
	const getRandomId = (min = 1000, max = 500000) => {
		min = Math.ceil(min);
		max = Math.floor(max);
		const num = Math.floor(Math.random() * (max - min + 1)) + min;

		if (!receiptNo) {
			setReceiptNo(num.toString());
		}
	};
	useEffect(() => {
		getRandomId();
		// eslint-disable-next-line
	}, [receiptNo]);
	const removePayment = () => {
		const ID = getID();
		console.log("Removing Payment with ID: " + ID);
		if (ID) {
			deletePayment(ID).then(
				(response) => {
					console.log(response);
					let obj = downPayments.filter((item) => item.id !== ID);
					editDownPayments(obj);

					deleteSuccessAlert();
					toggle();
					//					history.push(acctPaymentList);
				},
				(err) => {
					console.error(err);
					if (!err.isGeneralError) {
						showApiError(err, deleteFailAlert);
					}
				}
			);
		} else {
			const downList = () => {
				let obj = [...downPayments]; // Create a copy of downPayments
				obj.filter((item) => {
					return item.index === currentIndex;
				});
				return obj;
			};
			const newDownObj = downList();
			editDownPayments(newDownObj);
			clearFields();
			saveSuccessAlert();
			calculate();
			toggle();
		}
	};

	return (
		<>
			<FormProvider {...methods}>
				<Form>
					<InputWrapper
						formTitle={formTitle}
						inputComponents={[
							<InputElement
								key="datePaid"
								// readOnly={type !== DealStatus.PENDING}
								readOnly={!newPmt}
								{...methods}
								value={datePaid}
								onChange={(e) => setDatePaid(e.target.value)}
								name="datePaid"
								label="Date Paid"
								type="date"
							/>,
							<SelectorElement
								key="method"
								{...methods}
								readOnly={!newPmt}
								locked={!newPmt}
								name="method"
								label="Methods"
								value={method}
								onChange={(e) => handleMethod(e.target.value)}
								options={paymentMethod}
							/>,
							<CurrencyInputElement
								key="amtPaid"
								readOnly={!newPmt}
								value={amtPaid}
								onChange={setAmtPaid}
								name="amtPaid"
								label="Amount"
								type="number"
							/>,
							<CurrencyInputElement
								key="convenienceFee"
								readOnly={!newPmt}
								value={convenienceFee}
								onChange={setConvenienceFee}
								name="convenienceFee"
								label="Convenience Fee"
								type="number"
							/>,
							// <CurrencyInputElement
							// 	key="totalPaid"
							// 	readOnly={true}
							// 	value={amtPaid + convenienceFee}
							// 	//onChange={setAmtPaid}
							// 	name="totalPaid"
							// 	label="Total Paid"
							// 	type="number"
							// />,
							<CurrencyInput
								disableValidation
								readOnly={true}
								value={amtPaid + convenienceFee}
								//onChange={setAmtPaid}
								name="totalPaid"
								label="Total Paid"
								type="number"
							/>,
							<InputElement
								key="receiptNo"
								{...methods}
								value={receiptNo}
								onChange={(e) => setReceiptNo(e.target.value)}
								name="receiptNo"
								label="Receipt No"
								type="text"
							/>,
							<InputElement
								{...methods}
								key="checkNo"
								disabled={hideCheck}
								value={checkNo}
								onChange={(e) => setCheckNo(e.target.value)}
								name="checkNo"
								label="Check No"
								type="text"
							/>,
							<InputElement
								{...methods}
								key="status"
								disabled={true}
								value={status}
								name="status"
								label="Status"
								type="text"
							/>,
							<InputElement
								key="description"
								readOnly={type !== DealStatus.PENDING}
								{...methods}
								value={description}
								onChange={(e) => setDescription(e.target.value)}
								colSize="col-sm-9"
								name="description"
								label="Description"
								type="text"
							/>,
							<InputElement
								key="note"
								readOnly={type !== DealStatus.PENDING}
								{...methods}
								value={note}
								onChange={(e) => setNote(e.target.value)}
								colSize="col-sm-12"
								name="note"
								label="Note"
								type="textarea"
							/>,
							merchantId &&
								(method === "creditCard" || method === "debitCard") && (
									<Col sm="3" className="text-center">
										<ChildrenModal
											size="lg"
											modal={digitzsModal}
											toggle={toggleDigitzsModal}
										>
											<DigitzsPayment
												customerID={customerID}
												projName={"DC2.0"}
												pmtObj={payment}
												setPmtObj={setPayment}
												addPayment={addPayment}
											/>
										</ChildrenModal>
										<Button onClick={toggleDigitzsModal}>
											Process Credit Card
										</Button>
									</Col>
								),
						]}
						buttons={
							<div className="d-flex justify-content-center mt-2">
								<Button color="primary" onClick={() => addPayment("completed")}>
									{buttonText === "Save Payment" ? (
										<i className="nc-icon nc-check-2"></i>
									) : (
										<i className="nc-icon nc-simple-add"></i>
									)}
									{status === "void" ? "Restore" : buttonText}
								</Button>
								{/* <div className="btn btn-link btn-md" onClick={clearFields}>
									Reset
								</div> */}

								{payment?.id && payment?.status !== "void" && (
									<Button
										active={type === "Pending"}
										color="danger "
										onClick={() => {
											setStatus("void");
											addPayment("void");
										}}
									>
										Void
									</Button>
								)}
								{payment?.id && payment?.status === "void" && (
									<Button
										active={type === "pending"}
										color="danger "
										onClick={() => {
											removePayment();
										}}
									>
										Remove
									</Button>
								)}
							</div>
						}
					/>
				</Form>
			</FormProvider>
		</>
	);
};

MoneyDownForm.propTypes = {
	currentIndex: PropTypes.number.isRequired,
	setCurrentIndex: PropTypes.func.isRequired,
	toggle: PropTypes.func.isRequired,
};

export default MoneyDownForm;
