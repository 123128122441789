import React, { useState, createContext } from "react";

export const PackEditContext = createContext();
export const PackRequestContext = createContext();

export const PackEditProvider = ({ children }) => {
	// array of packObjs the dealer has created (dcPack and list of dcReports)
	const [packsList, setPacksList] = useState([]);
	// `pack` object (dcPack) in the packObj
	const [pack, setPack] = useState({});
	// `forms` array (list of dcReports) in the packObj
	const [forms, setForms] = useState([]);
	// Object used by ReactTable to set initially checked forms in the pack when editing
	const [checkedFormsObj, setCheckedFormsObj] = useState({});

	const context = {
		packsList,
		setPacksList,
		pack,
		setPack,
		forms,
		setForms,
		checkedFormsObj,
		setCheckedFormsObj,
	};

	return (
		<PackEditContext.Provider value={context}>
			{children}
		</PackEditContext.Provider>
	);
};

export const PackRequestProvider = ({ children }) => {
	// Object whose keys are the dcReport.id of dynamically generated parameters. The majority of forms have no parameters so the values are set to null
	const [paramsList, setParamsList] = useState({});
	const [otherSignaturesList, setOtherSignaturesList] = useState({});
	const [lenderPackRequest, setLenderPackRequest] = useState({
		packID: null,
		dealID: null,
		attachmentIDs: [],
		paramsList,
		otherSignaturesList,
	});
	const isLenderPackRequest = () => {
		return lenderPackRequest.packID === null ? false : true;
	};

	const context = {
		paramsList,
		setParamsList,
		otherSignaturesList,
		setOtherSignaturesList,
		lenderPackRequest,
		setLenderPackRequest,
		isLenderPackRequest,
	};

	return (
		<PackRequestContext.Provider value={context}>
			{children}
		</PackRequestContext.Provider>
	);
};
