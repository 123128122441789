import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
	Card,
	CardBody,
	CardFooter,
	Form,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
} from "reactstrap";
import Logo from "assets/img/logo-dark.svg";
import crypto from "crypto";
import { getUserDetails } from "api/LoginAPI";
import { login } from "utils/authUtils";
import { loginFailAlert, failAlert } from "utils/alertUtils";
import { PATHS } from "constants/Constants";

const LoginForm = () => {
	const [disabled, setDisabled] = useState(false);
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const history = useHistory();

	// Use useRef to track if the component is mounted
	const isMountedRef = useRef(null);

	useEffect(() => {
		isMountedRef.current = true; // Set to true when the component mounts
		return () => {
			isMountedRef.current = false; // Set to false when the component unmounts
		};
	}, []);

	const handleSubmit = (e) => {
		e.preventDefault();
		setDisabled(true);
		getUserDetails({
			username,
			password,
		}).then(
			(res) => {
				if (!isMountedRef.current) return;
				if (res.status === 200) {
					let resp = res.data.content;
					const cfState = encodeURIComponent(
						crypto.randomBytes(16).toString("base64")
					);
					const data = {
						...resp.dcLogin,
						cfState,
						locations: resp.locations,
						dealers: resp.dealers,
						jws: resp.jws,
						profileData: resp.profileData,
						messagingNumber: resp.phoneNumber,
					};

					login(JSON.stringify(data));

					if (resp.dcLogin.lastLoginDate === null) {
						history.push(PATHS.UPDATE_PASSWORD);
					} else {
						history.push(PATHS.DASHBOARD);
					}
				}
				setDisabled(false);
			},
			(error) => {
				if (!isMountedRef.current) return;
				if (error?.response?.data?.message) {
					failAlert(error.response.data.message);
				} else {
					loginFailAlert();
				}
				setDisabled(false);
			}
		);
	};

	return (
		<div className="login-page">
			<Form className="form login-form" onSubmit={handleSubmit}>
				<Card className="card-login">
					<img src={Logo} alt="DealerClick Logo" className="logo mt-3" />
					<CardBody className="px-4">
						<InputGroup>
							<InputGroupAddon addonType="prepend">
								<InputGroupText>
									<i className="nc-icon nc-single-02 text-primary" />
								</InputGroupText>
							</InputGroupAddon>
							<input
								type="text"
								className="form-control"
								id="username"
								aria-describedby="usernameHelp"
								placeholder="Enter username"
								value={username}
								onChange={(e) => setUsername(e.target.value)}
							/>
						</InputGroup>
						<InputGroup className="mt-2">
							<InputGroupAddon addonType="prepend">
								<InputGroupText>
									<i className="nc-icon nc-key-25 text-primary" />
								</InputGroupText>
							</InputGroupAddon>
							<input
								type="password"
								className="form-control"
								id="password"
								placeholder="Password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
						</InputGroup>
						<div className="text-center mt-3">
							<button
								type="submit"
								className="btn btn-primary mx-auto"
								disabled={disabled}
							>
								Login
							</button>
						</div>
					</CardBody>
					<CardFooter className="text-center">
						<div className="">
							<a
								className="mx-2 text-center"
								href="/"
								onClick={(e) => {
									e.preventDefault();
									history.push(PATHS.FORGOTTEN_PASSWORD);
								}}
							>
								I forgot my password...
							</a>
						</div>
						<hr className="mt-2 pb-2" />
						<a
							href="/#"
							onClick={(e) => {
								e.preventDefault();
								history.push(PATHS.NEW_USER);
							}}
						>
							Click here to activate new account
						</a>
					</CardFooter>
				</Card>
			</Form>
		</div>
	);
};

export default LoginForm;
