import React, { useEffect, useState } from "react";
import { Button, Table } from "reactstrap";

import useCurrentDealStore from "../../stores/DealStore";
import useCurrentSaleStore from "../../stores/SaleStore";
import LoadingSpinner from "../loadingSpinner/LoadingSpinner";

import { getTaxRateByZip } from "../../api/DealAPI";
import { showApiError } from "../../utils/errorRoutingUtils";
import { retrieveFailAlert } from "../../utils/alertUtils";
import shallow from "zustand/shallow";

// TODO: Ask about how to handle these tables, this could be a feature, or a top level component with its logic and state management removed to top level directories

const ZipCityTable = ({
	data,
	toggle,
	address,
	setAddress,
	isDeal = false,
	isBuyer = false,
}) => {
	const [selectedCity, setSelectedCity] = useState(null);
	// Deal store
	const {
		taxRegion,
		editCountyRecNum,
		editCounty,
		editTaxDefaults,
		taxDefaults,
		editTaxDefaultsValue,
	} = useCurrentDealStore(
		(state) => ({
			editCountyRecNum: state.editCountyRecNum,
			taxRegion: state?.deal?.dealSettings?.taxDefaults?.taxRegion,
			editCounty: state.editCounty,
			editTaxDefaults: state.editTaxDefaults,
			taxDefaults: state?.deal?.dealSettings?.taxDefaults,
			editTaxDefaultsValue: state.editTaxDefaultsValue,
		}),

		shallow
	);
	const { editTaxRate } = useCurrentSaleStore(
		(state) => ({
			editTaxRate: state.editTaxRate,
		}),
		shallow
	);

	// Headers
	const headers =
		isDeal !== false && isBuyer !== false
			? ["#", "Zip", "State", "City", "County", "Tax Rate", ""]
			: ["#", "Zip", "State", "City", "County", ""];
	// Local state
	const [cities, setCities] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);

	// Update the store address object
	const selectCity = (county) => {
		setAddress({
			...address,
			...data.address,
			state: county.state,
			city: county.city,
			county: county.name
				.toLowerCase()
				.split(" ")
				.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
				.join(" "),
		});
		console.log(taxRegion);
		console.log(isBuyer);
		console.log(isDeal);
		console.log(county);

		if (taxRegion === 0 && isDeal && isBuyer) {
			console.log(county.recNum);
			console.log(county.zip);
			editTaxRate(county.taxRate);
			editCountyRecNum(county.recNum);
			editCounty(county);
			console.log(taxDefaults);
			setSelectedCity(county);
			// getTaxRateByAddress(
			// 	address.street +
			// 		" " +
			// 		county.city +
			// 		" " +
			// 		county.state +
			// 		" " +
			// 		county.zip
			// ).then(
			// 	(res) => {
			// 		console.log(res.data.content.taxSales);
			// 		let newTaxDefaults = { ...taxDefaults };
			// 		newTaxDefaults.countyRecNum = county.recNum;
			// 		newTaxDefaults.taxRate = res.data.content.taxSales;
			// 		editTaxRate(res.data.content.taxSales);
			// 		editTaxDefaultsValue("taxRate", res.data.content.taxSales);
			// 		editTaxDefaults(newTaxDefaults);
			// 		console.log(res.data.content.taxSales);
			// 		console.log("taxRateValue", newTaxDefaults.taxRate);
			// 	},
			// 	(err) => {
			// 		if (!err.isGeneralError) {
			// 			showApiError(err, retrieveFailAlert);
			// 		}
			// 	}
			// );
		}
		toggle();
	};

	// Get the list of cities with the matching zip code
	useEffect(() => {
		if (data.zip != null && data.zip.length === 5) {
			getTaxRateByZip(data.zip).then(
				(res) => {
					setCities(res.data.content);
					setIsLoaded(true);
				},
				(err) => {
					setIsLoaded(true);
					setCities([]);

					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		} else {
			setCities([]);
		}
		// eslint-disable-next-line
	}, [selectedCity]);

	return (
		<div>
			{!isLoaded && cities === null && <LoadingSpinner />}
			{(isLoaded && cities != null && cities.length > 0 && (
				<Table striped hover>
					<thead>
						<tr>
							{headers.map((header, i) => (
								<th key={i}>{header}</th>
							))}
						</tr>
					</thead>
					<tbody>
						{cities.map((city, index) => (
							<tr key={city.recNum}>
								<th scope="row">{index + 1}</th>
								<td>{city.zip}</td>
								<td>{city.state}</td>
								<td>{city.city}</td>
								<td>
									{city.name
										.toLowerCase()
										.split(" ")
										.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
										.join(" ")}
								</td>
								{isDeal !== false && isBuyer !== false && (
									<td>{`${Number(city.taxRate * 100).toFixed(4)}%`}</td>
								)}

								<td className="d-flex justify-content-center">
									<Button className="btn-md" onClick={() => selectCity(city)}>
										Select
									</Button>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
			)) || (
				<h6 style={{ color: "red" }} className="d-flex justify-content-center">
					No cities found with the entered zip of {data.zip}
				</h6>
			)}
		</div>
	);
};

export default ZipCityTable;
